// blue/properties/new/component.ts
//
// Most commonly, we will navigate here when the `hasProperty` guard detects a user has no property.
// TODO: Handle errors in creating new property!!

import {Component, OnInit, OnDestroy} from "@angular/core";
import {Router, Routes} from "@angular/router";
import {MatSliderChange} from "@angular/material/slider";
import {Subscription} from "rxjs";
import {confirm} from "@nims/cordova";
import {Pair} from "@nims/jsutils";
import {RoomType} from "../../room-types/type";
import {Service as RoomTypesService} from "../../room-types/service";
import {Service as UiService} from "../../components/ui.service";
import {PropertiesService} from "../service";
import {Logger, LogModule} from "../../utils";
import {UserService} from "../../users";
import {environment} from "../../environments/environment";
import {exit, spinner} from "../../utils";
import {SignedInGuard} from "../../users/signed-in.guard";

export interface FormData {
  name: string;
  address: string;
  roomCounts: {[id: string]: number};
}

@Component({
  selector: "property-new",
  templateUrl: "component.html",
})
@LogModule("properties")
export class New implements OnInit, OnDestroy {
  public formData: FormData;

  public features = environment.features;
  public roomTypePairs: Pair<RoomType>[];

  public logger: Logger;

  private backbuttonSubscription: Subscription;

  constructor(
    private router: Router,
    private roomTypesService: RoomTypesService,
    private service: PropertiesService,
    private uiService: UiService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.formData = {name: "", address: "", roomCounts: {}};

    // Capture hardware back button, and if pressed get the hell out of Dodge.
    this.backbuttonSubscription = this.uiService.backbutton.subscribe(() => this.exit());

    this.roomTypePairs = this.roomTypesService.filteredPairs();
  }

  ngOnDestroy() {
    if (this.backbuttonSubscription) this.backbuttonSubscription.unsubscribe();
  }

  public onSubmit($event) {
    $event.preventDefault();
    spinner("Setting up a curated checklist specifically designed for your property", async () => {
      try {
        const {name, address, roomCounts} = this.formData;
        const propertyReference = await this.service.create(name, address, roomCounts);

        this.router.navigate(["/property", "inspect", propertyReference.id]);
      } catch (e) {
        return console.error(...this.logger.log("error creating property", e));
      }
    });
  }

  public signOut() {
    return this.userService.signOut();
  }

  public onRoomTypesCountChange(id: string, matSliderChange: MatSliderChange) {
    if (id) this.formData.roomCounts[id] = matSliderChange.value;
  }

  // Called from template on tapping left arrow in header,
  // and also when hardware back button is pressed.
  // Similar/duplicative logic in properties/inspect.
  public async exit() {
    if ((await confirm("Leave dSnag!?")) === 1) exit();
  }
}

export const NEW_DECLARATIONS = [New];
export const NEW_ROUTES: Routes = [
  {
    path: "new",
    component: New,
    canActivate: [SignedInGuard],
    data: {screenName: "property-new"},
  },
];
