// blue/products/service
//
// Service to handle in-app purchases.
// Expose an observable of object of products indexed by alias.
//
// This services should not be instantiated until Cordova is ready.
// Right now, that is the case, because it is instantiated only from the `purchases` route,
// which is guarded by the `CordovaGuard`.
// TODO: check this assumption is still true.

const MODULE = ["products"];

import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {deviceReady} from "@nims/cordova";
import {find} from "@nims/jsutils";
import {Logger, LogModule} from "../utils";
import {IS_PREMIUM_VERSION, STORE_URL} from "../environments/constants";
import {openUrlInInAppBrowser} from "../utils/in-app-browser";

////////////////////////////////////////////////////////////////
// TYPE DEFINITIONS FOR INAPPPRODUCDT PLUGIN
//
// TODO: Use external type definitions.
// But for some rason, the types provided in `@types/cordova-plugin-inapppurchase" are not working.

interface InAppPurchase {
  buy(productId: string): Promise<BuyResult>;
  consume(productType: string, receipt: string, signature: string): Promise<void>;
  getProducts(ids: string[]): Promise<Product[]>;
  restorePurchases(): Promise<RestoredPurchase[]>;
  subscribe(productId: string): Promise<BuyResult>;
}

declare global {
  interface Window {
    inAppPurchase: InAppPurchase;
  }
}

// Product definition, coming back from the call to `getProducts`.
export interface Product {
  productId: string;
  title: string;
  description: string;
  currency: string;
  price: string;
  priceAsDecimal: string;
}

enum RestoredPurchaseState {
  ACTIVE,
  CANCELLED,
  REFUNDED,
}

interface Consumable {
  receipt: string;
  signature: string;
  productType: string;
}

// Purchase information, coming back from the call to `buy`.
// In theory, we could use this data to "validate" the purchase on the server.
interface BuyResult extends Consumable {
  transactionId: string;
}

// Purchased product information, coming back from the call to `restorePurchases`.
export interface RestoredPurchase extends Consumable {
  productId: string;
  state: RestoredPurchaseState;
  transactionId: string;
  date: string;
}

// TODO: bring these in from FireStore!!
export const ids = [
  "full_checks",

  // Room-specific packages.
  "bedroom_checks",
  "bathroom_checks",
  "living_room_checks",
  "kitchen_checks",

  // Packages specific to "areas".
  "safety_checks",
  "burglary_prevention_checks",
  "external_finished_checks", // Yes, it's a typo.
  "terrace_checks",
];

@Injectable()
@LogModule(...MODULE)
export class Service {
  public products: Product[];

  // An observable of things we have bought.
  // We will watch this in the my-property service.
  public purchases$ = new Subject<string>();
  public unpurchases$ = new Subject<string>();

  public logger: Logger;

  constructor() {
    if (!IS_PREMIUM_VERSION) this.initStore();
  }

  // Is the store available?
  public storeAvailable() {
    return !!window.cordova && !!(window.cordova as any).InAppBrowser;
  }

  public offline() {
    openUrlInInAppBrowser(STORE_URL);
  }

  // Buy (order) a product.
  // caller should do something with error case.
  // At present, we are consuming each purchase immediately.
  // That means that it applies ONLY to the CURRENT property.
  // The user must re-purchase the package to aapply it to a different property.
  //
  // TO change this behavior, you can try changing the default for the `consume` parameter to `false`.
  // Of course, you are also going to have to restore products and apply them to any NEW properties created,
  // as well as to any new rooms created.
  // If the store is not available, then go ahead and apply anyway (this is the testing case).
  public async buy(id: string, consume = true): Promise<BuyResult | undefined> {
    let buyResult: BuyResult;

    if (window.inAppPurchase) {
      buyResult = await window.inAppPurchase.buy(id);

      const {productType, receipt, signature} = buyResult;

      // Consume it immediately. Because purchases applies only to one property.
      if (consume) window.inAppPurchase.consume(productType, receipt, signature);
    }

    this.applyProduct(id);

    return buyResult;
  }

  // "Unbuy" a single item. Mark it as "consumed" so we can
  public async unbuy(id: string) {
    const restoredPurchases = await this.restorePurchases();
    const purchase = find(restoredPurchases, restoredPurchase => restoredPurchase.productId === id);

    // Remove from property, whether or not store is happy with consuming it.
    this.unpurchases$.next(id);

    if (purchase) {
      try {
        this.consume(purchase);
      } catch (e) {
        console.error(...this.logger.log("Consume failed for", id));
      }
    } else {
      console.error(...this.logger.log("Cannot find id", id));
    }
  }

  // Get all purchases.
  public restorePurchases(): Promise<RestoredPurchase[]> {
    return window.inAppPurchase ? window.inAppPurchase.restorePurchases() : Promise.resolve([]);
  }

  // For test/debugging purposes, allow a way to "unpurchase" ("consume") all items.
  // Ignore products for which consuming fails, and just keep going.
  // With the optional argument, also remove everything from the project.
  public async unbuyAll(unpurchase = false) {
    const restoredPurchases = await this.restorePurchases();

    for (const purchase of restoredPurchases) {
      try {
        this.consume(purchase);
      } catch (e) {
        console.error(...this.logger.log("consume failed for", purchase));
      }
    }

    for (const id of ids) {
      this.unpurchases$.next(id);
      await sleep();
    }

    function sleep() {
      return new Promise(resolve => setTimeout(resolve, 500));
    }
  }

  // Apply a particular ID to the product, assuming we've already purchased it,
  // or prehaps are in testing mode and are skipping the store.
  public applyProduct(id: string) {
    this.purchases$.next(id);
  }

  ////////////////////////////////////////////////////////////////
  // PRIVATE

  // Initialize the store by "registering" the products we have.
  // It seems this is necessary.
  private async initStore() {
    await deviceReady;

    // Perhaps we are running on Cordova's "browser" platform.
    if (!window.inAppPurchase) {
      this.products = [];
      return;
    }

    const products = (this.products = await window.inAppPurchase.getProducts(ids));

    console.log(...this.logger.log({products}));
  }

  // Consume a product.
  private consume({productType, receipt, signature}: Consumable) {
    return window.inAppPurchase.consume(productType, receipt, signature);
  }
}
