// blue/items/inspect/component.ts
//
// Components for inspecting an item.

const MODULE = ["items", "inspect"];

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";

import {animate, style, transition, trigger} from "@angular/animations";

import {Observable, Subscription} from "rxjs";
import {delay, map} from "rxjs/operators";

import {
  AngularFirestoreCollection as Collection,
  AngularFirestoreDocument as Document,
} from "angularfire2/firestore";

import {Snag} from "../../snags/type";

import {Logger, LogModule} from "../../utils";
import {appear, flyInOut} from "../../animations/";

import {Item, ItemWithSnag, parseItemName, sortItems} from "../type";

////////////////////////////////////////////////////////////////
// HANDLE LIST OF ITEMS, KEYED BY ID
//
// Responsible for sorting (and filtering) item list.
// Then call component to handle each item document.

@Component({
  selector: "item-inspect-collection",
  templateUrl: "collection.html",
  animations: [
    trigger("itemAnimation", [
      transition(":leave", [
        style({maxHeight: "300px"}),
        animate(250, style({maxHeight: 0, transform: "translateX(100%)"})),
      ]),
    ]),
  ],
})
@LogModule(...MODULE)
export class CollectionComponent {
  @Input() itemsWithSnags$: Observable<Array<ItemWithSnag>>;
  @Input() snagsCollection: Collection<Snag>;
  @Input() showAllItems = false;

  @Output() gotoProperty = new EventEmitter<void>();
  @Output() startInspect = new EventEmitter<number>();

  // Current list of items to be shown, based on `showAllItems`.
  public itemsToShow$: Observable<Array<ItemWithSnag>>;
  public allDone: boolean;

  // The ID of the curently "active" item, whose snag is being edited,
  // so others can be disabled.
  public activeItem: string | null;

  public logger: Logger;

  private subscription: Subscription;

  ngOnChanges(simpleChanges: SimpleChanges) {
    const sortItemsWithSnags = ({item: item1}: ItemWithSnag, {item: item2}: ItemWithSnag) =>
      sortItems(item1, item2);

    const uninspected = ({snag}: ItemWithSnag) => !snag;

    const all$ = this.itemsWithSnags$.pipe(map(items => items.sort(sortItemsWithSnags)));
    const uninspected$ = all$.pipe(map(itemsWithSnags => itemsWithSnags.filter(uninspected)));

    this.itemsToShow$ = this.showAllItems ? all$ : uninspected$;

    // Calculate whether there are no items to show.
    // Delay it to allow the last item to transition off the screen.
    this.subscription = all$.pipe(delay(750)).subscribe(items => {
      this.allDone = items.every(item => !!item.snag);
    });

    this.logger.log$(this.itemsWithSnags$, "items with snags");
    this.logger.log$(all$, "all items with snags (sorted)");
    this.logger.log$(uninspected$, "uninspected items with snags (sorted)");
    this.logger.log$(this.itemsToShow$, "items to show");
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  // Attempt to optimize display, including avoiding unnecessary animations.
  public trackById(n: number, {id}: ItemWithSnag) {
    return id;
  }

  // A back button is display when there are not items to show.
  public onGotoProperty() {
    this.gotoProperty.emit();
  }

  // Some item has advised us that it is being edited.
  // Remember that, so as to disable the sibling items.
  public onSetActiveItem(b: boolean, id: string) {
    this.activeItem = b && id;
  }

  public onStartInspect(inspectedOn: number) {
    this.startInspect.emit(inspectedOn);
  }
}

////////////////////////////////////////////////////////////////
// DISPLAY AN ITEM ON A CARD
//
// Also take in the snag for this item from the snags collection on the room.
@Component({
  selector: "item-inspect-display",
  templateUrl: "./component.html",
  animations: [trigger("appear", appear), trigger("flyInOut", flyInOut)],
})
@LogModule(...MODULE)
export class DisplayComponent implements OnInit, OnChanges {
  @Input() item: Item;
  @Input() snag: Snag;
  @Input() snagsCollection: Collection<Snag>;
  @Input() id: string;
  @Input() disabled: boolean;

  @Output() setActive = new EventEmitter<boolean>();
  @Output() startInspect = new EventEmitter<number>();

  public snagDocument: Document<Snag>;

  public mainName: string;
  public subNames: string[];

  public logger: Logger;

  ngOnChanges(simpleChanges: SimpleChanges) {
    [this.mainName, ...this.subNames] = parseItemName(this.item);

    // Advise the snag-document component of the document to manipulate.
    this.snagDocument = this.snagsCollection.doc(this.id);
  }

  ngOnInit() {
    if (this.logger.enabled) {
      const {item} = this;
      console.log(...this.logger.log({item}));
    }
  }

  public onSetActive(b: boolean) {
    this.setActive.emit(b);
  }

  public onStartInspect(inspectedOn: number) {
    this.startInspect.emit(inspectedOn);
  }
}

export const INSPECT_DECLARATIONS = [CollectionComponent, DisplayComponent];
