// blue/rooms/inspect-entry/component.ts
//
// Components for room entries used during inspection, tiles on the property inspection page.

import {Component, Input, EventEmitter, Output} from "@angular/core";
import {OnInit, OnDestroy} from "@angular/core";

import {AngularFirestore} from "angularfire2/firestore";

import {Subscription} from "rxjs";
import {map} from "rxjs/operators";

import {length} from "@nims/jsutils";
import {alert} from "@nims/cordova";

import {GenericCollectionComponent, GenericDocumentComponent, Logger, LogModule} from "../../utils";
import {Status, calcStatus} from "../../status/";

import {Room} from "../type";
import {Service} from "../service";

const MODULE = ["rooms", "entry"];

////////////////////////////////////////////////////////////////
// ROOM ENTRY DOCUMENT
//
// Based on the Firestore document for a room, display it by using the "display" component.
// Pass through document-based information such as status as transcluded content.
// Retrieve the status information from the cached info on the rooms service.
@Component({
  selector: "room-entry-document",
  template: `
<ng-container *ngIf="data$ | async as data">
  <room-entry-display
    [data]="data"
    [status]="status"
    [numPurchasableItems]="numPurchasableItems"

    (jump)="onJump()"
    (delete)="onMaybeDelete($event)"
    (edit)="onEdit($event)">
  </room-entry-display>
</ng-container>
`,
})
@LogModule(...MODULE)
export class DocumentComponent extends GenericDocumentComponent<Room> implements OnInit, OnDestroy {
  // Jump to a particular room ID.
  @Output() jump = new EventEmitter<string>();

  public status: Status;
  public numPurchasableItems: number;

  public logger: Logger;

  private statusSubscription: Subscription;
  private numPurchasableItemsSubscription: Subscription;

  constructor(private service: Service) {
    super();
  }

  public ngOnInit() {
    super.ngOnInit();

    // Get the (hopefully cached) list of items with snags for this room,
    // and derive the status from it.
    const status$ = this.service
      .getItemsWithSnags$(this.doc)
      .pipe(map(itemsWithSnags => calcStatus(itemsWithSnags)));

    this.statusSubscription = status$.subscribe(status => (this.status = status));

    const numPurchasableItems$ = this.service
      .calcPurchasableItems$(this.doc)
      .pipe(map(purchasableItems => length(purchasableItems)));

    this.numPurchasableItemsSubscription = numPurchasableItems$.subscribe(
      numPurchasableItems => (this.numPurchasableItems = numPurchasableItems)
    );

    this.logger.log$(status$, "status");
  }

  ngOnDestroy() {
    if (this.statusSubscription) this.statusSubscription.unsubscribe();
    if (this.numPurchasableItemsSubscription) this.numPurchasableItemsSubscription.unsubscribe();
  }

  // User has tapped on the room--jump to it.
  public onJump() {
    this.jump.emit(this.doc.ref.id);
  }
}

////////////////////////////////////////////////////////////////
// ROOM ENTRY DISPLAY
@Component({
  selector: "room-entry-display",
  templateUrl: "component.html",
  styleUrls: ["component.css"],
})
@LogModule(...MODULE)
export class DisplayComponent implements OnInit {
  @Input("data") room: Room;
  @Input() status: Status;
  @Input() numPurchasableItems: number;

  @Output() jump = new EventEmitter<void>();
  @Output() delete = new EventEmitter<Room>();
  @Output() edit = new EventEmitter<Room>();

  public upgrade: string;
  public roomTypeName: string;

  // Should the additional text describing the upgrade to something like Safety be shown?
  public dedicatedUpgrade = false;

  // Should the additional text describing the upgrade to something like Bedroom be shown?
  public partialUpgrade = false;

  public logger: Logger;

  constructor(private service: Service) {}

  ngOnChanges() {
    const roomType = this.service.getRoomType(this.room);

    this.upgrade = roomType.upgrade;
    this.roomTypeName = roomType.name;
  }

  ngOnInit() {
    if (this.logger.enabled) console.log(...this.logger.log({room: this.room}));
  }

  public onJump() {
    if (this.logger.enabled) console.log(...this.logger.log("jumping"));

    this.jump.emit();
  }

  public onBuyPackage() {
    if (!this.upgrade) return console.error("Missing upgrade on room type, can this happen?");

    return this.buy(this.upgrade);
  }

  public onBuyFull() {
    this.buy("full_checks");
  }

  public onNoItems() {
    alert("There are no items available for checking in this room.", "No items to check");
  }

  private buy(upgrade: string) {
    return this.service.buy(upgrade);
  }
}

////////////////////////////////////////////////////////////////
// ROOM COLLECTION
//
// Generate an entry for each element in the collection.
@Component({
  selector: "room-entry-collection",
  templateUrl: "collection.html",
})
@LogModule(...MODULE)
export class CollectionComponent extends GenericCollectionComponent<Room> {
  @Output() jump = new EventEmitter<string>();

  constructor(afs: AngularFirestore) {
    super(afs);
  }

  onJump(id: string) {
    this.jump.emit(id);
  }
}

export const ENTRY_DECLARATIONS = [CollectionComponent, DisplayComponent, DocumentComponent];
