// blue/my-property/measure: measure my own property

import {Component, OnInit} from "@angular/core";
import {Router, Routes} from "@angular/router";

import {map, take, tap} from "rxjs/operators";

import {Logger, LogModule} from "../../utils";
import {HasPropertyGuard} from "../has-property.guard";

import {Service} from "../service";

const MODULE = ["my-property", "measure"];

@Component({
  selector: "my-property-measure-route",
  template: `WAIT...`,
})
@LogModule(...MODULE)
export class RouteComponent implements OnInit {
  public logger: Logger;

  constructor(private service: Service, private router: Router) {}

  ngOnInit() {
    this.service.document$
      .pipe(
        take(1),
        map(({ref: {id}}) => id),
        tap(id => {
          if (this.logger.enabled) console.log(...this.logger.log({id}));
        })
      )
      .subscribe(id => this.router.navigate(["/property", "measure", id]));
  }
}

export const MEASURE_DECLARATIONS = [RouteComponent];
export const MEASURE_ROUTES: Routes = [
  {
    path: "measure",
    component: RouteComponent,
    canActivate: [HasPropertyGuard],
    data: {screenName: "my-property-measure"},
  },
];

// TEST CHANGE!!! WILL THIS GET PICKED UP???
// ANOTHER TEST, CEHCK THIS OUT!!!
