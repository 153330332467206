// Manage the component and data related to capturing corners (shape and area).

import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {Feature} from "../type";

@Injectable()
export class Service {
  public open$ = new Subject<boolean>();

  public features: Feature[];

  private resolve: () => void;
  private reject: () => void;

  public reset() {
    this.features = [];
  }

  public addFeature(feature: Feature) {
    this.features.push(feature);
  }

  public done() {
    this.resolve();
  }

  public cancel() {
    this.reject();
  }

  public get(): Promise<Feature[]> {
    this.reset();
    this.open();

    return new Promise((resolve, reject) => {
      this.resolve = () => {
        this.close();
        resolve(this.features);
      };
      this.reject = () => {
        this.close();
        reject();
      };
    });
  }

  private open() {
    this.open$.next(true);
  }

  private close() {
    this.open$.next(false);
  }
}
