// blue/snags
//
// All logic and components related to snags (other than phohtos, which are in their own pod)

import {INSPECT_DECLARATIONS} from "./inspect/component";
import {REPORT_DECLARATIONS} from "./report/component";

export const SNAGS_DECLARATIONS = [...INSPECT_DECLARATIONS, ...REPORT_DECLARATIONS];

export {Snag, SNAG_COLOR, NOTSNAG_COLOR, UNINSPECTED_COLOR} from "./type";
