// blue/app/properties/inspect/component.ts
//
// Inspect property given as part of path.

const MODULE = ["properties", "inspect"];

import {Component, EventEmitter, Input, OnInit, OnDestroy, Output} from "@angular/core";
import {ActivatedRoute as Route, Router, Routes} from "@angular/router";

import {Observable, Subscription} from "rxjs";
import {map, pluck} from "rxjs/operators";

import {
  AngularFirestoreCollection as Collection,
  AngularFirestoreDocument as Document,
} from "angularfire2/firestore";

import {confirm} from "@nims/cordova";

import {Room, getRooms} from "../../rooms/type";
import {LogModule, Logger, exit} from "../../utils";
import {GenericDocumentComponent} from "../../utils";
import {Service as UiService} from "../../components/ui.service";

import {SignedInGuard} from "../../users/signed-in.guard";

import {Property} from "../type";
import {PropertiesService} from "../service";

////////////////////////////////////////////////////////////////
// INSPECT PROPERTY ROUTE

@Component({
  selector: "property-inspect-route",
  template: `
<ng-container *ngIf="doc$ | async as doc">
    <property-inspect-document [doc]="doc" (goMeasure)="onGoMeasure($event)"></property-inspect-document>
</ng-container>
`,
})
@LogModule(...MODULE)
export class RouteComponent implements OnInit {
  public doc$: Observable<Document<Property>>;

  public logger: Logger;

  constructor(private route: Route, private router: Router, private service: PropertiesService) {}

  ngOnInit() {
    this.doc$ = this.route.params.pipe(
      pluck("propertyId"),
      map((id: string) => this.service.properties().doc(id))
    );

    this.logger.log$(this.doc$);
  }

  public onGoMeasure(doc: Document<Property>) {
    const propertyId = doc.ref.id;

    this.router.navigate(["property", "measure", propertyId]);
  }
}

////////////////////////////////////////////////////////////////
// INSPECT A PROPERTY BASED ON ITS DOCUMENT

@Component({
  selector: "property-inspect-document",
  template: `
<ng-container *ngIf="data$ | async as data">
    <property-inspect-display [data]="data" (addRoom)="onAddRoom($event)" (goMeasure)="onGoMeasure()">

    <!-- TRANSCLUDED STATUS DISPLAY -->
    <div class="property-status-bar-display" style="height: 24px; ">
      <property-status-bar
        [property]="doc"
        [showNumerals]="true"
        [showDescription]="true"
        [dynamicDescription]="true">
      </property-status-bar>
    </div>

    <!-- TRANSCLUDED ENTRIES (ROOMS) -->
    <div class="room-entry-collection">
      <room-entry-collection [collection]="roomsCollection" (jump)="onJumpRoom($event)">
      </room-entry-collection>
    </div>

  </property-inspect-display>
</ng-container>
`,
})
@LogModule(...MODULE)
export class DocumentComponent extends GenericDocumentComponent<Property> implements OnInit {
  // Pass up a request to visit the measuring screen to the route component.
  @Output() goMeasure = new EventEmitter<Document<Property>>();

  public roomsCollection: Collection<Room>;

  public logger: Logger;

  constructor(private router: Router) {
    super();
  }

  ngOnInit() {
    if (this.logger.enabled) console.log(...this.logger.log("emit", {doc: this.doc}));

    super.ngOnInit();

    // Get the collection of rooms, to pass to the `inspect-property-display` component.
    // This is supposed to be in sorted order.
    this.roomsCollection = getRooms(this.doc);
  }

  public onJumpRoom(id: string) {
    this.router.navigate(["/rooms", this.doc.ref.id, id]);
  }

  public onGoMeasure() {
    this.goMeasure.emit(this.doc);
  }
}

////////////////////////////////////////////////////////////////
// PROPERTY DISPLAY

@Component({
  selector: "property-inspect-display",
  templateUrl: "component.html",
})
@LogModule(...MODULE)
export class DisplayComponent implements OnInit, OnDestroy {
  @Input("data") public property: Property;
  @Output() public addRoom = new EventEmitter<string>();
  @Output() goMeasure = new EventEmitter();

  public logger: Logger;

  private subscription: Subscription;

  constructor(private uiService: UiService) {}

  ngOnInit() {
    if (this.logger.enabled)
      console.log(...this.logger.log("entering ngOnInit", {property: this.property}));

    this.subscription = this.uiService.backbutton.subscribe(() => this.onBack());
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  // Exit the app (leaving it running in background).
  // This happens either when the hardward back button, or the left arrow in the status bar is pressed.
  public async onBack() {
    if ((await confirm("Leave dSnag!?")) === 1) exit();
  }

  public async onGoMeasure() {
    this.goMeasure.emit();
  }
}

// ROUTES
export const INSPECT_ROUTES: Routes = [
  {
    path: "inspect/:propertyId",
    component: RouteComponent,
    canActivate: [SignedInGuard],
    data: {screenName: "property-inspect"},
  },
];

export const INSPECT_DECLARATIONS = [RouteComponent, DocumentComponent, DisplayComponent];
