// blue/shapes/display.ts: component for displaying a "shape" (polygon).
// Shapes can be displayed as SVG, on a canvas (not used), or just as their area in text.

import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import Flatten from "flatten-js";
import polylabel from "polylabel";
import {reduceAngle, toDegrees} from "@nims/jsutils";
import {makePolygon, polygonSvgPath, tuplesToGeoJson} from "../utils";

// Superclass for components which display shapes in different ways.
// Set up the Flatten polygon.
export class ShapeDisplayComponent implements OnChanges {
  @Input() shape: [number, number][];

  public polygon: Flatten.Polygon;

  // Don't forget to call this from sub-classes.
  ngOnChanges(simpleChanges: SimpleChanges) {
    this.polygon = makePolygon(this.shape);
  }
}

// Display a "shape" (basically, a polygon at the moment).
@Component({
  selector: "shape-display-svg",
  templateUrl: "svg.html",
})
export class ShapeDisplaySvgComponent extends ShapeDisplayComponent implements OnChanges {
  @Input() fill = "transparent";
  @Input() close = true;

  public path: string;
  public viewBox: string;
  public lengths: number[];
  public centers: Flatten.Point[];
  public angles: number[];
  public polylabel: Flatten.Point;
  public area: number;

  ngOnChanges(simpleChanges: SimpleChanges) {
    super.ngOnChanges(simpleChanges);

    const {polygon} = this;
    const {box} = polygon;
    const width = box.xmax - box.xmin;
    const height = box.ymax - box.ymin;
    const scale = 100 / Math.max(width, height);
    const matrix = new Flatten.Matrix().scale(scale, scale).translate(-box.xmin, -box.ymin);
    const edges = Array.from(polygon.edges.values());

    this.area = polygon.area();

    this.lengths = edges.map(edge => edge.shape.length);
    this.angles = edges
      .map(edge => new Flatten.Vector(edge.shape.start, edge.shape.end).slope)
      .map(reduceAngle)
      .map(toDegrees);
    this.centers = edges.map(edge => edge.middle()).map(point => point.transform(matrix));
    this.path = polygonSvgPath(polygon.transform(matrix));

    const labelPos = polylabel(tuplesToGeoJson(this.shape));
    this.polylabel = new Flatten.Point(labelPos[0], labelPos[1]).transform(matrix);

    this.viewBox = `-10 -10 120 120`;
  }
}

// Component to display shape using canvas.
// NOT USED.
@Component({
  selector: "shape-display-canvas",
  template: "shape-display-canvas not implemented",
})
export class ShapeDisplayCanvasComponent extends ShapeDisplayComponent {}

// Component to display shape as area only.
// Used in report.
@Component({
  selector: "shape-display-area",
  template: `
<ng-container *ngIf="area; else no_area">{{area | sqft}}
</ng-container>

<ng-template #no_area>--</ng-template>
`,
})
export class ShapeDisplayAreaComponent extends ShapeDisplayComponent implements OnChanges {
  public area: number;

  ngOnChanges(simpleChanges: SimpleChanges) {
    super.ngOnChanges(simpleChanges);

    this.area = this.polygon.area();
  }
}

export const DECLARATIONS = [
  ShapeDisplayAreaComponent,
  ShapeDisplaySvgComponent,
  ShapeDisplayCanvasComponent,
];
