// Entry point for "components"--shared components.

import {DSnagComponent} from "./d-snag/component";
import {FbaLogEventDirective} from "./fba-log-event.directive";
import {HamburgerMenuComponent} from "./hamburger-menu/component";
import {LoggerComponent} from "./logger/component";
import {OfflineUpsell} from "./offline-upsell/component";
import {PremiumApp} from "./premium-app/component";
import {RunUpComponent} from "./run-up/component";
import {SidenavComponent} from "./sidenav/component";
import {Service as UiService} from "./ui.service";

export const COMPONENTS_DECLARATIONS = [
  DSnagComponent,
  FbaLogEventDirective,
  HamburgerMenuComponent,
  LoggerComponent,
  OfflineUpsell,
  PremiumApp,
  RunUpComponent,
  SidenavComponent,
];
export const COMPONENTS_EXPORTS = [];
export const COMPONENTS_PROVIDERS = [UiService];
export const COMPONENTS_ROUTES = [];
