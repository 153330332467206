// blue/app/my-property/report/component.ts
//
// Components for dealing with reporting on one'w own property.
//
import {Routes} from "@angular/router";
import {Component, Input, OnInit} from "@angular/core";

import {Observable} from "rxjs";
import {AngularFirestoreDocument as Document} from "angularfire2/firestore";

import {Property} from "../../properties";
import {LogModule, Logger} from "../../utils";
import {GenericDocumentComponent} from "../../utils";

import {Service} from "../service";
import {HasPropertyGuard} from "../has-property.guard";

const MODULE = ["my-property", "report"];

////////////////////////////////////////////////////////////////
// ROUTE FOR REPORTING ON MY PROPERTY.
//
// Associated with route `my-property/report`.

@Component({
  selector: "my-property-report-route",
  template: `
<ng-container *ngIf="doc$ | async as doc">
  <my-property-report-document [doc]="doc"></my-property-report-document>
</ng-container>
`,
})
@LogModule(...MODULE)
export class RouteComponent implements OnInit {
  public doc$: Observable<Document<Property> | null>;

  public logger: Logger;

  constructor(private service: Service) {}

  ngOnInit() {
    this.doc$ = this.service.document$;

    this.logger.log$(this.doc$);
  }
}

////////////////////////////////////////////////////////////////
// PROPERTY DOCUMENT FOR MY PROPERTY
//
// Emit a wrapper specific to my property reports,
// inside which we put the "app report", which is app-specific reporting stuff.
@Component({
  selector: "my-property-report-document",
  templateUrl: "document.html",
})
@LogModule(...MODULE)
export class DocumentComponent extends GenericDocumentComponent<Property> {}

////////////////////////////////////////////////////////////////
// DISPLAY
//
// Taking a flat set of property data, display the screen.
// Template allows for transcluded content containing actual report.
@Component({
  selector: "my-property-report-display",
  templateUrl: "component.html",
})
@LogModule(...MODULE)
export class DisplayComponent implements OnInit {
  @Input("data") property: Property;

  public logger: Logger;

  ngOnInit() {
    if (this.logger.enabled) console.log(...this.logger.log({property: this.property}));
  }
}

export const REPORT_DECLARATIONS = [DisplayComponent, DocumentComponent, RouteComponent];

export const REPORT_ROUTES: Routes = [
  {
    path: "report",
    component: RouteComponent,
    canActivate: [HasPropertyGuard],
    data: {screenName: "my-property-report"},
  },
];
