// blue/shares/report/resolver.ts
//
// When the `shared-report` path is visited, resolve to the relevant property.
// TODO: handle errors in shared report resolutino

import {Injectable} from "@angular/core";
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {pluck} from "rxjs/operators";
import {Service} from "./service";

@Injectable()
export class Resolver implements Resolve<any> {
  constructor(private service: Service) {}

  resolve(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    routerStateSnapshot: RouterStateSnapshot
  ) {
    const id: string = activatedRouteSnapshot.params[":sharedReportId"];

    return this.service.collection
      .doc(id)
      .valueChanges()
      .pipe(pluck("projectId"));
  }
}
