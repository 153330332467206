// blue/shares/report: Main component logic in app for showing shared reports.
//
// Display a project report based on a shared ID.
// Assumes that `property` is available as route data, giving a property document, likely put there by a resolver.
// This then calls Call the component `<report-shared-document>`,
// which allows handling of shared report-specific, document-related information.

import {Component, Input, OnInit} from "@angular/core";
import {ActivatedRoute as Route, Routes} from "@angular/router";
import {Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {AngularFirestoreDocument as Document} from "angularfire2/firestore";

import {Property} from "../../properties";
import {LogModule, Logger} from "../../utils";
import {GenericDocumentComponent} from "../../utils";

import {Service} from "../service";

const param = "sharedReportId";

////////////////////////////////////////////////////////////////
// ROUTE FOR SHARED REPORT DISPLAY
@Component({
  selector: "share-report-route",
  template: `
<ng-container *ngIf="doc$ | async as doc">
  <share-report-document [doc]="doc"></share-report-document>
</ng-container>
`,
})
@LogModule("reporting")
export class RouteComponent implements OnInit {
  public doc$: Observable<Document<Property>>;

  public logger: Logger;

  constructor(private route: Route, private service: Service) {}

  ngOnInit() {
    this.doc$ = this.route.params.pipe(
      map(params => params[param] as string),
      switchMap(srId => this.service.getPropertyDoc(srId))
    );

    this.logger.log$(this.doc$);
  }
}

////////////////////////////////////////////////////////////////
// HANDLE A DOCUMENT
//
// Handle the "document" for a shared property, for reporting purposes.

@Component({
  selector: "share-report-document",
  template: `
<ng-container *ngIf="data$ | async as data">
  <share-report-display [data]="data">

    <!-- Transclude shared component dealing with property document. -->
    <property-report-document [doc]="doc"></property-report-document>

  </share-report-display>
</ng-container>
`,
})
@LogModule("reporting")
export class DocumentComponent extends GenericDocumentComponent<Property> implements OnInit {}

////////////////////////////////////////////////////////////////
// DISPLAY FOR SHARED REPORT
//
// Display report on screen!
// Mainly, just invoke the common report logic.

@Component({
  selector: "share-report-display",
  templateUrl: "component.html",
})
@LogModule("reporting")
export class DisplayComponent implements OnInit {
  @Input("data") property: Property;

  public logger: Logger;

  constructor() {}

  ngOnInit() {
    if (this.logger.enabled) console.log(...this.logger.log({property: this.property}));
  }
}

export const REPORT_DECLARATIONS = [RouteComponent, DocumentComponent, DisplayComponent];
export const REPORT_ROUTES: Routes = [
  {path: `shared-report/:${param}`, component: RouteComponent, data: {screenName: "shared-report"}},
];
