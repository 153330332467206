// HAMBURGER MENU!
//
// Just a button, which opens the sidenav.

import {Component} from "@angular/core";
import {Service as UiService} from "../ui.service";
import {LogModule} from "../../utils";

@Component({
  selector: "hamburger-menu",
  template: `<mat-icon (click)="onClick()">menu</mat-icon>`,
  styles: [":host { line-height: 1; }"],
})
@LogModule("components")
export class HamburgerMenuComponent {
  constructor(private uiService: UiService) {}

  public onClick() {
    this.uiService.openSidenav();
  }
}
