// Create Fireabse dynamic links.

// Magic domain which you can see in the Firebase Console dynamic links sections.
const DYNAMIC_LINK_DOMAIN = "https://wue95.app.goo.gl";

// TODO: Put this somewhere else.
const ANDROID_PACKAGE_NAME = "in.nemmadi.dsnag";

// TODO: Change this when the website/app is hosted at dsnag.in.
export const FIREBASE_HOSTING_DOMAIN = "https://app.dsnag.in";

// These would be used if we were going to use the REST API for constructing links, which we aren't right now.
// const DSNAG_WEB_API_KEY = "AIzaSyC6xhGXd3KkA3YJaoHnuKLm4dP55U_Nw6s";
// const DYNAMIC_LINKS_API_URL = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${DSNAG_WEB_API_KEY}`;

////////////////////////////////////////////////////////////////
// MANUALLY CREATE DYNAMIC LINK
// See https://firebase.google.com/docs/dynamic-links/create-manually.
//
// If you don't need to track click data and you don't care if the links are long,
// you can manually construct Dynamic Links using URL parameters, and by doing so, avoid an extra network round trip.

export function dynamicLinkUrl(link: string) {
  return `${DYNAMIC_LINK_DOMAIN}/?link=${link}&apn=${ANDROID_PACKAGE_NAME}`;
}
