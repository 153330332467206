// navigation.service.ts
//
// Manage up/down/left/right navigation.
// A component should "push" its nav data in `ngOnInit`,
// and "pop" it in `ngOnDestroy`.

import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

interface Data {
  fn: Function;
  text: string;
}

interface Entry {
  up?: Data;
  down?: Data;
  left?: Data;
  right?: Data;
}

@Injectable()
export class NavigationService {
  private stack: Entry[] = [{}];
  public nav$ = new Subject<Entry>();
  public disabled = false;

  public navEntry: Entry = {};

  public push(navEntry: any) {
    this.stack.push(navEntry);
    this.nav$.next(navEntry);
  }

  public pop() {
    this.stack.pop();
    this.nav$.next(this.stack[this.stack.length - 1]);
  }
}
