// blue/properties/status/component.ts: components for displaying property status.

import {Component, Input, OnInit} from "@angular/core";

import {Observable} from "rxjs";
import {AngularFirestoreDocument as Document} from "angularfire2/firestore";

import {Logger, LogModule} from "../../utils";
import {Status} from "../../status";

import {Property} from "../type";
import {PropertiesService} from "../service";

const MODULE = ["properties", "status"];

////////////////////////////////////////////////////////////////
// SUPERCLASS FOR PIE AND BAR
@LogModule(...MODULE)
export class StatusDisplay implements OnInit {
  @Input() property: Document<Property>;

  public status$: Observable<Status>;
  public logger: Logger;

  constructor(private service: PropertiesService) {}

  ngOnInit() {
    // Get cached status observable.
    this.status$ = this.service.getStatus$(this.property);

    this.logger.log$(this.status$);
  }
}

////////////////////////////////////////////////////////////////
// PROPERTY STATUS AS BAR
@Component({
  selector: "property-status-bar",
  template: `
<ng-container *ngIf="status$ | async as status">
  <status-bar
    [status]="status"
    [showNumerals]="showNumerals"
    [showDescription]="showDescription"
    [dynamicDescription]="dynamicDescription">
  </status-bar>
</ng-container>
`,
})
export class Bar extends StatusDisplay {
  @Input() showNumerals = true;
  @Input() showDescription = true;
  /* If dynamicDescription value is true, description gets displayed after checking
   another condition ( category percentage greater than 50 )*/
  @Input() dynamicDescription = true;
  constructor(service: PropertiesService) {
    super(service);
  }
}

////////////////////////////////////////////////////////////////
// PROPERTY STATUS AS PIE
@Component({
  selector: "property-status-pie",
  template: `
<ng-container *ngIf="status$ | async as status">
  <status-pie [status]="status"></status-pie>
</ng-container>
`,
})
export class Pie extends StatusDisplay {
  constructor(service: PropertiesService) {
    super(service);
  }
}

export const STATUS_DECLARATIONS = [Bar, Pie];
