// blue/rooms/measure/component.ts
//
// Components for panes for entering room measurements.

import {Component, Input, EventEmitter, OnChanges, Output, OnInit} from "@angular/core";
import {AngularFirestore} from "angularfire2/firestore";

import {generateRandomColor} from "@nims/jsutils";

import {GenericCollectionComponent, GenericDocumentComponent, Logger, LogModule} from "../../utils";

import {
  deserializeShape,
  getDeviceHeight,
  makeShapeFromFeatures,
  serializeShape,
  Shape,
  ShapeCaptureService,
} from "../../shapes";

import {Service} from "../service";
import {Room} from "../type";

const MODULE = ["rooms", "measure"];

////////////////////////////////////////////////////////////////
// ROOM MEASUREMENT PANE BASED ON ROOM DOCUMENT
//
// Based on the Firestore document for a room, display a pane for measureing it using the `rooms-measure-display" component.
// This component's only job is to pass the actual data through to the display component,
// and handle changes to the shape information.
@Component({
  selector: "room-measure-document",
  template: `
<ng-container *ngIf="data$ | async as data">
  <room-measure-display [data]="data"></room-measure-display>
</ng-container>
`,
})
@LogModule(...MODULE)
export class DocumentComponent extends GenericDocumentComponent<Room> {
  // The display component has captured a enw shape.
  public onShapeChange(shape: Shape) {
    this.doc.update({shape: serializeShape(shape)});
  }
}

////////////////////////////////////////////////////////////////
// ROOM MEASUREMENT DISPLAY
//
// This component's only job is to display the tile with the room title,
// and invoke a component to display/edit the room's "shape".
@Component({
  selector: "room-measure-display",
  templateUrl: "component.html",
  styleUrls: ["component.css"],
})
@LogModule(...MODULE)
export class DisplayComponent implements OnInit, OnChanges {
  @Input("data") room: Room;

  @Output() shapeChange = new EventEmitter<Shape>();

  public shape: Shape;
  public fill: string;
  public isSingleton: boolean;

  public logger: Logger;

  constructor(private service: Service, private shapeCaptureService: ShapeCaptureService) {}

  ngOnChanges() {
    this.isSingleton = this.service.getRoomType(this.room).singleton;
    this.fill = generateRandomColor(223, 223, 190, 0.3, this.room.name);
  }

  ngOnInit() {
    this.shape = this.room.shape && deserializeShape(this.room.shape);
  }

  public async onCapture() {
    try {
      await getDeviceHeight();

      const features = await this.shapeCaptureService.get();
      const shape = makeShapeFromFeatures(features);

      this.shapeChange.emit((this.shape = shape));
    } catch (e) {}
  }
}

////////////////////////////////////////////////////////////////
// ROOM COLLECTION
//
// Generate an entry for each element in the collection.
@Component({
  selector: "room-measure-collection",
  templateUrl: "collection.html",
})
@LogModule(...MODULE)
export class CollectionComponent extends GenericCollectionComponent<Room> {
  constructor(afs: AngularFirestore) {
    super(afs);
  }
}

export const DECLARATIONS = [CollectionComponent, DisplayComponent, DocumentComponent];
