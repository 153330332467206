// blue/properties/measure/component.ts
//
// Components for displaying measurements of the rooms in the property,
// and handling requests for (re-)capturing and updating shapes.

const MODULE = ["properties", "measure"];

import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ActivatedRoute as Route, Router, Routes} from "@angular/router";

import {Observable} from "rxjs";
import {map, pluck} from "rxjs/operators";

import {
  AngularFirestoreCollection as Collection,
  AngularFirestoreDocument as Document,
} from "angularfire2/firestore";

import {Room, getRooms} from "../../rooms/type";

import {LogModule, Logger} from "../../utils";
import {GenericDocumentComponent} from "../../utils";

import {SignedInGuard} from "../../users/signed-in.guard";

import {Property} from "../type";
import {PropertiesService} from "../service";

////////////////////////////////////////////////////////////////
// MEASURE PROPERTY ROUTE
//
// Measure rooms in property given as part of path.
// Might be invoked from `my-property/measure` route.

@Component({
  selector: "property-measure-route",
  template: `
<ng-container *ngIf="doc$ | async as doc">
  <property-measure-document
    [doc]="doc"
    (back)="onBack(doc)">
  </property-measure-document>
</ng-container>
`,
})
@LogModule(...MODULE)
export class RouteComponent implements OnInit {
  public doc$: Observable<Document<Property>>;

  public logger: Logger;

  constructor(private route: Route, private router: Router, private service: PropertiesService) {}

  ngOnInit() {
    this.doc$ = this.route.params.pipe(
      pluck("propertyId"),
      map((id: string) => this.service.properties().doc(id))
    );

    this.logger.log$(this.doc$);
  }

  // Going "back" means going to the proeprty inspection screen.
  public onBack(doc: Document<Property>) {
    this.goInspect(doc);
  }

  private goInspect(doc: Document<Property>) {
    const propertyId = doc.ref.id;

    this.router.navigate(["property", "inspect", propertyId]);
  }
}

////////////////////////////////////////////////////////////////
// MEASURE PROPERTY BASED ON ITS DOCUMENT
//
// Retrieve data, add headers, and invoke the component to do the actual display.

@Component({
  selector: "property-measure-document",
  templateUrl: "document.html",
  styles: [
    `
      .property-area-display {
        background-color: wheat;
      }
    `,
  ],
})
@LogModule(...MODULE)
export class DocumentComponent extends GenericDocumentComponent<Property> implements OnInit {
  @Output() back = new EventEmitter();

  public roomsCollection: Collection<Room>;
  public area$: Observable<number>;

  public logger: Logger;

  constructor(private service: PropertiesService) {
    super();
  }

  ngOnInit() {
    if (this.logger.enabled) console.log(...this.logger.log("emit", {doc: this.doc}));

    super.ngOnInit();

    // Get the collection of rooms, to pass to the `measure-property-display` component.
    // This is supposed to be in sorted order.
    // TODO: Either here, or somewhere elwe, omit "virtual" rooms.
    this.roomsCollection = getRooms(this.doc);
    this.area$ = this.service.getArea$(this.doc);

    this.logger.log$(this.area$, "area");
  }

  public onBack() {
    this.back.emit();
  }
}

////////////////////////////////////////////////////////////////
// DISPLAY SCREEN FOR MEASURING PROPERTY, GIVEN PROPERTY OBJECT

@Component({
  selector: "property-measure-display",
  templateUrl: "component.html",
})
@LogModule(...MODULE)
export class DisplayComponent implements OnInit {
  @Input("data") public property: Property;

  @Output() back = new EventEmitter<void>();

  public logger: Logger;

  ngOnInit() {
    if (this.logger.enabled)
      console.log(...this.logger.log("entering ngOnInit", {property: this.property}));
  }

  public onBack() {
    this.back.emit();
  }
}

// ROUTES
export const MEASURE_ROUTES: Routes = [
  {
    path: "measure/:propertyId",
    component: RouteComponent,
    canActivate: [SignedInGuard],
    data: {screenName: "property-measure"},
  },
];

export const MEASURE_DECLARATIONS = [RouteComponent, DocumentComponent, DisplayComponent];
