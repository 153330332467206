// blue/room-types/service.ts
//
// Services related to room types.
// Keep a static versino of roomtypes, guaranteed to be present once "ready" is fulfilled.

import {Injectable} from "@angular/core";
import {filter, pairs} from "@nims/jsutils";

function byPairName([, {name: name1}], [, {name: name2}]) {
  return name1.localeCompare(name2);
}
function isNotSingleton([, {singleton}]) {
  return !singleton;
}

import {
  AngularFirestore,
  AngularFirestoreCollection as Collection,
  QueryFn,
} from "angularfire2/firestore";

import {afsCollectionToObjectOfData} from "@nims/afutils";
import {Hash} from "@nims/jsutils";

import {RoomType} from "./type";
import {Logger, LogModule} from "../utils";

@Injectable()
@LogModule("room-types")
export class Service {
  public roomTypesO: Hash<RoomType>;
  public ready: Promise<void>;

  public logger: Logger;

  constructor(private afs: AngularFirestore) {
    this.ready = this.initialize();
  }

  public pairs() {
    return pairs(this.roomTypesO);
  }

  public filteredPairs() {
    return this.pairs()
      .filter(isNotSingleton)
      .sort(byPairName);
  }

  public get(queryFn?: QueryFn): Collection<RoomType> {
    return this.afs.collection<RoomType>("room-types", queryFn);
  }

  public getByUpgrade(upgrade: string) {
    return filter(this.roomTypesO, roomType => roomType.upgrade === upgrade);
  }

  private initialize(): Promise<any> {
    return new Promise(resolve =>
      afsCollectionToObjectOfData(this.get()).subscribe(roomTypesO =>
        resolve((this.roomTypesO = roomTypesO))
      )
    );
  }
}
