// blue/rooms: Everything to do with rooms.
//
// Display a card about them. Inspect their items. Report on them. Measure them.

import {EDIT_DECLARATIONS} from "./edit/component";
import {ENTRY_DECLARATIONS as INSPECT_ENTRY_DECLARATIONS} from "./inspect-entry/component";
import {DECLARATIONS as MEASURE_DECLARATIONS} from "./measure/component";
import {DECLARATIONS as REPORT_AREA_DECLARATIONS} from "./report-area/component";
import {DECLARATIONS as REPORT_STATUS_DECLARATIONS} from "./report-status/component";
import {DECLARATIONS as REPORT_ENTRY_DECLARATIONS} from "./report-entry/component";
import {
  SCREEN_DECLARATIONS as INSPECT_SCREEN_DECLARATIONS,
  SCREEN_ROUTES as INSPECT_SCREEN_ROUTES,
} from "./inspect-screen/component";
import {STATUS_DECLARATIONS} from "./status/component";

import {Service} from "./service";
import {Room, sortRooms} from "./type";

const ROOMS_DECLARATIONS = [
  ...EDIT_DECLARATIONS,
  ...INSPECT_ENTRY_DECLARATIONS,
  ...INSPECT_SCREEN_DECLARATIONS,
  ...MEASURE_DECLARATIONS,
  ...REPORT_AREA_DECLARATIONS,
  ...REPORT_ENTRY_DECLARATIONS,
  ...REPORT_STATUS_DECLARATIONS,
  ...STATUS_DECLARATIONS,
];
const ROOMS_PROVIDERS = [Service];
const ROOMS_ROUTES = [...INSPECT_SCREEN_ROUTES];

export {
  Room,
  sortRooms,
  Service as RoomsService,
  ROOMS_DECLARATIONS,
  ROOMS_PROVIDERS,
  ROOMS_ROUTES,
};
