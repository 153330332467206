// blue/users/tos/component.ts
//
// Terms and conditions.

import {Component} from "@angular/core";

@Component({
  selector: "tos",
  templateUrl: "component.html",
  styleUrls: ["component.css"],
})
export class TosComponent {
  public LOG = false;
  public MODULE_NAME = "users/tos.component";
}
