// blue/properties/report/status/component.ts
//
// Room-by-room status portion of report.
// Invoked from main report display template.

const MODULE = ["properties", "report", "status"];

import {Component, EventEmitter, Input, Output} from "@angular/core";

import {AngularFirestoreCollection as Collection} from "angularfire2/firestore";

import {Status} from "../../../status";
import {GenericDocumentComponent} from "../../../utils";
import {LogModule} from "../../../utils";
import {Room, getRooms} from "../../../rooms/type";

import {Property} from "../../type";

////////////////////////////////////////////////////////////////
// PROPERTY STATUS DISPLAY FOR REPORT DOCUMENT)
//
// Handle property status display on report, based on the AF "document", which allows us to get status, rooms, etc.
@Component({
  selector: "property-report-status-document",
  template: `
<ng-container *ngIf="data$ | async as data">
  <property-report-status-display [data]="data" [rooms]="rooms" (jump)="onJump($event)">

    <property-status-bar
      [property]="doc"
      [showNumerals]="true"
      [showDescription]="false"
      [dynamicDescription]="false">
    </property-status-bar>

  </property-report-status-display>
</ng-container>
`,
})
@LogModule(...MODULE)
export class DocumentComponent extends GenericDocumentComponent<Property> {
  @Output() jumpRoom = new EventEmitter<string>();

  public rooms: Collection<Room>;
  public overallStatus: Status;

  ngOnChanges() {
    this.rooms = getRooms(this.doc);

    // TODO: fix this! Is this necessary any more?
    this.overallStatus = {} as any;
  }

  public onJump(roomId) {
    this.jumpRoom.emit(roomId);
  }
}

////////////////////////////////////////////////////////////////
// PROPERTY STATUS DISPLAY FOR REPORT
//
// Handle display of property status in report, based on the actual property object.
@Component({
  selector: "property-report-status-display",
  templateUrl: "component.html",
})
@LogModule(...MODULE)
export class DisplayComponent {
  @Input("data") property: Property;
  @Input() rooms: Collection<Room>;

  @Output() jump = new EventEmitter<string>();

  public onJump(roomId: string) {
    this.jump.emit(roomId);
  }
}

export const STATUS_DECLARATIONS = [DisplayComponent, DocumentComponent];
