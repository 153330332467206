// blue/shapes/device-height.ts

import {prompt} from "@nims/cordova";

const DEVICE_HEIGHT_KEY = "dsnag.deviceHeight";

// The current device height in mm.
// Zero means not set.
// To set this, please use `setDeviceHeight` below.
export let deviceHeight = +localStorage.getItem(DEVICE_HEIGHT_KEY);

// Set some device height, probably obtained via the dialog box below,
// in memory and local storage.
export function setDeviceHeight(h: number) {
  localStorage.setItem(DEVICE_HEIGHT_KEY, String((deviceHeight = h)));
  return h;
}

// Local function to obtain device height via dialog box.
// TODO: Replace this with a nice slider or something.
async function promptDeviceHeight() {
  const msg = `To allow us to detect room corners, and calculate floor space, please enter your height as a number in centimeters, for example "160".`;

  while (true) {
    const value = deviceHeight ? String(Math.round(deviceHeight / 10)) : "";
    const {buttonIndex, input1} = await prompt(msg, "Device height", ["OK"], value);
    const input = +input1;

    if (buttonIndex === 2) throw new Error("User canceled setting of device height");
    if (!isNaN(input)) return input * 10;
  }
}

// Obtain device height from user and store.
export async function inputDeviceHeight() {
  return setDeviceHeight(await promptDeviceHeight());
}

export function getDeviceHeight() {
  return deviceHeight || inputDeviceHeight();
}
