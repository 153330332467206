import {enableProdMode} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {ravenConfig} from "@nims/ngutils";
import {AppModule} from "./index";
import {environment} from "./environments/environment";

const {production, sentryDsn} = environment;

if (production && sentryDsn) ravenConfig(sentryDsn);
if (production) enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule);
