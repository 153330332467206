// blue/properties/report/area/component.ts
//
// Components for floor space portion of report.

const MODULE = ["properties", "report", "area"];

import {Component, Input, SimpleChanges} from "@angular/core";
import {Observable} from "rxjs";

import {AngularFirestoreCollection as Collection} from "angularfire2/firestore";

import {Status} from "../../../status";
import {GenericDocumentComponent} from "../../../utils";
import {LogModule} from "../../../utils";
import {Room, getRooms} from "../../../rooms/type";

import {Property} from "../../type";
import {PropertiesService} from "../../service";

////////////////////////////////////////////////////////////////
// PROPERTY AREA FOR REPORT (DOCUMENT)
//
// Given a property document, display area information for the report.
@Component({
  selector: "property-report-area-document",
  template: `
<ng-container *ngIf="data$ | async as data">
  <property-report-area-display [data]="data" [rooms]="rooms">

    <ng-container *ngIf="area$ | async as area">
      {{area | sqft}}
    </ng-container>

  </property-report-area-display>
</ng-container>
`,
})
@LogModule(...MODULE)
export class DocumentComponent extends GenericDocumentComponent<Property> {
  public rooms: Collection<Room>;
  public overallStatus: Status;
  public area$: Observable<number>;

  constructor(private service: PropertiesService) {
    super();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.rooms = getRooms(this.doc);
    this.area$ = this.service.getArea$(this.doc);
  }
}

////////////////////////////////////////////////////////////////
// PROPERTY AREA DISPLAY FOR REPORT
//
// Handle displaying areas of rooms in a property, based on the actual data.
@Component({
  selector: "property-report-area-display",
  templateUrl: "component.html",
})
@LogModule(...MODULE)
export class DisplayComponent {
  @Input("data") property: Property;
  @Input() rooms: Collection<Room>;
}

export const AREA_DECLARATIONS = [DisplayComponent, DocumentComponent];
