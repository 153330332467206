// blue/shares/shared-report-browser/component.ts: Component for showing shared report in browser.
//
// Display a project report based on a shared ID.
// Assumes that `property` is available as route data, giving a property document, likely put there by a resolver.

import {Component, Input, OnInit} from "@angular/core";
import {ActivatedRoute as Route, Routes} from "@angular/router";
import {Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {AngularFirestoreDocument as Document} from "angularfire2/firestore";

import {Property} from "../../properties";
import {LogModule, Logger} from "../../utils";
import {GenericDocumentComponent} from "../../utils";

import {Service} from "../service";

const param = "sharedReportId";

////////////////////////////////////////////////////////////////
// ROUTE FOR SHARED REPORT DISPLAY
@Component({
  selector: "shared-report-browser-route",
  template: `
<ng-container *ngIf="doc$ | async as doc">
  <shared-report-browser-document [doc]="doc"></shared-report-browser-document>
</ng-container>
`,
})
@LogModule("reporting")
export class RouteComponent implements OnInit {
  public doc$: Observable<Document<Property>>;

  public logger: Logger;

  constructor(private route: Route, private service: Service) {}

  ngOnInit() {
    this.doc$ = this.route.params.pipe(
      map(params => params[param] as string),
      switchMap(srId => this.service.getPropertyDoc(srId))
    );

    this.logger.log$(this.doc$);
  }
}

////////////////////////////////////////////////////////////////
// HANDLE A DOCUMENT
//
// Handle the "document" for a shared property, for reporting purposes.

@Component({
  selector: "shared-report-browser-document",
  template: `
<ng-container *ngIf="data$ | async as data">
  <shared-report-browser-display [data]="data">

    <!-- Transclude shared component dealing with property document. -->
    <property-report-document [doc]="doc"></property-report-document>

  </shared-report-browser-display>
</ng-container>
`,
})
@LogModule("reporting")
export class DocumentComponent extends GenericDocumentComponent<Property> implements OnInit {}

////////////////////////////////////////////////////////////////
// DISPLAY FOR SHARED REPORT
//
// Display report on screen!
// Mainly, just invoke the common report logic.

@Component({
  selector: "shared-report-browser-display",
  templateUrl: "component.html",
})
@LogModule("reporting")
export class DisplayComponent implements OnInit {
  @Input("data") property: Property;

  public logger: Logger;

  constructor() {}

  ngOnInit() {
    if (this.logger.enabled) console.log(...this.logger.log({property: this.property}));
  }
}

export const DECLARATIONS = [RouteComponent, DocumentComponent, DisplayComponent];
export const ROUTES: Routes = [{path: `shared-report/:${param}`, component: RouteComponent}];
