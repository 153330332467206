// get-photo.ts: get a picture from the device's camera.

import {testImage} from "@nims/jsutils";

import {Photo} from "./type";
import {getPicture, captureImage} from "@nims/cordova";
import {orientation$} from "@nims/jsutils";

function defaultCameraOptions(): CameraOptions {
  const destinationType = Camera.DestinationType.DATA_URL;
  const sourceType = Camera.PictureSourceType.CAMERA;
  const encodingType = Camera.EncodingType.JPEG;
  const mediaType = Camera.MediaType.PICTURE;

  return {
    allowEdit: false,
    correctOrientation: true,
    destinationType,
    encodingType,
    mediaType,
    quality: 90,
    saveToPhotoAlbum: false,
    sourceType,
    targetHeight: 640,
    targetWidth: 480,
  };
}

// Get a picture from the camera, returning a promise for base64 representation.
async function capture(cameraOptions?: CameraOptions) {
  const options = {...defaultCameraOptions(), ...cameraOptions};
  const byteString = await getPicture(options);

  return byteString;

  // TODOOOO: figure out how to make this work.
  // const blob = base64ToBlob(byteString, "image/jpeg");

  // const thumbnail = await resizeImageFromUrl(URL.createObjectURL(blob), {
  //   MAX_WIDTH: 320,
  //   MAX_HEIGHT: 240,
  // });
  // const base64 = await readBlob(thumbnail);

  // return base64;
}

export async function captureNimsImage(): Promise<string> {
  return captureImage();
}

export async function getPhoto(cameraOptions?: CameraOptions): Promise<Photo> {
  // If the capture throws, for reasons such as no permission, it will bubble up to caller.
  const photo: Photo = {imageBase64: !window.cordova ? testImage : await capture()};

  // Get the orientation. However, give up if it's taking too long.
  // Set orientation property on photo only if we actually have it.
  try {
    photo.orientation = await new Promise<number>((resolve, reject) => {
      orientation$.toPromise().then(orientation => resolve(orientation));
      setTimeout(reject, 250);
    });
  } catch (e) {
    // Ignore error or timeout getting orientation.
  }

  return photo;
}
