// blue/shapes: Everything to do with shapes, collections of "shapes" making up a room.

import {DECLARATIONS as DISPLAY_DECLARATIONS} from "./display/component";
import {CaptureComponent} from "./capture/component";
import {Service as CaptureService} from "./capture/service";

const SHAPES_DECLARATIONS = [CaptureComponent, ...DISPLAY_DECLARATIONS];
const SHAPES_PROVIDERS = [CaptureService];
const SHAPES_ROUTES = [];

export {SHAPES_DECLARATIONS, SHAPES_PROVIDERS, SHAPES_ROUTES};

export {CaptureService as ShapeCaptureService} from "./capture";
export {getDeviceHeight} from "./device-height";
export * from "./type";
export * from "./utils";
