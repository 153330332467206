// blue/components/sidenav/component.ts
//
// TS logic for sidenav.

const MODULE = ["components"];

import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";

import {Subscription, Observable} from "rxjs";

import * as firebase from "firebase";

import {confirm} from "@nims/cordova";

import {Service as MyPropertyService} from "../../my-property/service";
import {Property} from "../../properties/type";
import {Service as UserService} from "../../users/service";

import {IS_PREMIUM_VERSION} from "../../environments/constants";
import {environment} from "../../environments/environment";

import {Logger, LogModule} from "../../utils";

@Component({
  selector: "app-sidenav",
  templateUrl: "component.html",
  styleUrls: ["component.css"],
})
@LogModule(...MODULE)
export class SidenavComponent {
  // Used to hide the menu item for in-app purchases.
  public IS_PREMIUM_VERSION = IS_PREMIUM_VERSION;
  public features = environment.features;
  public uid: string;
  public myProperty: Property;
  public afuser$: Observable<firebase.User>;

  public logger: Logger;

  private uidSubscription: Subscription;
  private myPropertySubscription: Subscription;

  constructor(
    private matSnackBar: MatSnackBar,
    private myPropertyService: MyPropertyService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userService.init();
    this.uidSubscription = this.userService.uid$.subscribe(uid => (this.uid = uid));
    this.afuser$ = this.userService.afuser$;
    this.myPropertySubscription = this.myPropertyService.property$.subscribe(
      property => (this.myProperty = property)
    );
  }

  ngOnDestroy() {
    if (this.uidSubscription) this.uidSubscription.unsubscribe();
    if (this.myPropertySubscription) this.myPropertySubscription.unsubscribe();
  }

  // Sign out.
  // This will trigger an emission on the AngularFire auth observable,
  // which will take the user to the login page.
  public signOut() {
    return this.userService.signOut();
  }

  // This option is mainly for the visitor who is viewing a shared report.
  // It is displayed only when NOT signed-in.
  // We'll probably also give him the option to sign-up from the shared report page.
  public signUp() {
    return this.router.navigate(["/user", "sign-in"]);
  }

  public async resetProperty() {
    const button = await confirm(
      `Really reset your property?
This will permanently remove all data, and cannot be undone!`
    );

    if (button === 1) {
      // TODOO: implement property reset proeprly
      try {
        await this.myPropertyService.reset();
        this.matSnackBar.open("Property successfully delete", "", {duration: 2000});
        this.router.navigate(["/property", "new"]);
      } catch (e) {
        this.matSnackBar.open("Oops, property deletion failed.", "", {duration: 2000});
      }
    }
  }
}
