// blue/items/type.ts
//
// Interface for the `Item` type, representing a single item in a checklist.
// An inspected item (its results) is represented by the `Snag` object.

import {QueryFn} from "angularfire2/firestore";
import * as firebase from "firebase";
import {SUPERSCRIPT_NUMBERS} from "@nims/jsutils";

import {Snag} from "../snags/type";

export interface Item {
  name: string;

  // This item applies to these room types.
  roomTypes: {[roomTypeId: string]: boolean};

  isPremium: boolean;

  // This is not an ID--we do not handle aspects as first-class data items.
  // It's just a string.
  aspect: string;

  // This is not some kind of pointer or foreign key.
  // We do not handle sections as first-class data items.
  // It's just a string for the time being.
  section: string;

  order: number;

  // This item does not take a picture.
  noPhoto: boolean;
}

// Item names are adorned, in some cases, with a kind of note enclosed in square brackets at the end.
// To display these more visually, break them apart.
// Replace with a footnote-like reference (numeric superscript).
export function parseItemName({name}): string[] {
  let n = 0;

  const comment = /\s*\[.*?\]/g;
  const comments = name.match(comment);
  const numberedComments =
    comments && comments.map((s, i) => SUPERSCRIPT_NUMBERS[i + 1] + s.replace(/^\s*\[|\]$/g, ""));
  const main = name.replace(comment, seg => SUPERSCRIPT_NUMBERS[++n]);

  return comments ? [main, ...numberedComments] : [name];
}

// Given a room type (ID), produce a function to check if an item applies to it.
export function itemRoomTypeChecker(roomTypeId: string) {
  return (item: Item) => {
    const {roomTypes} = item;

    if (!roomTypes) {
      console.error("Defective item, missing roomTypes", item);
      return false;
    }

    return roomTypes[roomTypeId];
  };
}

export function isItemPremium(item: Item) {
  return item.isPremium;
}

// Make a filter to grab only those items with a particular aspect.
export function aspectFilterer(aspect: string) {
  return (item: Item) => item.aspect === aspect;
}

// A AngularFirestore query to retrieve items with a particular roomType,
// and/or a non-premium setting.
export function itemRoomTypeQuery(roomType: string): QueryFn {
  return (ref: firebase.firestore.CollectionReference) =>
    ref.where(`roomTypes.${roomType}`, "==", true);
}

export function itemRoomTypeAndNotPremiumQuery(roomType: string): QueryFn {
  return (ref: firebase.firestore.CollectionReference) =>
    ref.where(`roomTypes.${roomType}`, "==", true).where("isPremium", "==", false);
}

// Sort items by `order` field within `section`.
export function sortItems({order: order1, section: section1}, {order: order2, section: section2}) {
  return section1.localeCompare(section2) || order1 - order2;
}

// Is an item purchasable?
export function purchasabilityChecker(roomTypeUpgrade: string, roomUpgraded: boolean) {
  return (item: Item) => !!roomTypeUpgrade && !roomUpgraded && isItemPremium(item);
}

// Is an item available, based on the room upgrade status, and the item's premium status?
export function availabilityChecker(roomUpgraded: boolean) {
  return (item: Item) => roomUpgraded || !isItemPremium(item);
}

////////////////////////////////////////////////////////////////
// ITEMS WITH SNAGS

// A special type combining items and correspoding snags (if any).
// Normally, we would keep arrays of these.
export interface ItemWithSnag {
  id: string;
  item: Item;
  snag: Snag | null;
}

// Filter out item/snag pairs which are uninspected.
export function isItemUninspected({snag}: ItemWithSnag) {
  return !snag;
}
