// blue/rooms/report-area/component.ts
//
// Components for room area display on a report.
//
// TODO: Since we are not doing anything with the individual rooms here,
// the entire machinery for dealing with collections, and having a separate "document" based component
// is probably completely overkill.

const MODULE = ["rooms", "report-area"];

import {Component, Input, OnInit} from "@angular/core";
import {AngularFirestore} from "angularfire2/firestore";

import {deserializeShape} from "../../shapes/utils";

import {GenericCollectionComponent, GenericDocumentComponent, LogModule} from "../../utils";
import {Shape} from "../../shapes/type";

import {Room} from "../type";
import {Service} from "../service";

////////////////////////////////////////////////////////////////
// ROOM AREA DISPLAY IN REPORT BASED ON ROOM DOCUMENT
//
// Based on the Firestore document for a room, display its area using the "room-report-area-display" component.
// Pass through document-based information such as area as transcluded content.
// Retrieve the area information from the cached info on the rooms service.
@Component({
  selector: "room-report-area-document",
  template: `
<ng-container *ngIf="data$ | async as data">
  <room-report-area-display
    [data]="data">
  </room-report-area-display>
</ng-container>
`,
})
@LogModule(...MODULE)
export class DocumentComponent extends GenericDocumentComponent<Room> {}

////////////////////////////////////////////////////////////////
// ROOM AREA DISPLAY IN REPORT
@Component({
  selector: "room-report-area-display",
  templateUrl: "component.html",
})
@LogModule(...MODULE)
export class DisplayComponent {
  @Input("data") room: Room;

  public shape: Shape;
  public isSingleton: boolean;

  constructor(private service: Service) {}

  ngOnChanges() {
    this.isSingleton = this.service.getRoomType(this.room).singleton;

    const shape = this.room.shape;

    // TODO: do this deserializtion somewhere more transparent.
    // Right now, we hasve to do it in the room measure components as well.
    this.shape = shape && deserializeShape(shape);
  }
}

////////////////////////////////////////////////////////////////
// ROOM COLLECTION OF SUMMARIES
//
// Generate an entry for each element in the collection.
@Component({
  selector: "room-report-area-collection",
  templateUrl: "collection.html",
})
@LogModule(...MODULE)
export class CollectionComponent extends GenericCollectionComponent<Room> implements OnInit {
  constructor(afs: AngularFirestore) {
    super(afs);
  }
}

export const DECLARATIONS = [CollectionComponent, DisplayComponent, DocumentComponent];
