// blue/shares/service.ts
//
// Service for shared report (tokens).
// Retrieve a record for a particular shared report token,
// from which the project ID can be inferred.
//
// Also, create a shared link.

const MODULE = ["shares"];

import {Injectable} from "@angular/core";

import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import * as firebase from "firebase";
import {AngularFirestore, AngularFirestoreDocument as Document} from "angularfire2/firestore";

import {shareWithOptions} from "@nims/cordova";

import {PropertiesService} from "../properties/service";

import {Property} from "../properties/type";
import {dynamicLinkUrl, FIREBASE_HOSTING_DOMAIN} from "../firebase-dynamic-link";
import {Logger, LogModule} from "../utils";

import {SharedReport} from "./type";

@Injectable()
@LogModule(...MODULE)
export class Service {
  public collection = this.afs.collection<SharedReport>("shared-reports");
  private properties = this.propertiesService.properties();

  public logger: Logger;

  constructor(private afs: AngularFirestore, private propertiesService: PropertiesService) {}

  // Based on a project ID, create a shared report.
  public async create(propertyId: string): Promise<firebase.firestore.DocumentReference> {
    return this.collection.add({propertyId});
  }

  // Using the magic of firebase dynamic links,
  // get a link which will open, hopefully, either in the browser,
  // accessing the deployed `blue/console` app, or the app on the device, after installing it if necessary.
  public async dynamicLinkUrl(propertyId: string) {
    const sharedReport = await this.create(propertyId);

    // This is the URl which will open the report in a browser, for example.
    const appUrl = `${FIREBASE_HOSTING_DOMAIN}/shared-report/${sharedReport.id}`;

    // `Location#prepareExternalUrl` does useful things like adding the hash (or not).
    return dynamicLinkUrl(appUrl);
  }

  public getPropertyDoc(srId: string): Observable<Document<Property>> {
    // Get stream each emission on which is one shared report document.
    const srDoc = this.collection.doc<SharedReport>(srId);

    // Map that further to a stream of shared reports, by taking `valueChanges`.
    const sr$ = srDoc.valueChanges();

    // Map the stream of shared reports to a stream of their propertyIds.
    //    const propertyId$: Observable<string> = sr$.pipe(map(sr => sr.propertyId));
    const propertyId$ = sr$.pipe(map(sr => sr.propertyId));

    // Map propertyIds to property documents.
    const doc$ = propertyId$.pipe(map(id => this.properties.doc<Property>(id)));

    return doc$;
  }

  // This is invoked from the share screen.
  public async shareReport(property: Document<Property>) {
    if (!property) return;

    return this.share({
      subject: "My home inspection report",
      message: `Take a look at my amazingly informative Home Inspection report, that I generated using the great new dSnag! app.`,
      url: await this.dynamicLinkUrl(property.ref.id),
    });
  }

  public async shareApp() {
    return this.share({
      subject: "Great new home inspection app",
      /* tslint:disable-next-line */
      message: `dSnag! is a technology marvel that takes guesswork and "trust me" out and brings facts out discovering and documenting most common snags or issues in an apartment/house/villa.`,

      // TODO: put this in some list of constants somewhere.
      url: "https://play.google.com/store/apps/details?id=in.nemmadi.dsnag",
    });
  }

  private async share(options: SocialSharingPlugin.ShareOptions) {
    try {
      const result = await shareWithOptions(options);

      if (!result.completed) throw new Error("Sharing not completed");
    } catch (e) {
      console.error(...this.logger.log("sharing failed", e));
    }
  }
}
