// blue/app/shared/room-types-select.component.ts
//
// Dropdown for selecting room types.
// Emits the ID.
// Used when defining a new room, or editing an existing one.

import {Component, Input, OnInit, Output, EventEmitter} from "@angular/core";
import {Pair} from "@nims/jsutils";
import {Logger, LogModule} from "../../utils";
import {Service} from "../service";
import {RoomType} from "../type";

@Component({
  selector: "room-types-select",
  templateUrl: "./component.html",
})
@LogModule("room-types")
export class SelectComponent implements OnInit {
  private _id: string;

  // The id of the currently selected room type, if any.
  @Input()
  get id() {
    return this._id;
  }

  @Output() idChange = new EventEmitter<string>();

  public pairs: Pair<RoomType>[];

  public logger: Logger;

  set id(id) {
    this._id = id;
    this.idChange.emit(id);
  }

  constructor(private service: Service) {}

  ngOnInit() {
    this.pairs = this.service.filteredPairs();

    if (this.logger.enabled) console.log(...this.logger.log({id: this.id}));
  }
}
