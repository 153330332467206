// blue/products/sscreen.component.ts
//
// Screen for in-app products.

const MODULE = ["products"];

import {Component, OnInit, OnDestroy} from "@angular/core";

import {Observable, Subscription} from "rxjs";
import {map, switchMap} from "rxjs/operators";

import {Logger, LogModule} from "../utils";
import {Service as MyPropertyService} from "../my-property/service";
import {environment} from "../environments/environment";

import {Product, Service, ids} from "./service";

@Component({
  selector: "product-screen",
  templateUrl: "./screen.component.html",
  //  styleUrls: ["./screen.component.css"],
})
@LogModule(...MODULE)
export class ScreenComponent implements OnInit, OnDestroy {
  // Observables of upgrade-related properties on `Property`.
  public upgradeAll$: Observable<boolean>;
  public upgrades$: Observable<{[upgradeId: string]: boolean}>;

  // Flattened version of upgrades on property.
  public upgrades: {[upgradeId: string]: boolean};

  // Products in store.
  public products: Product[];

  // Raw IDs that we know about, used for applying upgrades when bypassing store.
  public productIds = ids;

  // Show also purchased products?
  public showPurchased = false;

  public features = environment.features;

  public logger: Logger;

  private subscription: Subscription;

  constructor(private myPropertyService: MyPropertyService, private service: Service) {}

  ngOnInit() {
    const myPropertyDocument$ = this.myPropertyService.document$;
    const myProperty$ = myPropertyDocument$.pipe(switchMap(property => property.valueChanges()));

    // get streams of the current property's upgradeAll and upgrades properties.
    this.upgradeAll$ = myProperty$.pipe(map(property => property.upgradeAll));
    this.upgrades$ = myProperty$.pipe(map(property => property.upgrades));

    this.subscription = this.upgrades$.subscribe(upgrades => (this.upgrades = upgrades || {}));

    this.products = this.service.products;
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  // Track-by routine to optimize display of products.
  public productId({productId}: Product) {
    return productId;
  }

  // Open up a browser to the e-commmerce site.
  public onOfflineProducts() {
    return this.service.offline();
  }

  public unbuyAll() {
    return this.service.unbuyAll();
  }

  public applyProduct(id: string) {
    this.service.applyProduct(id);
  }
}
