// blue/photos: TS logic for component for displaying photo, including highlight (and drawing thereof).

import {
  Component,
  Input,
  Output,
  ElementRef,
  EventEmitter,
  HostBinding,
  OnInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

import {makeDataUriFromBase64} from "@nims/jsutils";

import {Photo, PhotoHighlight} from "./type";

const RED = "rgb(231, 76, 60)";

@Component({
  selector: "photo-display",
  templateUrl: "component.html",
  styleUrls: ["component.css"],
})
export class PhotoComponent implements OnInit, OnChanges {
  @Input() public photo: Photo | null;
  @Input() editable = false;
  @Input() showOrientationArrow = true;

  // For several reasons, we need to set the size like this,
  // rather than by the size of the surrounding/calling element.
  @HostBinding("style.width")
  @Input()
  width = "320px";

  @HostBinding("style.height")
  @Input()
  height = "240px";

  @Output() public changeHighlight = new EventEmitter<PhotoHighlight>();

  @ViewChild("img") img: ElementRef;

  public isDrawingHighlight = false;
  public backgroundImage: string;

  // Remember the old highlight, so we can put it back if we need to.
  public previousHighlight: PhotoHighlight;

  public src: string;

  constructor() {}

  public ngOnInit() {
    if (!this.photo.highlight) this.photo.highlight = null;
  }

  public ngOnChanges(simpleChanges: SimpleChanges) {
    this.setBackgroundImage();

    this.src = makeDataUriFromBase64(this.photo.imageBase64);
  }

  public touchStart(event) {
    const touch: Touch = event.touches[0];
    const image = event.target as HTMLImageElement;
    const imgRect = image.getBoundingClientRect();

    this.previousHighlight = this.photo.highlight;

    this.photo.highlight = {
      left: (touch.clientX - imgRect.left) / imgRect.width,
      top: (touch.clientY - imgRect.top) / imgRect.height,
      width: 0,
      height: 0,
    };

    this.setBackgroundImage();
    this.isDrawingHighlight = true;
    event.preventDefault();
  }

  public touchMove(event: TouchEvent) {
    const highlight = this.photo.highlight;
    const touch: Touch = (event as any).changedTouches[0];
    const image = event.target as HTMLImageElement;
    const imgRect = image.getBoundingClientRect();

    highlight.width = (touch.clientX - imgRect.left) / imgRect.width - highlight.left;
    highlight.width = Math.max(0, highlight.width);
    highlight.width = Math.min(highlight.width, 1 - highlight.left);

    highlight.height = (touch.clientY - imgRect.top) / imgRect.height - highlight.top;
    highlight.height = Math.max(0, highlight.height);
    highlight.height = Math.min(highlight.height, 1 - highlight.top);

    this.setBackgroundImage();
    event.preventDefault();
  }

  public touchEnd() {
    this.isDrawingHighlight = false;
    if (!this.photo.highlight.width || !this.photo.highlight.height)
      this.photo.highlight = this.previousHighlight;
    event.preventDefault();
  }

  // HIGHLIGHTING!
  // User has pressed mouse--start highlighting mode.
  public mouseDown(event) {
    this.previousHighlight = this.photo.highlight;

    this.photo.highlight = {
      left: event.offsetX / event.target.offsetWidth,
      top: event.offsetY / event.target.offsetHeight,
      width: 0,
      height: 0,
    };

    this.setBackgroundImage();
    this.isDrawingHighlight = true;
    event.preventDefault();
  }

  // User has moved mouse--update bottom right of highlight.
  public mouseMove(event) {
    const highlight = this.photo.highlight;

    highlight.width = event.offsetX / event.target.offsetWidth - highlight.left;
    highlight.height = event.offsetY / event.target.offsetHeight - highlight.top;
    this.setBackgroundImage();
    event.preventDefault();
  }

  // User has lifted mouse--exit highlighting mode.
  public end(event) {
    this.isDrawingHighlight = false;
    if (!this.photo.highlight.width || !this.photo.highlight.height)
      this.photo.highlight = this.previousHighlight;
    else this.changeHighlight.emit(this.photo.highlight);

    event.preventDefault();
  }

  // Create a radial gradient representing the highlight.
  // It is transparent at the cnter, then there is a red edge,
  // then slightly opaque out to the borders.
  private makeBackgroundImage() {
    if (!this.photo) return;

    const {highlight} = this.photo;
    if (!highlight) return;

    const centerX = highlight.left + highlight.width / 2;
    const centerY = highlight.top + highlight.height / 2;

    // Radial gradient sizes are specified in terms of radius, not diameter.
    const sizeX = highlight.width / 2;
    const sizeY = highlight.height / 2;

    return `radial-gradient(ellipse ${sizeX * 100}% ${sizeY * 100}% at ${centerX * 100}% ${centerY *
      100}%, transparent 90%, ${RED} 92%, rgba(0, 0, 0, 0.2))`;
  }

  private setBackgroundImage() {
    this.backgroundImage = this.makeBackgroundImage();
  }
}

export const PHOTOS_DECLARATIONS = [PhotoComponent];
export {Photo, PhotoHighlight};
