// blue/properties/report: Everything about reporting on properties.
//
// Report-related elements for entities such as rooms etc are found in the respective directories.

import {AREA_DECLARATIONS} from "./area/component";
import {STATUS_DECLARATIONS} from "./status/component";
import {AspectPies} from "./aspect-pies/component";

import {
  REPORT_DECLARATIONS as MAIN_REPORT_DECLARATIONS,
  REPORT_ROUTES as MAIN_REPORT_ROUTES,
} from "./component";

// EXPORTS
export const REPORT_DECLARATIONS = [
  ...AREA_DECLARATIONS,
  ...MAIN_REPORT_DECLARATIONS,
  ...STATUS_DECLARATIONS,
  AspectPies,
];

export const REPORT_ROUTES = [...MAIN_REPORT_ROUTES];
