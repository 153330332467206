// blue/room-types: all about room types, the mechanism by which items are associated with rooms.
//
// This "module" offers two components, one to obtain counts of room types for new properties,
// and the other one to put up a select widget for the user to select a room type to add to the property.

import {Service} from "./service";
import {RoomType, isSingleton} from "./type";

import {SelectComponent} from "./select/component";
import {MenuComponent} from "./menu/component";

export const ROOM_TYPES_DECLARATIONS = [SelectComponent, MenuComponent];
export const ROOM_TYPES_PROVIDERS = [Service];

export {isSingleton, Service as RoomTypesService, RoomType};
