// blue/items: logic and components related to checklist items.
//
// Inspect them. Report on them.

import {INSPECT_DECLARATIONS} from "./inspect/component";
import {REPORT_DECLARATIONS} from "./report/component";
import {Service} from "./service";

export const ITEMS_DECLARATIONS = [...INSPECT_DECLARATIONS, ...REPORT_DECLARATIONS];
export const ITEMS_PROVIDERS = [Service];

export {
  Item,
  itemRoomTypeQuery,
  itemRoomTypeAndNotPremiumQuery,
  parseItemName,
  itemRoomTypeChecker,
  isItemPremium,
  sortItems,
} from "./type";

export {Service as ItemsService} from "./service";
