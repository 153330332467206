// blue/components/offline-upsell/component.ts
//
// TS logic for component to display pitch for buying offline inspection services,
// with link to e-commerce site.

import {Component} from "@angular/core";
import {Service as ProductsService} from "../../products/service";

@Component({
  selector: "offline-upsell",
  templateUrl: "component.html",
  styleUrls: ["component.css"],
})
export class OfflineUpsell {
  // TODO: consider if we want to persist the hidden status of the offline upsell box.
  public hide = false;

  constructor(public productsService: ProductsService) {}
}
