// blue/properties/type.ts
//
// Type definitions and basic utilities for properties.

import {
  AngularFirestore,
  AngularFirestoreDocument as Document,
  QueryFn,
} from "angularfire2/firestore";

import {I} from "@nims/jsutils";
import {deleteCollection} from "@nims/afutils";

import {Room, getRooms, getRoomSnags} from "../rooms/type";

export interface Property {
  name: string;
  address?: string;

  // This is a Firebase authentication `uid`.
  owner: string;

  // This is the owner's name or email AS OF THE TIME the property was created.
  // They are NOT updated when the user updates his info in whatever fashion.
  // This is used when displaying a shared report.
  ownerName: string;
  ownerEmail?: string;

  // We do not access this property to know if a specific room should be able to access premium items.
  // It's for remembering that full access was purchased, so we can avoid showing upsell messages.
  upgrades?: {[upgradeId: string]: boolean};
  upgradeAll: boolean;

  inspectedOn?: number;
}

export function clean(property: Property) {
  return (
    property && {
      ...property,
      name: property.name && property.name.trim(),
      address: property.address && property.address.trim(),
    }
  );
}

// Get rooms in a property.
export function getPropertyRooms(
  property: Document<Property>,
  afs: AngularFirestore,
  queryFn: QueryFn = I
) {
  return getRooms(property, queryFn);
}

// Delete a property completely.
export async function deleteProperty(property: Document<Property>, afs: AngularFirestore) {
  const roomsCollection = getPropertyRooms(property, afs);

  // Loop over all rooms, deleting snags in each room.
  for (const roomSnapshot of (await roomsCollection.ref.get()).docs) {
    const room = new Document<Room>(roomSnapshot.ref, afs);
    const snagsCollection = getRoomSnags(room);

    await deleteCollection(snagsCollection.ref);
  }

  // Delete the room collection.
  await deleteCollection(getPropertyRooms(property, afs).ref);

  // Delete the property itself.
  return property.delete();
}
