// animations/fly-in-out.animation.ts
//
// Angular animatino for flying things in and out, duh.

import {transition, style, animate} from "@angular/animations";

export const flyInOut = [
  //  state("in", style({transform: "translateX(0)"})),
  //  transition("void => *", [style({transform: "translateX(-100%)"}), animate(100)]),
  transition("* => void", [animate(250, style({transform: "translateX(100%)"}))]),
];
