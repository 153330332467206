// This is the basic shape of status information return.

import {count, length, objectReduce} from "@nims/jsutils";
import {ItemWithSnag} from "../items/type";

export interface Status {
  numItems: number;
  numSnags: number;
  numNotSnags: number;
}

function add(a: number, b: number) {
  return a + b;
}

// Combine statuses (such as for rooms) into a single status (such as for property).
export function combineStatuses(statuses: Status[]) {
  return objectReduce(statuses, add, 0);
}

// Calculate status from a hash of items with snags.
export function calcStatus(itemsWithSnags: Array<ItemWithSnag>): Status {
  const snags = itemsWithSnags.map(({snag}) => snag);

  return {
    numItems: length(itemsWithSnags),
    numSnags: count(snags, snag => !!snag && snag.snag),
    numNotSnags: count(snags, snag => !!snag && !snag.snag),
  };
}
