// blue/products/card.component.ts
//
// Display a single product in the form of a card.
// Allow the user to buy it.
//
// TODO: Shouldn't actions like "buy" be outputs, handled at the higher level?

import {Component, Input, OnInit} from "@angular/core";
import {OnChanges, SimpleChanges} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {trigger} from "@angular/animations";

import {removeString} from "@nims/jsutils";

import {appear} from "../animations/appear.animation";
import {Logger, LogModule} from "../utils";
import {environment} from "../environments/environment";

import {Product, Service} from "./service";

const removeDSnag = removeString("(dSnag!)");

@Component({
  selector: "product-card-display",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.css"],
  animations: [trigger("appear", appear)],
})
@LogModule("purchasing")
export class CardDisplayComponent implements OnInit, OnChanges {
  @Input() public readonly product: Product;
  @Input() purchased: boolean;

  public cleanedTitle: string;

  public features = environment.features;

  public logger: Logger;

  constructor(private matSnackBar: MatSnackBar, private service: Service) {}

  ngOnInit() {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    // For some reason, the store sticks the name of the app onto the end of the product title.
    this.cleanedTitle = removeDSnag(this.product.title);
  }

  // Buy (order) the product.
  public async buy() {
    try {
      await this.service.buy(this.product.productId);
      this.snackBar("Purchase completed and applied to your property.");
    } catch (e) {
      this.snackBar("Sorry, could not complete the purchase.");
    }
  }

  // Unbuy (cancel) the product. TESTING ONLY.
  public async unbuy() {
    try {
      await this.service.unbuy(this.product.productId);
      this.snackBar("Reversal completed and applied to your property.");
    } catch (e) {
      // Why is this appearing?
      //       this.snackBar("Sorry, could not unbuy the product");
    }
  }

  private snackBar(msg) {
    this.matSnackBar.open("Sorry, could not unbuy the product", "", {duration: 2000});
  }
}
