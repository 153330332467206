// blue/rooms/type.ts
//
// Define "room" type, for specific rooms in a property.
// Room is associated with a room type, which is an ID indexing into the room-types collection.
// Rooms may also either be "upgraded", giving them access to premium items.

import {AngularFirestoreDocument as Document, QueryFn} from "angularfire2/firestore";
import * as firebase from "firebase";
import {I} from "@nims/jsutils";
import {getSnags} from "../snags/type";

type CollectionReference = firebase.firestore.CollectionReference;

export interface Room {
  name: string;

  roomTypeId: string;

  // Premium items are otherwise available for this room.
  // Perhaps that's because the property was created in the premium app,
  // or because the full package was purchased,
  // or because the package for this room was purchased.
  upgraded: boolean;

  // Epoch time when the first item of this room was inspected
  inspectedOn?: number;

  // A room has "space" information, from which we derive its size.
  // This is conceptually of type `Shape`, which currently is a polygon.
  // However, Firestore does not support nested arrays.
  // Therefore, we store it as a flattened array.
  // We need to hydrate it (deserialize) it on the way in,
  // and flatten it (serialize it) on the way out.
  shape?: number[];
}

// A AngularFirestore query to retrieve rooms with a particular roomtype as a collection.
export function roomTypeIdQuery(roomTypeId: string): QueryFn {
  return (ref: CollectionReference) => ref.where("roomTypeId", "==", roomTypeId);
}

// A AngularFirestore query to retrieve rooms with a particular upgrade setting.
export function roomUpgradedQuery(upgraded: boolean): QueryFn {
  return (ref: CollectionReference) => ref.where("upgraded", "==", upgraded);
}

// Sort rooms by name.
export function sortRooms({name: name1}: Room, {name: name2}: Room) {
  return name1.localeCompare(name2);
}

// QueryFn for ordering by name.
export const queryRoomByName = (ref: CollectionReference) => ref.orderBy("name");

// Get a collection of rooms from a document, almost always a property, sorted by room name.
export function getRooms<T>(document: Document<T>, queryFn: QueryFn = I) {
  return document.collection<Room>("rooms", ref => queryFn(ref).orderBy("name"));
}

// Get snags in a room.
export function getRoomSnags(room: Document<Room>, queryFn: QueryFn = I) {
  return getSnags(room, queryFn);
}
