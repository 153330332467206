// blue/console/index.ts

// ANGULAR
import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RouterModule, Routes} from "@angular/router";
import {FormsModule} from "@angular/forms";

import {RavenModule} from "@nims/ngutils";
import {ChartModule} from "primeng/components/chart/chart";

import {environment} from "./environments/environment";
import {Module as AngularFireModule} from "./angular-fire.module";
import {AppComponent} from "./app/component";
import {MaterialModule} from "./material.module";

import {
  FormatPipesModule,
  NgutilsDirectivesModule,
  OrientationArrowModule,
  SafePipesModule,
} from "@nims/ngutils";

const enableTracing = true || (environment.debug && environment.debug.enableRouterTracing);

// If you are going to change this, then you will need to change the path
// in universal URLs, defined in `blue/app/shares/service.ts`.
const useHash = false;

import {COMPONENTS_DECLARATIONS, COMPONENTS_PROVIDERS} from "../../src/components";
import {HELP_DECLARATIONS} from "../../src/help";
import {ITEMS_DECLARATIONS, ITEMS_PROVIDERS} from "../../src/items";
import {MY_PROPERTY_DECLARATIONS, MY_PROPERTY_PROVIDERS} from "../../src/my-property";
import {PHOTOS_DECLARATIONS} from "../../src/photos";
import {PRODUCTS_DECLARATIONS, PRODUCTS_PROVIDERS} from "../../src/products";
import {PROPERTIES_DECLARATIONS, PROPERTIES_PROVIDERS} from "../../src/properties";
import {ROOMS_DECLARATIONS, ROOMS_PROVIDERS} from "../../src/rooms";
import {ROOM_TYPES_DECLARATIONS, ROOM_TYPES_PROVIDERS} from "../../src/room-types";
import {SHARES_DECLARATIONS, SHARES_PROVIDERS} from "../../src/shares";
import {SNAGS_DECLARATIONS} from "../../src/snags";
import {SHAPES_DECLARATIONS, SHAPES_PROVIDERS} from "../../src/shapes";
import {STATUS_DECLARATIONS} from "../../src/status";
import {USERS_DECLARATIONS, USERS_PROVIDERS} from "../../src/users";
import {PROVIDERS as UTILITY_PROVIDERS} from "../../src/providers";
import {WelcomeComponent} from "./welcome/component";
import {ROUTES as SHARED_REPORT_BROWSER_ROUTES} from "../../src/shares/shared-report-browser/component";

const routes: Routes = [
  {path: "", redirectTo: "/welcome", pathMatch: "full"},
  {path: "welcome", component: WelcomeComponent},
  ...SHARED_REPORT_BROWSER_ROUTES,
];

const providers = [
  ...COMPONENTS_PROVIDERS,
  ...ITEMS_PROVIDERS,
  ...MY_PROPERTY_PROVIDERS,
  ...PRODUCTS_PROVIDERS,
  ...ROOM_TYPES_PROVIDERS,
  ...ROOMS_PROVIDERS,
  ...PROPERTIES_PROVIDERS,
  ...SHAPES_PROVIDERS,
  ...SHARES_PROVIDERS,
  ...USERS_PROVIDERS,
  ...UTILITY_PROVIDERS,
];

const declarations = [
  AppComponent,
  WelcomeComponent,
  ...COMPONENTS_DECLARATIONS,
  ...HELP_DECLARATIONS,
  ...ITEMS_DECLARATIONS,
  ...MY_PROPERTY_DECLARATIONS,
  ...PHOTOS_DECLARATIONS,
  ...PRODUCTS_DECLARATIONS,
  ...PROPERTIES_DECLARATIONS,
  ...ROOMS_DECLARATIONS,
  ...ROOM_TYPES_DECLARATIONS,
  ...SHAPES_DECLARATIONS,
  ...SHARES_DECLARATIONS,
  ...SNAGS_DECLARATIONS,
  ...STATUS_DECLARATIONS,
  ...USERS_DECLARATIONS,
];

const imports = [
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,

  ChartModule,
  RavenModule,

  MaterialModule,
  FormatPipesModule,
  NgutilsDirectivesModule,
  OrientationArrowModule,
  SafePipesModule,

  AngularFireModule,
  RouterModule.forRoot(routes, {enableTracing, useHash}),
];

@NgModule({
  declarations,
  imports,
  exports: [RouterModule],
  providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
