// blue/properties/report/aspect-pies/component.ts
//
// Little status pies by aspect.
// We are doing this as statically as possibly for performance reasons.

const MODULE = ["properties", "report"];

import {Component} from "@angular/core";
import {OnInit} from "@angular/core";
import {Input} from "@angular/core";

import {Observable} from "rxjs";

import {AngularFirestoreDocument as Document} from "angularfire2/firestore";

import {ItemWithSnag, aspectFilterer} from "../../../items/type";
import {Service as ItemsService} from "../../../items/service";

import {PropertiesService} from "../../../properties/service";
import {Status} from "../../../status";
import {Logger, LogModule} from "../../../utils";

import {Property} from "../../type";

@Component({
  selector: "property-report-aspect-pies",
  templateUrl: "component.html",
  styleUrls: ["component.css"],
})
@LogModule(...MODULE)
export class AspectPies implements OnInit {
  @Input("doc") property: Document<Property>;

  public statuses$: Observable<Status>[];
  public aspects: string[];

  public logger: Logger;

  constructor(private itemsService: ItemsService, private propertiesService: PropertiesService) {}

  ngOnInit() {
    this.calc();
  }

  // Calculate an array of observables of status, one per aspect.
  private calc() {
    this.aspects = this.itemsService.aspects;

    // For each asepct, compute a status.
    this.statuses$ = this.aspects.map(aspect => {
      const filterer = aspectFilterer(aspect);

      return this.propertiesService.calcFilteredStatus$(this.property, ({item}: ItemWithSnag) =>
        filterer(item)
      );
    });
  }
}
