// Entry point for blue/app/properties.
//
// EVerything to do with properties.
// Define them. Inspect them. Measure them. Report on them.

import {Routes} from "@angular/router";

export {Property, clean, getPropertyRooms} from "./type";

import {EDIT_DECLARATIONS, EDIT_ROUTES} from "./edit/component";
import {INSPECT_DECLARATIONS, INSPECT_ROUTES} from "./inspect/component";
import {MEASURE_DECLARATIONS, MEASURE_ROUTES} from "./measure/component";
import {NEW_DECLARATIONS, NEW_ROUTES} from "./new/component";
import {REPORT_DECLARATIONS, REPORT_ROUTES} from "./report";
import {STATUS_DECLARATIONS} from "./status/component";

export const PROPERTIES_DECLARATIONS = [
  ...EDIT_DECLARATIONS,
  ...INSPECT_DECLARATIONS,
  ...MEASURE_DECLARATIONS,
  ...NEW_DECLARATIONS,
  ...REPORT_DECLARATIONS,
  ...STATUS_DECLARATIONS,
];

export {ownerQuery} from "./service";
import {PropertiesService} from "./service";

export const PROPERTIES_PROVIDERS = [PropertiesService];

export const PROPERTIES_ROUTES: Routes = [
  {
    path: "property",
    children: [
      ...EDIT_ROUTES,
      ...INSPECT_ROUTES,
      ...MEASURE_ROUTES,
      ...NEW_ROUTES,
      ...REPORT_ROUTES,
    ],
  },
];
