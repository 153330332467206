// blue/users/sign-in.component.ts
//
// JS logic for sign-in component.
// Controller by the `/users/sign-in` route.
// Pass a redirect URL as query paramter if you so desire.

const MODULE = ["users"];

import {Component} from "@angular/core";
import {ActivatedRoute, Routes} from "@angular/router";
import {Logger, LogModule} from "../../utils";
import {Service} from "../service";
import {environment} from "../../environments/environment";
import {openUrlInInAppBrowser} from "../../utils/in-app-browser";
import {TOS_URL} from "../../environments/constants";

@Component({
  selector: "user-sign-in",
  templateUrl: "component.html",
  styleUrls: ["component.css"],
})
@LogModule(...MODULE)
export class SignIn {
  public testSignIn = environment.features.testSignIn;

  private redirectUrl: string;

  private logger: Logger;

  constructor(private readonly activatedRoute: ActivatedRoute, private readonly service: Service) {}

  public ngOnInit() {
    const redirectUrl = this.activatedRoute.snapshot.queryParams["redirectUrl"];
    const defaultRedirectUrl = "/#/my-property/inspect";

    this.redirectUrl = defaultRedirectUrl || redirectUrl;
  }

  public async ngAfterViewInit() {
    try {
      this.service.firebaseUi("#firebase-ui", this.redirectUrl);
    } catch (e) {
      console.error(...this.logger.log("failed to initialize firebaseUI", e));
    }
  }

  public signInAsDsnag() {
    this.service.signInWithEmailAndPassword("rtm@dsnag.com", "password");
  }

  public openTos() {
    openUrlInInAppBrowser(TOS_URL);
  }
}

export const SIGN_IN_DECLARATIONS = [SignIn];
export const SIGN_IN_ROUTES: Routes = [
  {path: "sign-in", component: SignIn, data: {screenName: "sign-in"}},
];
