// guards/signed-in.guard.ts
//
// Check to make sure that user is logged in.
// If not, sned her to log in screen.
// This is mainly important to prevent the user going "back" to the project list after signing out.
// But how could that happen anyway?

import {Injectable} from "@angular/core";
import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot} from "@angular/router";
import {map} from "rxjs/operators";
import {Service} from "./service";

@Injectable()
export class SignedInGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly service: Service) {}

  // Should this be something derived from UserService instead?
  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const redirectUrl = `#${state.url}`;

    return this.service.uid$.pipe(
      map(uid => {
        if (uid) return true;

        // Must match `usehash` setting in blue/app/src/app/index.ts.
        this.router.navigate(["/user", "sign-in"], {queryParams: {redirectUrl}});
        return false;
      })
    );
  }
}
