// blue/proeprties/service.ts
//
// Services related to properties.
// Create a new property.
// Get property status and/or area (from cache, possibly).
// Add rooms to a property.
// Upgrade a property.

import {Injectable} from "@angular/core";

import {
  AngularFirestore,
  AngularFirestoreCollection as Collection,
  AngularFirestoreDocument as Document,
  QueryFn,
} from "angularfire2/firestore";
import * as firebase from "firebase";

import {Observable, combineLatest} from "rxjs";
import {map, switchMap} from "rxjs/operators";

import {afsCollectionToDocumentReferences} from "@nims/afutils";
import {Hash, TRUE} from "@nims/jsutils";

import {Service as UserService} from "../users/service";
import {Service as RoomsService} from "../rooms/service";
import {Service as RoomTypesService} from "../room-types/service";

import {Room} from "../rooms/type";
import {ItemWithSnag} from "../items/type";

import {Logger, LogModule} from "../utils";
import {Status, calcStatus, combineStatuses} from "../status";

import {Property, clean, getPropertyRooms} from "./type";

export function ownerQuery(owner): QueryFn {
  return (ref: firebase.firestore.CollectionReference) => ref.where("owner", "==", owner);
}

// In the future, we may have some more complicated notion of "area",
// and some variation in how we think of "combining" areas.
// For now, we just add them up.
// The equivalent "combiner" for statuses is over in `statuses/type`.
function combineAreas(areas: number[]) {
  return areas.reduce((a, b) => a + (b || 0), 0);
}

@Injectable()
@LogModule("properties")
export class PropertiesService {
  public logger: Logger;

  // Maintain a cache of property status and area, to avoid recalculating it each and every time.
  public statuses$: Hash<Observable<Status>> = {};
  public areas$: Hash<Observable<number>> = {};

  constructor(
    private afs: AngularFirestore,
    private roomsService: RoomsService,
    private roomTypesService: RoomTypesService,
    private userService: UserService
  ) {}

  public properties(queryFn?: QueryFn): Collection<Property> {
    return this.afs.collection<Property>("properties", queryFn);
  }

  public async checkAndUpdateInspectedOn(propertyId: string, inspectedOn: number) {
    const property = this.properties().doc(propertyId);
    const propertySnapshot = await property.ref.get();
    const propertyData = propertySnapshot.data() as Property;
    if (!propertyData.inspectedOn) {
      property.update({inspectedOn: inspectedOn});
    }
  }

  // Create a new property.
  // Associate it with the current user.
  // Remember whether it was created using the premium version.
  public async create(name: string, address: string, roomCounts: {[id: string]: number}) {
    const {
      uid: owner,
      name: ownerName = "unknown",
      email: ownerEmail = "unknown",
    } = this.userService;

    const propertyReference = await this.properties().add(
      clean({owner, ownerName, ownerEmail, name, address, upgradeAll: false})
    );

    await this.addRooms(new Document<Property>(propertyReference, this.afs), roomCounts);

    return propertyReference;
  }

  // TODO: try using batched adding of rooms, using routines in afutils.
  private async addRooms(property: Document<Property>, roomCounts: {[id: string]: number}) {
    await this.roomsService.addToProperty(property, roomCounts);
  }

  // Add a new room to a property, giving it a reasonable name.
  // We will count the other rooms in the property with this room type.
  // TODO: clean up adding one room vs. adding multiple rooms (in `RoomsService`).
  public async addRoom(property: Document<Property>, roomTypeId: string) {
    let n = 0;

    // Get the room type object, so we can get its name.
    const roomType = this.roomTypesService.roomTypesO[roomTypeId];
    const upgrade = roomType.upgrade;

    // The property object has a rooms collection we are adding to.
    const roomsCollection = getPropertyRooms(property, this.afs);

    // Get a snapshot of all rooms in that collection.
    const roomsSnapshot = await roomsCollection.ref.get();

    // Loop over all the rooms, counting the ones of the same roomtype.
    roomsSnapshot.forEach(doc => {
      if ((doc.data() as Room).roomTypeId === roomTypeId) n++;
    });

    const propertySnapshot = await property.ref.get();
    const propertyData = propertySnapshot.data() as Property;

    // Is this room eligible for ugprade?
    // Yes, if it is an upgradable room type, and that upgrade has been applied to the property,
    // OR if the full upgrade has been applied.
    const upgraded =
      (upgrade && propertyData.upgrades && propertyData.upgrades[upgrade]) ||
      propertyData.upgradeAll;

    // Get property object itself, so we can find what upgrades have already been applied.

    // Add the new room.
    return roomsCollection.add({
      roomTypeId,
      name: `${roomType && roomType.name} ${n + 1}`,
      upgraded,
    });
  }

  ////////////////////////////////////////////////////////////////
  // STATUS

  // Retrieve the cached status for the property, or calculate it is necessary.
  // TODO: Fix the `false` part below.
  public getStatus$(property: Document<Property>) {
    return (this.statuses$[property.ref.id] =
      (false && this.statuses$[property.ref.id]) || this.calcStatus$(property));
  }

  // Get the overall property status summary as an Observable, using the property's availability information,
  // by combining individual room statuses.
  public calcStatus$(property: Document<Property>) {
    return this.calcRoomStatuses$(property).pipe(map(combineStatuses));
  }

  public calcFilteredStatus$(
    property: Document<Property>,
    filterer: (itemWithSnag: ItemWithSnag) => boolean
  ) {
    return this.calcFilteredRoomStatuses$(property, filterer).pipe(map(combineStatuses));
  }

  // Get an observable for an array of room statuses.
  // Get all the room statuses, and combine them.
  public calcRoomStatuses$(property: Document<Property>): Observable<Status[]> {
    return this.calcFilteredRoomStatuses$(property, TRUE);
  }

  // Get an observable for an array of room statuses after filtering items, such as by aspect.
  // Get all the room statuses, and combine them.
  public calcFilteredRoomStatuses$(
    property: Document<Property>,
    filterer: (itemWithSnag: ItemWithSnag) => boolean
  ): Observable<Status[]> {
    const collection = getPropertyRooms(property, this.afs);

    // Get an observable, each emission on which is an array of room documents.
    const docs$: Observable<Document<Room>[]> = afsCollectionToDocumentReferences(collection).pipe(
      map(refs => refs.map(ref => new Document<Room>(ref, this.afs)))
    );

    // Create an observable of arrays of observables of room statuses.
    const statuses$ = docs$.pipe(
      map(docs =>
        docs.map(doc =>
          this.roomsService.getItemsWithSnags$(doc).pipe(
            map(itemsWithSnags => itemsWithSnags.filter(filterer)),
            map(itemsWithSnags => calcStatus(itemsWithSnags))
          )
        )
      )
    );

    return statuses$.pipe(switchMap(statuses => combineLatest(statuses)));
  }

  // Retrieve a room based on property ID and room ID.
  public getRoom(propertyId: string, roomId: string) {
    return getPropertyRooms(this.properties().doc(propertyId), this.afs).doc<Room>(roomId);
  }

  ////////////////////////////////////////////////////////////////
  // AREAS

  // Retrieve the cached area for the property, or calculate it is necessary.
  // At the moment, the caching is turned off due to some hot/cold observable issues.
  public getArea$(property: Document<Property>) {
    const id = property.ref.id;

    return (this.areas$[id] = (false && this.areas$[id]) || this.calcArea$(property));
  }

  // Get the overall property area information as an Observable by combining
  // observables of individual individual room areas.
  public calcArea$(property: Document<Property>) {
    return this.calcRoomAreas$(property).pipe(map(combineAreas));
  }

  // Get an observable for an array of room areas.;
  // Get all the room areas, then combine them.
  public calcRoomAreas$(property: Document<Property>): Observable<number[]> {
    const collection = getPropertyRooms(property, this.afs);

    // Get an observable, each emission on which is an array of room documents.
    const docs$: Observable<Document<Room>[]> = afsCollectionToDocumentReferences(collection).pipe(
      map(refs => refs.map(ref => new Document<Room>(ref, this.afs)))
    );

    // Create an observable of arrays of observables of room statuses.
    const areas$ = docs$.pipe(map(docs => docs.map(doc => this.roomsService.getArea$(doc))));

    return areas$.pipe(switchMap(areas => combineLatest(areas)));
  }

  ////////////////////////////////////////////////////////////////
  // UPGRADES
  //
  // WHen an upgrade occurs, we need to both update the `upgraded` flag in all affected rooms,
  // and also remember the upgrade on the `property` object, so it "sticks" with the project,
  // when it is accessed from a different device, or the browser.

  // Execute an upgrade.
  // We need to both update all the rooms affected, as well as remember the upgrade on the property itself.
  public async upgrade(property: Document<Property>, upgrade: string) {
    await this.upgradeRooms(property, upgrade);
    await this.markUpgrade(property, upgrade);
  }
  // Execute a downgrade.
  // We need to both update all the rooms affected, as well as remember the downgrade on the property itself.
  public async downgrade(property: Document<Property>, upgrade: string) {
    await this.downgradeRooms(property, upgrade);
    await this.markDowngrade(property, upgrade);
  }

  // Execute a full upgrade.
  // We need to both update all the rooms affected, as well as remember the upgrade on the property itself.
  public async upgradeAll(property: Document<Property>) {
    await this.upgradeAllRooms(property);
    await this.markUpgradeAll(property);
  }

  // Execute a full upgrade.
  // We need to both update all the rooms affected, as well as remember the upgrade on the property itself.
  public async downgradeAll(property: Document<Property>) {
    await this.downgradeAllRooms(property);
    await this.markDowngradeAll(property);
  }

  // Update all the rooms with a room type as having a particular upgrade.
  private async upgradeRooms(property: Document<Property>, upgrade: string) {
    return this.roomsService.upgradeRooms(getPropertyRooms(property, this.afs), upgrade);
  }

  // Update all the rooms with a room type as NOT having a particular upgrade.
  private async downgradeRooms(property: Document<Property>, upgrade: string) {
    return this.roomsService.downgradeRooms(getPropertyRooms(property, this.afs), upgrade);
  }

  // Upgrade all rooms.
  private upgradeAllRooms(property: Document<Property>) {
    return this.roomsService.upgradeAllRooms(getPropertyRooms(property, this.afs));
  }

  // Downgrade all rooms.
  private downgradeAllRooms(property: Document<Property>) {
    return this.roomsService.downgradeAllRooms(getPropertyRooms(property, this.afs));
  }

  // Remember that an upgrade has occurred in the property object.
  private markUpgrade(property: Document<Property>, upgrade: string) {
    return property.update({[`upgrades.${upgrade}`]: true});
  }

  // Remember that an upgrade has occurred in the property object.
  private markDowngrade(property: Document<Property>, upgrade: string) {
    return property.update({[`upgrades.${upgrade}`]: false});
  }

  // Remember that an upgrade to full checks has occurred in the property object.
  private markUpgradeAll(property: Document<Property>) {
    return property.update({upgradeAll: true});
  }

  // Remember that an downgrade from full checks has occurred in the property object.
  private markDowngradeAll(property: Document<Property>) {
    return property.update({upgradeAll: false});
  }
}
