// blue/rooms/inspect-screen/component.ts
//
// Module for room-specific inspection screen, displaying a list of items with snags if any.

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

import {Location} from "@angular/common";
import {ActivatedRoute as Route, Router, Routes} from "@angular/router";

import {Observable, Subscription} from "rxjs";
import {delay, map} from "rxjs/operators";

import {
  AngularFirestoreCollection as Collection,
  AngularFirestoreDocument as Document,
} from "angularfire2/firestore";

import {Room} from "../../rooms/type";
import {Snag} from "../../snags/type";
import {Item, ItemWithSnag} from "../../items/type";

import {PropertiesService} from "../../properties/service";

import {GenericDocumentComponent} from "../../utils";
import {Logger, LogModule} from "../../utils";
import {HasPropertyGuard} from "../../my-property/has-property.guard";
import {SignedInGuard} from "../../users";

import {Service} from "../service";

const MODULE = ["rooms", "inspect-screen"];

////////////////////////////////////////////////////////////////
// ROUTE COMPONENT FOR INSPECTING ROOM (inspect-room)
//
// Get property and room ID from path, find room, and pass to room document component for further handling.
@Component({
  selector: "room-inspect-screen-route",
  template: `
<ng-container *ngIf="doc$ | async as doc">
  <room-inspect-screen-document
    [doc]="doc"
    [propertyId]="propertyId"
    (gotoProperty)="onGotoProperty()">
  </room-inspect-screen-document>
</ng-container>
`,
})
@LogModule(...MODULE)
export class RouteComponent implements OnInit {
  public doc$: Observable<Document<Room>>;
  public logger: Logger;
  public propertyId: string;

  constructor(
    private propertiesService: PropertiesService,
    private route: Route,
    private router: Router
  ) {}

  ngOnInit() {
    this.propertyId = this.route.snapshot.params["propertyId"];

    if (this.logger.enabled) console.log(...this.logger.log("in ngOnInit"));

    this.doc$ = this.route.params.pipe(
      map(({propertyId, roomId}) => this.propertiesService.getRoom(propertyId, roomId))
    );

    this.logger.log$(this.doc$);
  }

  // Go back to the "inspect property" page (the list of rooms, mainly).
  // This bubbles up here from the room display component back button.
  public onGotoProperty() {
    this.router.navigate(["property", "inspect", this.route.snapshot.params["propertyId"]]);
  }


}

////////////////////////////////////////////////////////////////
// HANDLE DOCUMENT OF ROOM FOR PURPOSES OF SHOWING ROOM SCREEN
//
// Retrieve snags from this room, and pass along to the component for actually displaying the room.
// Provide means for lower-level components to return to property page.
@Component({
  selector: "room-inspect-screen-document",
  template: `
<ng-container *ngIf="data$ | async as data">
  <room-inspect-screen-display
    [data]="data"
    [itemsWithSnags$]="itemsWithSnags$"
    [snagsCollection]="snagsCollection"
    (gotoProperty)="onGotoProperty()"
    (startInspect)="onStartInspect($event)"
  >
    <div style="height: 24px; ">
      <room-status-bar
        [room]="doc"
        [showNumerals]="true"
        [showDescription]="true"
        [dynamicDescription]="true">
      </room-status-bar>
    </div>

    <div *ngIf="allDone" margin top bottom thick flex vertical align-center>
      <div large text>Great! You've finished inspecting here.</div>
      <!-- <div *ngIf="!itemsWithSnags.length"><img src="assets/images/undraw_checklist_7q37.svg" style="width: 60vw; "/></div> -->
      <button larger text mat-button (click)="onGotoProperty()" color="primary">Return to list of rooms</button>
    </div>

  </room-inspect-screen-display>
</ng-container>
`,
})
@LogModule(...MODULE)
export class DocumentComponent extends GenericDocumentComponent<Room> implements OnChanges {
  @Input() propertyId;
  @Output() gotoProperty = new EventEmitter<void>();

  public itemsWithSnags$: Observable<ItemWithSnag[]>;
  public snagsCollection: Collection<Snag>;
  public allDone: boolean;

  private subscription: Subscription;

  constructor(private propertiesService: PropertiesService, private service: Service) {
    super();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.itemsWithSnags$ = this.service.getItemsWithSnags$(this.doc);
    this.snagsCollection = this.doc.collection<Snag>("snags");
    this.subscription = this.itemsWithSnags$
      .pipe(delay(750))
      .subscribe(itemsWithSnags => (this.allDone = itemsWithSnags.every(({snag}) => !!snag)));
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  public onGotoProperty() {
    this.gotoProperty.emit();
  }

  public onStartInspect(inspectedOn: number) {
    this.service.checkAndUpdateInspectedOn(this.doc, inspectedOn);
    this.propertiesService.checkAndUpdateInspectedOn(this.propertyId, inspectedOn);
  }

}

////////////////////////////////////////////////////////////////
// DISPLAY A ROOM SCREEN
//
// Show the actual room information, with items as cards.
// The status information is transcluded from the room document component above.
// Offer an option to show all or just uninspected items
@Component({
  selector: "room-inspect-screen-display",
  templateUrl: "./component.html",
})
@LogModule(...MODULE)
export class DisplayComponent implements OnChanges {
  @Input("data") public room: Room;
  @Input() itemsWithSnags$: Array<ItemWithSnag>;
  @Input() snagsCollection: Collection<Snag>;

  @Output() gotoProperty = new EventEmitter<void>();
  @Output() startInspect = new EventEmitter<number>();

  public items: Collection<Item>;
  public showAllItems = false;

  public logger: Logger;

  constructor(public location: Location, private service: Service) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.items = this.service.getItemsCollection(this.room);
  }

  public onGotoProperty() {
    this.gotoProperty.emit();
  }

  public onStartInspect(inspectedOn: number) {
    this.startInspect.emit(inspectedOn);
  }
}

////////////////////////////////////////////////////////////////
// ROOM ROUTES
export const SCREEN_ROUTES: Routes = [
  {
    // This path should probably have an `inspect` segment in it.
    path: "rooms/:propertyId/:roomId",
    component: RouteComponent,
    canActivate: [SignedInGuard, HasPropertyGuard],
    data: {screenName: "room-inspect"},
  },
];

export const SCREEN_DECLARATIONS = [DocumentComponent, DisplayComponent, RouteComponent];
