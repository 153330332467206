// components/run-up/components.ts
//
// Display a number running up from zero, or from some number to another one.

import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {interval, Observable} from "rxjs";
import {map, take} from "rxjs/operators";

@Component({
  selector: "run-up",
  template: `{{value$ | async}}`,
  styles: [":host { display: inline; }"],
})
export class RunUpComponent implements OnChanges {
  @Input() value: number;
  @Input() ms = 50;

  public value$: Observable<number>;

  ngOnChanges(simpleChanges: SimpleChanges) {
    const {
      currentValue: current,
      previousValue: previous = 0,
    }: {currentValue: number; previousValue: number} = simpleChanges["value"];

    return current > previous
      ? interval(this.ms)
          .pipe(take(current - previous))
          .pipe(map(n => n + previous))
      : interval(this.ms)
          .pipe(take(previous - current))
          .pipe(map(n => previous - n));
  }
}
