// animations/appear.animation.ts
//
// Angular animatino for growing and shrinking items vertically.

import {transition, style, animate} from "@angular/animations";

export const appear = [
  transition(":enter", [style({maxHeight: 0}), animate(500, style({maxHeight: "300px"}))]),
  transition(":leave", [style({maxHeight: "300px"}), animate(500, style({maxHeight: 0}))]),
];
