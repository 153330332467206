// blue/rooms/report-entry/component.ts
//
// Module for room-specific reporting, eventually displaying a list of items/snags.

const MODULE = ["rooms", "report-entry"];

import {Component, ElementRef, Input, OnChanges, SimpleChanges} from "@angular/core";

import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import {AngularFirestore} from "angularfire2/firestore";

import {Room} from "../../rooms/type";
import {ItemWithSnag} from "../../items/type";
import {Status, calcStatus} from "../../status";

import {GenericCollectionComponent, GenericDocumentComponent} from "../../utils";
import {Logger, LogModule} from "../../utils";

import {Service} from "../service";

////////////////////////////////////////////////////////////////
// ROOM COLLECTION FOR REPORTING PURPOSES
//
// Generate a repor entry for each element in the property's `rooms` collection.
@Component({
  selector: "room-report-entry-collection",
  templateUrl: "collection.html",
})
@LogModule(...MODULE)
export class CollectionComponent extends GenericCollectionComponent<Room> {
  constructor(afs: AngularFirestore) {
    super(afs);
  }
}

////////////////////////////////////////////////////////////////
// HANDLE DOCUMENT OF ROOM FOR PURPOSES OF SHOWING ROOM REPORT
//
// Retrieve snags from this room, and pass along to the component for actually reporting the room.
@Component({
  selector: "room-report-entry-document",
  template: `
<ng-container *ngIf="data$ | async as data">
  <room-report-entry-display
    [data]="data"
    [itemsWithSnags$]="itemsWithSnags$"
    [status$]="status$"
  >
  </room-report-entry-display>
</ng-container>
`,
})
@LogModule(...MODULE)
export class DocumentComponent extends GenericDocumentComponent<Room> implements OnChanges {
  public itemsWithSnags$: Observable<Array<ItemWithSnag>>;
  public element: HTMLElement;
  public status$: Observable<Status>;

  constructor(private elementRef: ElementRef, private service: Service) {
    super();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.itemsWithSnags$ = this.service.getItemsWithSnags$(this.doc);
    this.status$ = this.itemsWithSnags$.pipe(map(itemsWithSnags => calcStatus(itemsWithSnags)));
    this.element = this.elementRef.nativeElement;

    this.logger.log$(this.status$);
  }
}

////////////////////////////////////////////////////////////////
// DISPLAY A ROOM REPORT
// Report the actual room information.
@Component({
  selector: "room-report-entry-display",
  templateUrl: "component.html",
})
@LogModule(...MODULE)
export class DisplayComponent {
  @Input("data") public room: Room;
  @Input() itemsWithSnags$: Observable<Array<ItemWithSnag>>;
  @Input() status$: Observable<Status>;

  public logger: Logger;
}
export const DECLARATIONS = [CollectionComponent, DocumentComponent, DisplayComponent];
