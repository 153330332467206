// blue/status/component.ts: components for displaying status information in different ways.
//
// The application using this module is responsible for including chart.js,
// most likely in the `scripts` property of `.angular-cli.json`.

const MODULE = ["status"];


import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";

import {Observable, interval} from "rxjs";
import {take} from "rxjs/operators";

import {SNAG_COLOR, NOTSNAG_COLOR, UNINSPECTED_COLOR, SNAG_LABEL, NOTSNAG_LABEL, UNINSPECTED_LABEL} from "../snags/type";
import {Logger, LogModule} from "../utils";

import {Status} from "./type";

// TODO: somehow sync this with meterial design app color.
const APP_COLOR = "#03a9f4";

function runup(n: number) {
  //  return interval(RUNUP / n).pipe(take(n));
  return interval(50).pipe(take(n + 1));
}

////////////////////////////////////////////////////////////////
// Superclass for all components displaying statuese in different ways.

export class StatusDisplay implements OnChanges {
  @Input() status: Status;
  @Input() percent = true;

  public SNAG_COLOR = SNAG_COLOR;
  public NOTSNAG_COLOR = NOTSNAG_COLOR;
  public UNINSPECTED_COLOR = UNINSPECTED_COLOR;

  public SNAG_LABEL = SNAG_LABEL;
  public NOTSNAG_LABEL = NOTSNAG_LABEL;
  public UNINSPECTED_LABEL = UNINSPECTED_LABEL;

  public numSnagsPct: number;
  public numNotSnagsPct: number;
  public numNotInspectedPct: number;

  public numSnags: number;
  public numNotSnags: number;
  public numNotInspected: number;

  public logger: Logger;

  ngOnChanges(simpleChanges: SimpleChanges) {
    const {numItems, numSnags, numNotSnags} = this.status;

    this.numSnagsPct = Math.floor(numSnags / numItems * 100);
    this.numNotSnagsPct = Math.ceil(numNotSnags / numItems * 100);
    this.numNotInspectedPct = 100 - this.numSnagsPct - this.numNotSnagsPct;

    this.numSnags = numSnags;
    this.numNotSnags = numNotSnags;
    this.numNotInspected = numItems - numSnags - numNotSnags;
  }
}

////////////////////////////////////////////////////////////////
// STATUS DISPLAY AS BAR
@Component({
  selector: "status-bar",
  templateUrl: "bar.html",
  styleUrls: ["bar.css"],
})
@LogModule(...MODULE)
export class Bar extends StatusDisplay {
  @Input() labels = true;
  @Input() showNumerals = true;
  @Input() showDescription = true;
  @Input() dynamicDescription = true;
}

////////////////////////////////////////////////////////////////
// STATUS DISPLAY AS PIE
@Component({
  selector: "status-pie",
  template: `<p-chart type="pie" [width]="width" [height]="height" [data]="data"[options]="options"></p-chart>`,
})
@LogModule(...MODULE)
export class Pie extends StatusDisplay implements OnChanges {
  @Input() width = 120;
  @Input() height = 120;
  @Input() labels = true;

  // PrimeNG provides no typing for this?
  public data: any;
  public options: any;

  ngOnChanges(simpleChanges: SimpleChanges) {
    super.ngOnChanges(simpleChanges);
    const labels = [NOTSNAG_LABEL, SNAG_LABEL, UNINSPECTED_LABEL];
    this.data = {
      datasets: [
        {
          data: [this.numNotSnags, this.numSnags, this.numNotInspected],
          backgroundColor: [NOTSNAG_COLOR, SNAG_COLOR, UNINSPECTED_COLOR],
        },
      ],
    };
    this.options = {
      tooltips: {
        callbacks: {
          label: function(tooltipItem, chartData) {
            return labels[tooltipItem.index] + " : " + chartData.datasets[0].data[tooltipItem.index];
          }
        }
      }
    };
  }
}
@Component({
  selector: "status-text",
  templateUrl: "text.html",
  styleUrls: ["text.css"],
})
@LogModule(...MODULE)
export class Text extends StatusDisplay {
  @Input() percent = false;
  @Input() animate = false;
  @Input() numPurchasableItems: number;

  // Here, refers to "OK", "not OK", "to do" labels in little boxes.
  @Input() labels = false;

  @Output() purchasable = new EventEmitter();

  public numSnagsPct$: Observable<number>;
  public numNotSnagsPct$: Observable<number>;
  public numNotInspectedPct$: Observable<number>;

  public numSnags$: Observable<number>;
  public numNotSnags$: Observable<number>;
  public numNotInspected$: Observable<number>;
  public numPurchasableItems$: Observable<number>;

  public APP_COLOR = APP_COLOR;

  ngOnChanges(simpleChanges: SimpleChanges) {
    super.ngOnChanges(simpleChanges);

    this.numSnagsPct$ = runup(this.numSnagsPct);
    this.numNotSnagsPct$ = runup(this.numNotSnagsPct);
    this.numNotInspectedPct$ = runup(this.numNotInspectedPct);

    this.numSnags$ = runup(this.numSnags);
    this.numNotSnags$ = runup(this.numNotSnags);
    this.numNotInspected$ = runup(this.numNotInspected);
    this.numPurchasableItems$ = runup(this.numPurchasableItems);
  }

  public onPurchasable() {
    this.purchasable.emit();
  }
}

export const STATUS_DECLARATIONS = [Bar, Pie, Text];
