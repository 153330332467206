// blue/providers/fba-log-event.directive.ts: Directive to log event against Firebase analytics
//
// Usage:
// ```
// <div [fbaLogEvent]="'my-event-name'">
// ```

import {Directive, HostListener, Input} from "@angular/core";
import {FirebaseAnalyticsService} from "../providers//firebase-analytics.service";

@Directive({
  selector: "[fbaLogEvent]",
})
export class FbaLogEventDirective {
  @Input() fbaLogEvent: string;

  constructor(private firebaseAnalyticsService: FirebaseAnalyticsService) {}

  @HostListener("click")
  onClick() {
    this.firebaseAnalyticsService.logEvent(this.fbaLogEvent);
  }
}
