export function exit() {
  // Where is this defined?
  // See https://issues.apache.org/jira/browse/CB-206.
  const app = (navigator as any).app;
  const device = (navigator as any).device;

  if (app && app.exitApp) app.exitApp();
  else if (device && device.exitApp) device.exitApp();
  else window.close();
}
