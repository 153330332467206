// cordova.guard.ts
//
// Check to make sure that Cordova is loaded and deviceready event has come.
// This is used to prevent premature activation of the sign-in route.

import {CanActivate} from "@angular/router";
import {deviceReady} from "@nims/cordova/lib/events";

// `CanActivate` guard to Wait for Cordova to be loaded.
// Used as guard on sign-in route.
export class CordovaGuard implements CanActivate {
  async canActivate() {
    if (window.cordova) {
      await deviceReady;
    }
    return true;
  }
}
