// blue/app/src/app/utils/index.ts
//
// Barrel for blue app utils.

import {Logger} from "@nims/jsutils";
import {LogModule} from "./log-module.decorator";

export {GenericCollectionComponent} from "./collection.component";
export {GenericDocumentComponent} from "./document.component";
export {exit} from "./exit";
export {spinner} from "./spinner";

export {Logger, LogModule};
