// blue/room-types/menu/component.ts
//
// Dropdown for selecting room types.
// Emits the ID.
// Used when defining a new room, or editing an existing one.

import {Component, Output, EventEmitter} from "@angular/core";
import {Logger, LogModule} from "../../utils";
import {Service} from "../service";

@Component({
  selector: "room-types-menu",
  templateUrl: "component.html",
})
@LogModule("shared")
export class MenuComponent {
  @Output() select = new EventEmitter<string>();

  public entries = this.service.filteredPairs();

  public logger: Logger;

  constructor(private service: Service) {}

  public onSelect(id: string) {
    this.select.emit(id);
  }
}
