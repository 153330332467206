// A snag.
//
// Part of a collection under rooms.

import {AngularFirestoreDocument as Document, QueryFn} from "angularfire2/firestore";

import {I} from "@nims/jsutils";

import {Photo} from "../photos";
import {Room} from "../rooms/type";

export interface Snag {
  snag: boolean;
  photo?: Photo | null;
  description?: string;
  createdOn?: number; // For legacy inspected items createdOn date is not present
}

export const NOTSNAG_COLOR = "#5AE193";
export const SNAG_COLOR = "#FF7F6F";
export const UNINSPECTED_COLOR = "lightgray";

export const NOTSNAG_LABEL = "OK";
export const SNAG_LABEL = "NOT OK";
export const UNINSPECTED_LABEL = "PENDING";

// Get a collection of snags from a room.
export function getSnags(document: Document<Room>, queryFn: QueryFn = I) {
  return document.collection<Snag>("snags");
}
