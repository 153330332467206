// Entry point for "users" grouping of functionality.

import {Routes} from "@angular/router";
import {CordovaGuard} from "../providers";
import {TosComponent} from "./tos/component";

import {Service} from "./service";
import {SignedInGuard} from "./signed-in.guard";

export {Service as UserService, SignedInGuard};

import {SIGN_IN_DECLARATIONS, SIGN_IN_ROUTES} from "./sign-in/component";

export const USERS_DECLARATIONS = [...SIGN_IN_DECLARATIONS, TosComponent];
export const USERS_PROVIDERS = [SignedInGuard, Service];

export const USERS_ROUTES: Routes = [
  {
    path: "user",
    canActivate: [CordovaGuard],
    children: [
      {path: "", pathMatch: "full", redirectTo: "/user/sign-in"},
      ...SIGN_IN_ROUTES,
      {path: "tos", component: TosComponent},
    ],
  },
];
