// blue/products/index.ts: Products module

import {Routes} from "@angular/router";

import {Service} from "./service";
import {ScreenComponent} from "./screen.component";
import {CardDisplayComponent} from "./card.component";

// We don't recommend importing this from here. Prefer to import directly from `service.ts`.
export {Service as ProductsService} from "./service";

export const PRODUCTS_PROVIDERS = [Service];
export const PRODUCTS_DECLARATIONS = [ScreenComponent, CardDisplayComponent];
export const PRODUCTS_ROUTES: Routes = [{path: "products", component: ScreenComponent}];
