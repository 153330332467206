// blue/shares: entry point for all sharing related functionality

import {Routes} from "@angular/router";

import {SCREEN_DECLARATIONS, SCREEN_ROUTES} from "./screen/component";
import {REPORT_DECLARATIONS, REPORT_ROUTES} from "./report/component";
import {DECLARATIONS as SHARED_REPORT_BROWSER_DECLARATIONS} from "./shared-report-browser/component";

import {Resolver} from "./resolver";
import {Service} from "./service";

export {Service as SharesService};

export const SHARES_DECLARATIONS = [
  ...REPORT_DECLARATIONS,
  ...SCREEN_DECLARATIONS,
  ...SHARED_REPORT_BROWSER_DECLARATIONS,
];
export const SHARES_ROUTES: Routes = [...SCREEN_ROUTES, ...REPORT_ROUTES];
export const SHARES_PROVIDERS = [Service, Resolver];
