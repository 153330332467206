// blue/my-property/resolver.js
//
// Resolve to a property. But is this used?

import {Injectable} from "@angular/core";
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {AngularFirestoreDocument} from "angularfire2/firestore";
import {Property} from "../properties";
import {Logger, LogModule} from "../utils";
import {Service} from "./service";

// Resolver for "my-report", which resolves to the ID of the current user's property.
@Injectable()
@LogModule("my-property")
export class Resolver implements Resolve<AngularFirestoreDocument<Property>> {
  public logger: Logger;

  constructor(private service: Service) {}

  resolve(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    routerStateSnapshot: RouterStateSnapshot
  ) {
    if (this.logger.enabled) console.log(...this.logger.log("in resolve"));

    const doc$ = this.service.document$;

    if (this.logger.enabled)
      doc$.subscribe(doc => console.log(...this.logger.log("emitting", {doc})));

    return doc$;
  }
}
