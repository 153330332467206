// blue/items/service: service for items.
//
// In particular, retain a static list of items.

const MODULE = ["items"];

import {Injectable} from "@angular/core";
import {LogModule, Logger} from "../utils";

import {
  AngularFirestore,
  AngularFirestoreCollection as Collection,
  QueryFn,
} from "angularfire2/firestore";

import {afsCollectionToObjectOfData} from "@nims/afutils";
import {Hash, filter, uniq, values} from "@nims/jsutils";

import {Item, itemRoomTypeChecker} from "./type";

@Injectable()
@LogModule(...MODULE)
export class Service {
  public itemsO: {[id: string]: Item};

  // A list of unique aspects in the system.
  public aspects: string[];

  public ready: Promise<void>;

  public logger: Logger;

  constructor(private afs: AngularFirestore) {
    this.ready = this.initialize();
  }

  // Read some infrequently changing objects into core.
  // Returns promise indicating data has been read in and flattened.
  private initialize(): Promise<any> {
    return new Promise(resolve =>
      afsCollectionToObjectOfData(this.items()).subscribe(itemsO => {
        this.aspects = uniq(values(itemsO).map(item => item.aspect)).sort();
        resolve((this.itemsO = itemsO));
      })
    );
  }

  public items(queryFn?: QueryFn): Collection<Item> {
    // TODO: change this.
    //    return this.afs.collection<Item>("items", ref => ref.orderBy("section").orderBy("order"));
    return this.afs.collection<Item>("items", queryFn);
  }

  // Find the items in a room (including non-available ones).
  public getByRoomTypeId(roomTypeId: string): Hash<Item> {
    return filter(this.itemsO, itemRoomTypeChecker(roomTypeId));
  }
}
