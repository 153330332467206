// Terminology for use throughout the app.

export enum Terms {
  AREA = "floor space",

  // This was intended to be used by the app,
  // but now we are using the `sqft` pipe defined in ngutils,
  // which has its own ideas about how to represent sqaure feet.
  SQFT = "sq. ft.",
}
