// blue/utils/spinner.ts
//
// Surround some (asynchronous) action with a spinner, hiding it even if the action throws an error.
// Today's JS mini-lesson: `try` clauses don't require `catch`.

export async function spinner<T>(msg: string, fn: () => T): Promise<T> {
  const spinnerDialog: any = (window as any).SpinnerDialog;

  if (spinnerDialog) spinnerDialog.show(null, msg);

  try {
    return await fn();
  } finally {
    if (spinnerDialog) spinnerDialog.hide();
  }
}
