// blue/utils/collection.component.ts
//
// Superclass for component which deals with an AngularFirestore "collection"
// and also knows how to add to itself.
//
// This exposes both a `values$` property, which is an observable of arrays of values;
// a `docs$` property, which is an observable of arrays of `AngularFirestoreDocument`s,
// and could be useful if we want to send along documents to a component;
// and a `snapshots$` property, for iterating over snapshots,
// which provide acess to both metadata (such as ID) and data itself.
//
// Exported at module level as `GenericCollectionComponent`.

import {Input, OnInit} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {
  AngularFirestore,
  AngularFirestoreCollection as Collection,
  AngularFirestoreDocument as Document,
  DocumentChangeAction,
} from "angularfire2/firestore";

export class GenericCollectionComponent<Data> implements OnInit {
  // An AngularFirestore document for this item.
  @Input() protected collection: Collection<Data>;

  // Observable of the item itself.
  public values$: Observable<Data[]>;
  public docs$: Observable<Document<Data>[]>;
  public snapshots$: Observable<DocumentChangeAction<Data>[]>;

  constructor(private afs: AngularFirestore) {}

  ngOnInit() {
    this.values$ = this.collection.valueChanges();
    this.snapshots$ = this.collection.snapshotChanges();
    this.docs$ = this.snapshots$.pipe(
      map(actions => actions.map(action => new Document(action.payload.doc.ref, this.afs)))
    );
  }

  public add(data: Data) {
    return this.collection.add(data);
  }
}
