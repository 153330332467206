// blue/app/src/app/angular-fire-module.ts: Module for Firebase and AngularFire related things

import {NgModule} from "@angular/core";

import {AngularFireModule} from "angularfire2";
import {AngularFireAuthModule} from "angularfire2/auth";
import {AngularFirestoreModule} from "angularfire2/firestore";

import {environment} from "./environments/environment";

@NgModule({
  imports: [
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
  ],
  exports: [],
})
export class Module {}
