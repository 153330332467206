// shapes/capture/preview.ts
//
// Show preview screen, with buttons overlaid on camera preview.
// This component knows nothing abou5 geometry or orientation.
// It merely presents the UI, and reports "features"--annotated orientation data--to the caller.

const MODULE = ["shapes", "capture"];

import {Component, OnInit, OnDestroy} from "@angular/core";
import {fromEvent, Subscription} from "rxjs";
import * as cameraPreview from "@nims/cordova/lib/camera-preview";
import {Logger, LogModule} from "../../utils";
import {inputDeviceHeight} from "../device-height";
import {Service} from "./service";

@Component({
  selector: "shape-capture",
  templateUrl: "component.html",
  styleUrls: ["component.css"],
})
@LogModule(...MODULE)
export class CaptureComponent implements OnInit, OnDestroy {
  public deviceOrientationEvent: DeviceOrientationEvent;

  private deviceOrientationEventSubscription: Subscription;

  public logger: Logger;

  constructor(private service: Service) {}

  public ngOnInit() {
    this.setupCamera();

    this.deviceOrientationEventSubscription = fromEvent(window, "deviceorientation").subscribe(
      (deviceOrientationEvent: DeviceOrientationEvent) =>
        (this.deviceOrientationEvent = deviceOrientationEvent)
    );
  }

  public ngOnDestroy() {
    this.stopCamera();

    if (this.deviceOrientationEventSubscription)
      this.deviceOrientationEventSubscription.unsubscribe();
  }

  // EVENT HANDLERS
  public onCancel() {
    this.service.cancel();
  }

  public onFeature(featureType) {
    this.service.addFeature({type: "corner", deviceOrientation: this.deviceOrientationEvent});
  }

  public onReset() {
    this.service.reset();
  }

  public onSettings() {
    inputDeviceHeight();
  }

  public onDone() {
    this.service.done();
  }

  ////////////////////////////////////////////////////////////////
  // PRIVATE

  private stopCamera() {
    if (!(window as any).CameraPreview) return;

    return cameraPreview.stopCamera();
  }

  private async setupCamera() {
    try {
      await cameraPreview.startCamera({
        camera: "back",
        tapPhoto: false,
        toBack: true,
      });
      await cameraPreview.show();
    } catch (e) {}
  }
}
