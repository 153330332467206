// property/report/component.ts
//
// Report on properties.
// This is not routable; the routes come through "share" or "my-property'.

import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ContentChildren,
  QueryList,
} from "@angular/core";

import {Routes} from "@angular/router";

import {sentenceCase} from "@nims/jsutils";

import {AngularFirestoreCollection as Collection} from "angularfire2/firestore";
import {Terms} from "../../environments/terms";

import {GenericDocumentComponent, Logger, LogModule} from "../../utils";
import {Room, getRooms} from "../../rooms/type";
import {DocumentComponent as RoomReportEntryDocumentComponent} from "../../rooms/report-entry/component";

import {Property} from "../type";

const MODULE = ["properties", "report"];

@Component({
  selector: "property-report-document",
  template: `
<ng-container *ngIf="data$ | async as data">
  <property-report-display [data]="data" [rooms]="rooms">

    <div spaced style="border-color: #ddd">

      <!-- STATUS BY ROOM -->
      <div border bottom thin>By room</div>
      <property-report-status-document [doc]="doc" (jumpRoom)="onJumpRoom($event)"></property-report-status-document>

      <!-- STATUS BY ASPECT -->
      <div border bottom thin>By checkpoint type</div>
      <property-report-aspect-pies [doc]="doc"></property-report-aspect-pies>

      <!-- AREA BY ROOM -->
      <div border bottom thin>{{AREA}} estimates</div>
      <property-report-area-document [doc]="doc"></property-report-area-document>

      <!-- INDIVIDUAL ROOMS -->
      <room-report-entry-collection [collection]="rooms"></room-report-entry-collection>

    </div>

  </property-report-display>
</ng-container>
`,
})
@LogModule(...MODULE)
export class DocumentComponent extends GenericDocumentComponent<Property> implements OnChanges {
  public rooms: Collection<Room>;

  // Get all the individual room display components.
  // However, for some reason, these are not populated, whether we use ContentChildren or ViewChildren.
  @ContentChildren(RoomReportEntryDocumentComponent)
  roomEntries: QueryList<RoomReportEntryDocumentComponent>;

  public AREA = sentenceCase(Terms.AREA);

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.rooms = getRooms(this.doc);
  }

  public onJumpRoom(roomId: string) {
    console.log(...this.logger.log("want to jump to room", roomId));

    const roomEntry = this.roomEntries.find(_roomEntry => roomEntry.doc.ref.id === roomId);

    if (!roomEntry)
      return console.error(...this.logger.log("Could not find room entry with id", roomId));

    roomEntry.element.scrollIntoView({behavior: "smooth", block: "start"});
  }
}

////////////////////////////////////////////////////////////////
// REPORT ON A PROPERTY
//
// Display report on screen!
// Mainly, just invoke the shared report logic.
@Component({
  selector: "property-report-display",
  templateUrl: "component.html",
})
@LogModule(...MODULE)
export class DisplayComponent implements OnInit {
  @Input("data") property: Property;
  @Input() rooms: Collection<Room>;

  public logger: Logger;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    if (this.logger.enabled) console.log(...this.logger.log({property: this.property}));
  }

  public onPrint() {
    return (cordova.plugins as any).printer.print(this.elementRef.nativeElement);
  }
}

export const REPORT_DECLARATIONS = [DisplayComponent, DocumentComponent];
export const REPORT_ROUTES: Routes = [];
