// blue/users/service.ts
//
// Handle user management, logging in, logging out.
// TODO: make this code common with console, move to @nims/common repo.

import {Injectable} from "@angular/core";
import {Router} from "@angular/router";

import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import * as firebase from "firebase";
import * as firebaseui from "firebaseui";
import {AngularFireAuth} from "angularfire2/auth";
import {Logger, LogModule} from "../utils";

////////////////////////////////////////////////////////////////
// SERVICE
@Injectable()
@LogModule("users")
export class Service {
  // A stream of userIDs (or null if logged out).
  public uid$: Observable<string | null>;
  public uid: string;
  public name: string;
  public email: string;
  public afuser$: Observable<firebase.User>;

  // Have we heard from the Firebase auth system at all yet?
  // This is turned on the first time we get an emission on the auth stream.
  public havePing = false;

  // Has an initial dynamic link come in, taking us to the shared report route?
  public handlingInitialDynamicLink = false;

  public logger: Logger;

  private authUI: firebaseui.auth.AuthUI;
  private initialized = false;

  constructor(private readonly angularFireAuth: AngularFireAuth, private readonly router: Router) {}

  // Initialize user handling, in the form of watching the Firebase authorization stream.
  // This is called from the app (the app component,
  // but not the console, where there is no login.
  public init() {
    if (this.initialized) return;
    this.initialized = true;

    const authState = (this.afuser$ = this.angularFireAuth.authState);

    this.uid$ = authState.pipe(map(afUser => afUser && afUser.uid));

    authState.subscribe(afuser => {
      console.log("got ping on auth stream", afuser);
      this.havePing = true;

      if (afuser) {
        this.uid = afuser.uid;
        this.name = afuser.displayName;
        this.email = afuser.email;
        if (!this.handlingInitialDynamicLink) this.router.navigate(["/my-property", "inspect"]);
        // if (!afuser.emailVerified) this.sendEmailVerification(afuser);
      } else {
        this.uid = this.name = this.email = null;

        if (this.handlingInitialDynamicLink) this.handlingInitialDynamicLink = false;
        else {
          this.router.navigate(["/user", "sign-in"]);
        }
      }
    });

    this.authUI = new firebaseui.auth.AuthUI(firebase.auth());
  }

  public signInWithEmailAndPassword(email: string, password: string) {
    const signedIn = this.angularFireAuth.auth.signInWithEmailAndPassword(email, password);

    signedIn.then(
      user => {
        //        if (this.logger.enabled)
        //          console.log(...this.logger.log(`#signInWithEmailAndPassword, user is`, user));
      },
      err => console.log("error logging in", err)
    );

    return signedIn;
  }

  // Sign out. This will trigger an emission on the authState observable.
  public signOut() {
    this.authUI.disableAutoSignIn();
    return this.angularFireAuth.auth.signOut();
  }

  // Firebase UI for authentication.
  // This is invoked from the sign-in page.
  // The sign-in page knows where we want to redirect after signing in.
  public firebaseUi(selector: string, signInSuccessUrl?: string) {
    const uiConfig: firebaseui.auth.Config = {
      signInSuccessUrl,

      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,

        // TWITTER
        // We have specified a callback of https://app.dsnag.in/__/auth/handler.
        // (See https://apps.twitter.com/app/14926918/settings)
        firebase.auth.TwitterAuthProvider.PROVIDER_ID,

        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],

      // Suppress ToS display, since it does not work well with Cordova.
      tosUrl: "",

      callbacks: {
        signInSuccessWithAuthResult(authResult: any, redirectUrl: any) {
          return false;
        },
        async signInFailure(error: firebaseui.auth.AuthUIError) {
          // Some unrecoverable error occurred during sign-in.
          // Return a promise when error handling is completed and FirebaseUI
          // will reset, clearing any UI. This commonly occurs for error code
          // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
          // occurs. Check below for more details on this.
          console.error("Sign in failure", error);
        },
        uiShown() {},
      },

      // This turns off the acocunt chooser. See https://github.com/firebase/firebaseui-web/issues/42.
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    };

    this.authUI.start(selector, uiConfig);
  }

  public isPendingRedirect() {
    return this.authUI.isPendingRedirect();
  }

  // To handle the case where the app activity is destroyed before the sign-in operation completes,
  // call getRedirectResult when your app loads.
  public async getRedirectResult() {
    await firebase.auth().getRedirectResult();
  }
}
