// blue/my-property
//
// Everything to do with "my property", the property belonging to the currently signed-in user.
// We can edit, inspect, or report on that property.
// Routes are provide for each, starting with a `my-property` segment.

// (Some of) these routes provide some UI wrappers,
// in order to personally the "my-property" reporting or inspecting experience.
// But in all cases, they then pretty much just finding the current property,
// and invoke the relevant property component.

import {Routes} from "@angular/router";

import {REPORT_DECLARATIONS, REPORT_ROUTES} from "./report/component";
import {EDIT_DECLARATIONS, EDIT_ROUTES} from "./edit/component";
import {INSPECT_DECLARATIONS, INSPECT_ROUTES} from "./inspect/component";
import {MEASURE_DECLARATIONS, MEASURE_ROUTES} from "./measure/component";

import {Service} from "./service";
import {Resolver} from "./resolver";
import {HasPropertyGuard} from "./has-property.guard";

export const MY_PROPERTY_PROVIDERS = [Service, Resolver, HasPropertyGuard];

export const MY_PROPERTY_DECLARATIONS = [
  ...EDIT_DECLARATIONS,
  ...INSPECT_DECLARATIONS,
  ...REPORT_DECLARATIONS,
  ...MEASURE_DECLARATIONS,
];

export const MY_PROPERTY_ROUTES: Routes = [
  {
    path: "my-property",
    children: [
      {path: "", pathMatch: "full", redirectTo: "/my-property/inspect"},
      ...REPORT_ROUTES,
      ...INSPECT_ROUTES,
      ...EDIT_ROUTES,
      ...MEASURE_ROUTES,
    ],
  },
];

// In general it's better practice to import the service directory, rather from here,
// which could result in circular imports.
export {Service as MyPropertyService, Resolver as MyPropertyResolver, HasPropertyGuard};
