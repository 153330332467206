// blue/components/logger/component.ts
//
// Logging in templates.
// Usage:
// ```
// public logger = makeLogger(['module', 'file');
//
// <logger [logger]="logger">My data goes here</logger>
// ```

import {Component, Input} from "@angular/core";
import {Logger} from "@nims/jsutils";

@Component({
  selector: "logger",
  template: `
<ng-container *ngIf="logger.enabled && logger.templateEnabled">
<span [ngStyle]="logger.style">{{logger.name[0]}}/{{logger.name[1]}}</span>
  <ng-content></ng-content>
</ng-container>
`,
  styles: [
    `:host { outline: 1px solid green; margin: 8px; font-family: monospace; font-size: 8pt; display: block; }`,
    `span { padding: 4px; }`,
  ],
})
export class LoggerComponent {
  @Input() logger: Logger;
}
