export function openUrlInInAppBrowser(url: string) {
  if (window.cordova) {
    // Where is `InAppBrowser" defined?
    const inAppBrowser: any = (window.cordova as any).InAppBrowser;

    // hardwareback: set to yes to use the hardware back button to navigate backwards through the InAppBrowser's history.
    // If there is no previous page, the InAppBrowser will close.
    // The default value is yes, so you must set it to no if you want the back button to simply close the InAppBrowser.
    inAppBrowser.open(url, "_blank", "hardwareback=no");
  } else {
    window.open(url);
  }
}
