// blue/rooms/edit/component.ts
//
// Edit list of rooms, as part of property editing screen.

import {Component, Input} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AngularFirestoreCollection as Collection} from "angularfire2/firestore";
import {afsCollectionToArrayOfIdAndData} from "@nims/afutils";
import {confirm} from "@nims/cordova";
import {RoomType} from "../../room-types/type";
import {RoomTypesService} from "../../room-types";
import {Room, sortRooms} from "../type";

@Component({
  selector: "room-edit-collection",
  templateUrl: "component.html",
})
export class Edit {
  @Input("collection") rooms: Collection<Room>;

  public data$: Observable<{id: string; room: Room; roomType: RoomType}[]>;

  constructor(private roomTypesService: RoomTypesService) {}
  ngOnInit() {
    const roomTypesO = this.roomTypesService.roomTypesO;

    this.data$ = afsCollectionToArrayOfIdAndData(this.rooms).pipe(
      map(rooms => rooms.sort(({data: data1}, {data: data2}) => sortRooms(data1, data2))),

      // Look up roomType based on roomTypeId and include in this `data$` object.
      // In the template, we consult this to see whether it is an `auto` type "pseudo-room" which cannot be deleted.
      map(rooms =>
        rooms.map(({id, data: room}) => ({id, room, roomType: roomTypesO[room.roomTypeId]}))
      )
    );
  }

  public async onDelete(id) {
    if ((await this.confirmDelete()) === 1) await this._deleteRoom(id);
  }

  public onChangeName(id, event: Event) {
    // TODO: figure out where InputEvent is defined.
    const name = (event.target as any).value.trim();

    if (name) return this.rooms.doc(id).update({name: (event.target as any).value});
  }

  private _deleteRoom(id) {
    return this.rooms.doc(id).delete();
  }

  private async confirmDelete() {
    return await confirm(
      `Really delete this room?
This will permanently remove all inspection results, and cannot be undone.`
    );
  }
}

export const EDIT_DECLARATIONS = [Edit];
