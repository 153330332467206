import {Environment} from "./environment.type";

export const environment: Environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyC6xhGXd3KkA3YJaoHnuKLm4dP55U_Nw6s",
    authDomain: "app.dsnag.in",
    databaseURL: "https://nims-blue.firebaseio.com",
    projectId: "nims-blue",

    // Storage is not used.
    storageBucket: "nims-blue.appspot.com",

    // Messaging is not used.
    messagingSenderId: "591836527152",
  },
  debug: {
    ngLog: true,
    enableRouterTracing: true,
    log: true,
  },
  features: {
    deleteProperty: true,
    resetProperty: true,
    unbuyProducts: true,
    linkToPremium: false,
    linkToVideo: true,
    offlinePurchases: true,
  },
};
