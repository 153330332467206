// blue/store/src/item-photo.ts
//
// Interface for item photos.

// These coordinates are in the 0-1 range, vis-a-vis a 4x3 box.
export interface PhotoHighlight {
  left: number;
  top: number;
  width: number;
  height: number;
}

// A photo contains various URLs, highlight info, and headings.
export interface Photo {
  imageBase64: string;

  // Highlight.
  highlight?: PhotoHighlight;

  orientation?: number;
}

export function clonePhoto(photo: Photo) {
  return photo && {...photo, highlight: photo.highlight && {...photo.highlight}};
}
