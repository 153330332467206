import {trigger, transition, style, animate} from "@angular/animations";

// We have these in `@nims/jsutils/func`, but cannot use them
// because of AoT stupidity.

export function LT(x: number, y: number) {
  return x < y;
}
export function GT(x: number, y: number) {
  return x > y;
}

export function slideInRight() {
  return trigger("slideInRight", [
    transition(":enter", [
      style({transform: "translateX(100%)", opacity: 0}),
      animate("250ms", style({transform: "translateX(0)", opacity: 1})),
    ]),
    transition(":leave", [
      style({transform: "translateX(0)", opacity: 1}),
      animate("250ms", style({transform: "translateX(-100%)", opacity: 0})),
    ]),
    transition(LT as any, [
      style({transform: "translateX(0)", opacity: 1}),
      animate("250ms", style({transform: "translateX(-100%)", opacity: 0})),
    ]),
    transition(GT as any, [
      style({transform: "translateX(0)", opacity: 1}),
      animate("250ms", style({transform: "translateX(100%)", opacity: 0})),
    ]),
  ]);
}
