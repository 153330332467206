// blue/my-property/edit/component.ts
//
// Edit current property.

import {Component} from "@angular/core";
import {OnInit} from "@angular/core";
import {Routes} from "@angular/router";

import {Observable} from "rxjs";
import {AngularFirestoreDocument} from "angularfire2/firestore";

import {Logger, LogModule} from "../../utils";

import {Service} from "../service";
import {Property} from "../../properties";

import {HasPropertyGuard} from "../has-property.guard";

const MODULE = ["my-property", "report"];

////////////////////////////////////////////////////////////////
// HANDLE MY PROPERTY ROUTE REQUEST
//
// Template for kicking off editing property info for my property.
// Retrieve the ID for my property, then pass along to the `define-property-document-info` component.
//
// TODO: Add error handling, such as case where propety cannot be found.

@Component({
  selector: "my-property-edit-route",
  template: `
<ng-container *ngIf="doc$ | async as doc">
  <property-edit-document [doc]="doc"></property-edit-document>
</ng-container>`,
})
@LogModule(...MODULE)
export class RouteComponent implements OnInit {
  public doc$: Observable<AngularFirestoreDocument<Property> | null>;

  public logger: Logger;

  constructor(private service: Service) {}

  ngOnInit() {
    if (this.logger.enabled) console.log(...this.logger.log("entered ngOnInit"));

    this.doc$ = this.service.document$;

    this.logger.log$(this.doc$);
  }
}

export const EDIT_DECLARATIONS = [RouteComponent];

export const EDIT_ROUTES: Routes = [
  {
    path: "edit",
    component: RouteComponent,
    canActivate: [HasPropertyGuard],
    data: {screenName: "my-property-edit"},
  },
];
