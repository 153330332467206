// blue/rooms/service.ts
//
// Services related to rooms, such as adding them to a property, or finding their status or area.

const MODULE = ["rooms"];

import {Injectable} from "@angular/core";

import {Observable, ReplaySubject, combineLatest} from "rxjs";
import {map} from "rxjs/operators";

import {
  AngularFirestoreCollection as Collection,
  AngularFirestoreDocument as Document,
  QueryFn,
} from "angularfire2/firestore";

import {Hash, filter, mapToArray} from "@nims/jsutils";
import {afsCollectionToObjectOfData, batchAddMultiple, batchUpdateCollection} from "@nims/afutils";

import {Service as ItemsService} from "../items/service";
import {Service as ProductsService} from "../products/service";
import {Service as RoomTypesService} from "../room-types/service";
import {deserializeShape, makePolygon} from "../shapes/utils";

import {
  Item,
  ItemWithSnag,
  availabilityChecker,
  itemRoomTypeQuery,
  itemRoomTypeAndNotPremiumQuery,
  purchasabilityChecker,
} from "../items/type";

import {Property} from "../properties/type";
import {Snag} from "../snags/type";

import {Logger, LogModule} from "../utils";

import {Room} from "./type";

const UPGRADED: Partial<Room> = {upgraded: true};
const DOWNGRADED: Partial<Room> = {upgraded: false};

// Filter out item/snag pairs which are uninspected.
// TODO: isn't this duplicated in items/type.td?
export function isItemUninspected({snag}: ItemWithSnag) {
  return !snag;
}

@Injectable()
@LogModule(...MODULE)
export class Service {
  public logger: Logger;

  // Maintain a cache of room information, to be shared among the property page and the room page.
  // This is an object keyd by room, containing an observable of a hash of item/snag pairs, keyed by item ID.
  public itemsWithSnags$: Hash<Observable<Array<ItemWithSnag>>> = {};

  constructor(
    private itemsService: ItemsService,
    private productsService: ProductsService,
    private roomTypesService: RoomTypesService
  ) {}

  // Return cached items-with-snags object for a room, or calculate if necessary.
  public getItemsWithSnags$(room: Document<Room>) {
    if (this.logger.enabled)
      console.log(
        ...this.logger.log("in getItemsWithSnag$", room.ref.id, this.itemsWithSnags$[room.ref.id])
      );

    if (!this.itemsWithSnags$[room.ref.id]) {
      const subject = (this.itemsWithSnags$[room.ref.id] = new ReplaySubject());
      this.calcItemsWithSnags$(room).subscribe(subject);
    }

    return this.itemsWithSnags$[room.ref.id];
  }

  public get(property: Document<Property>, queryFn?: QueryFn) {
    return property.collection<Room>("rooms", queryFn);
  }

  public async addToProperty(property: Document<Property>, roomCounts: {[id: string]: number}) {
    await batchAddMultiple(this.get(property).ref, [
      ...this.makeRooms(roomCounts),
      ...this.makeAutoRooms(),
    ]);
  }

  // Given a room document, produce an observable of its area.
  // Retrieve its "shape", and calcualte the area of that.
  public getArea$(room: Document<Room>) {
    return room.valueChanges().pipe(
      map(({shape}: Room) => shape),
      map(shape => (shape ? makePolygon(deserializeShape(shape)).area() : 0))
    );
  }

  // Find the inspection status of an individual room in observable form.
  // Optionally, specify a way to filter items to be included.
  private calcItemsWithSnags$(room: Document<Room>): Observable<Array<ItemWithSnag>> {
    const snags$ = afsCollectionToObjectOfData(room.collection<Snag>("snags"));
    const room$ = room.valueChanges();

    // TODO: figure out why lint thinks this is deprecated.
    // tslint:disable-next-line
    return combineLatest(room$, snags$).pipe(
      map(([r, snags]) => this.calcItemsWithSnags(r, snags))
    );
  }

  // create a combined object of items and snags for a room.
  // This is the "flat" version of hte calculation, invoked from `calcItemsWithSnags$` which operates on streams.
  public calcItemsWithSnags(room: Room, snags: Hash<Snag>): Array<ItemWithSnag> {
    if (!room) return [];

    const allItems = this.getItems(room);
    const isAvailable = availabilityChecker(room.upgraded);
    const items = filter(allItems, isAvailable);
    const itemsWithSnags: ItemWithSnag[] = mapToArray(items, (item, id) => ({
      id,
      item,
      snag: snags[id],
    }));

    return itemsWithSnags;
  }

  public calcPurchasableItems$(doc: Document<Room>) {
    return doc.valueChanges().pipe(map(room => this.calcPurchasableItems(room)));
  }

  public calcPurchasableItems(room: Room) {
    const roomType = this.getRoomType(room);
    const items = this.getItems(room);
    const isPurchasable = purchasabilityChecker(roomType.upgrade, room.upgraded);
    const purchasableItems = filter(items, isPurchasable);

    return purchasableItems;
  }

  // Find the items in a room (including non-available ones).
  public getItems(room: Room): Hash<Item> {
    return this.itemsService.getByRoomTypeId(room.roomTypeId);
  }

  public getRoomType(room: Room) {
    return this.roomTypesService.roomTypesO[room.roomTypeId];
  }

  public async checkAndUpdateInspectedOn(room: Document<Room>, inspectedOn: number) {
    const roomSnapshot = await room.ref.get();
    const roomData = roomSnapshot.data() as Room;
    if (!roomData.inspectedOn) {
      room.update({inspectedOn: inspectedOn});
    }
  }

  // Upgrade a bunch of rooms--maybe all of them, maybe all of a particular room type.
  // Welcome to Firestore.
  public async upgradeCollection(rooms: Collection<Room>) {
    for (const snapshot of (await rooms.ref.get()).docs) {
      await snapshot.ref.update(UPGRADED);
    }
  }

  // Downgrade a bunch of rooms--maybe all of them, maybe all of a particular room type.
  public async downgradeCollection(rooms: Collection<Room>) {
    for (const snapshot of (await rooms.ref.get()).docs) {
      await snapshot.ref.update(DOWNGRADED);
    }
  }

  // Upgrade a single room.
  public upgrade(room: Document<Room>) {
    return room.update(UPGRADED);
  }

  // Upgrade a single room.
  public downgrade(room: Document<Room>) {
    return room.update(DOWNGRADED);
  }

  // Buy something via the products service.
  public buy(upgrade: string) {
    this.productsService.buy(upgrade);
  }

  public getItemsCollection(room: Room) {
    const {upgraded, roomTypeId} = room;

    return this.itemsService.items(
      upgraded ? itemRoomTypeQuery(roomTypeId) : itemRoomTypeAndNotPremiumQuery(roomTypeId)
    );
  }

  // Sort two rooms.
  // For the time being, sort singletons AFTER regular rooms.
  public sort(r1: Room, r2: Room) {
    const roomType1 = this.getRoomType(r1);
    const roomType2 = this.getRoomType(r2);

    return (
      (roomType1.singleton ? 1 : 0) - (roomType2.singleton ? 1 : 0) ||
      r1.name.localeCompare(r2.name)
    );
  }

  ////////////////////////////////////////////////////////////////
  // UPGRADES AND DOWNGRADES

  // Upgrade all the rooms in a collection for which a particular upgrade applies.
  public async upgradeRooms(rooms: Collection<Room>, upgrade: string) {
    return this.changegradeRooms(rooms, upgrade, true);
  }

  // Upgrade all the rooms in a collection.
  public async upgradeAllRooms(rooms: Collection<Room>) {
    return this.changegradeAllRooms(rooms, true);
  }

  // Downgrade all the rooms in a collection for which a particular upgrade applies.
  public async downgradeRooms(rooms: Collection<Room>, upgrade: string) {
    return this.changegradeRooms(rooms, upgrade, false);
  }

  // Downgrade all the rooms in a collection.
  public async downgradeAllRooms(rooms: Collection<Room>) {
    return this.changegradeAllRooms(rooms, false);
  }

  // Internal routine to either upgrade or downgrade rooms that have a particular upgrade.
  // Do this in batched mode, which implies being online, but that's OK, right?
  private async changegradeRooms(rooms: Collection<Room>, upgrade: string, upgraded: boolean) {
    const roomTypeIds = Object.keys(this.roomTypesService.getByUpgrade(upgrade));
    const batch = rooms.ref.firestore.batch();

    const roomsSnapshot = await rooms.ref.get();

    for (const doc of roomsSnapshot.docs) {
      if (roomTypeIds.indexOf((doc.data() as Room).roomTypeId) >= 0)
        batch.update(doc.ref, {upgraded});
    }

    await batch.commit();
  }

  // Internal routine to either upgrade or downgrade all rooms in a room collection.
  private async changegradeAllRooms(rooms: Collection<Room>, upgraded: boolean) {
    return batchUpdateCollection(rooms.ref, {upgraded});
  }

  ////////////////////////////////////////////////////////////////
  // PRIVATE

  // Given a hash of count of rooms by room ID, make an array of rooms to add to the property.
  private makeRooms(roomCounts: Hash<number>) {
    const rooms: Room[] = [];
    const roomTypesO = this.roomTypesService.roomTypesO;

    for (const roomTypeId in roomCounts) {
      const count = roomCounts[roomTypeId];
      const roomType = roomTypesO[roomTypeId];
      const nameRoot = roomType.name;

      for (let i = 0; i < count; i++) {
        const name = count === 1 ? nameRoot : `${nameRoot} ${i + 1}`;
        rooms.push({roomTypeId, name} as Room);
      }
    }
    return rooms;
  }

  private makeAutoRooms() {
    return mapToArray(
      filter(this.roomTypesService.roomTypesO, ({auto}) => auto),
      ({name}, roomTypeId) => ({
        roomTypeId,
        name,
      })
    );
  }
}
