// blue/shapes/utils: Utilities related to "shapes"
//
// Serialize and deserialize shapes into the slat arrays used in the database.

import Flatten from "flatten-js";
import {flatten, partition, toRadians} from "@nims/jsutils";
import {Shape, Feature} from "./type";
import {deviceHeight} from "./device-height";

// Calculate eye position from the top of the head, based on known anatomical measurements.
function eyePosition() {
  // The average human is 7 1/2 heads tall (i.e., head isd 13.3% of height).
  // That would be 24cm for someone 180cm tall.
  const HEAD_HEIGHT = deviceHeight / 7.5;

  // Vertically speaking, eyes are almost exactly in the middle of the head.
  const EYES_Y_RATIO = 0.5;

  // From front to back, the size of the human head is approximately 7/8 of its height.
  // The eyes lie at 3/8 of head height from vertical center.
  const EYES_X_RATIO = 3 / 8;

  // Measured from the top of the head and the vertical center line, the eye position is thus:
  const EYE_X = HEAD_HEIGHT * EYES_X_RATIO;
  const EYE_Y = HEAD_HEIGHT * EYES_Y_RATIO;

  return [EYE_X, EYE_Y];
}

// A shape is stored as a FLAT array, each two points of which constitute one point.
export function deserializeShape(vals: number[]) {
  return partition(vals, 2) as [number, number][];
}

// Flatten the
export function serializeShape(shape: Shape) {
  return flatten(shape);
}

// The `svg` method of `Flatten.Polygon` gives us back the entire `<apth` element as a string.
// That's hard to work with.
// Just return the `d` attribute.
export function polygonSvgPath(polygon: Flatten.Polygon) {
  return Array.from(polygon.faces)
    .map(face => face.svg())
    .join("");
}

// The GeoJson format is an array of [x,y] tuples,
// with an extra layer of nesting to handle multiple "faces".
export function tuplesToGeoJson(points: [number, number][]) {
  return [[...points, points[0]]];
}

export function makePolygon(tuples: [number, number][]) {
  const pts = tuples.map(([x, y]) => new Flatten.Point(x, y));
  const polygon = new Flatten.Polygon();

  polygon.addFace(pts);

  return polygon;
}

////////////////////////////////////////////////////////////////
// CORNER COMPUTATION

// Given a beta (downward rotation of phone),
// calculate the distance from my feet to the point on the floor the camera is pointing at.
function betaToDistance(beta: number) {
  const [EYE_X, EYE_Y] = eyePosition();

  console.assert(!!deviceHeight);

  const deviceY = deviceHeight - EYE_Y;

  // Calculate the distance from where the user is standing to the corner he has pointed his phone at.
  const d = Math.tan(toRadians(beta)) * deviceY + EYE_X;

  return d;
}

// Given a rotation around z-axis, fine the point a given distance away from my feet.
function distanceAndAlphaToPoint(distance: number, alpha: number) {
  // Adjust the clockwise-ness of the rotation around the z-axis.
  const alphaRadians = toRadians(360 - alpha);

  // Given d and the angle, calculate the x/y position of the corner.
  const x = distance * Math.cos(alphaRadians);
  const y = distance * Math.sin(alphaRadians);

  return [x, y] as [number, number];
}

export function orientationToPoint(orientation: DeviceOrientationEvent) {
  const {alpha, beta} = orientation;
  console.assert(alpha !== null && beta !== null);

  return distanceAndAlphaToPoint(betaToDistance(beta), alpha);
}

// This implementation assumes that all features are corners.
export function XmakeShapeFromFeatures(features: Feature[]) {
  return [[0, 0], [0, 1000], [1000, 1000], [1000, 0]] as Shape;
}

export function makeShapeFromFeatures(features: Feature[]) {
  const shape: Shape = [];

  for (const {type, deviceOrientation} of features) {
    // Skip edges for the time being. Later, use them to align the walls.
    if (type === "edge") continue;

    console.assert(type === "corner");

    shape.push(orientationToPoint(deviceOrientation));
  }

  return shape;
}

// Adjust the shape to try to make right angles more right,
// using "edge" features on either side of the corner, if they exist.
// export function regularizeCorners(features: Feature[], i: number): [number, number] | undefined {
//   const feature = features[i];

//   const prevIndex = i ? i - 1 : features.length - 1;
//   const nextIndex = (i + 1) % features.length;
//   const prevFeature = features[prevIndex];
//   const nextFeature = features[nextIndex];

//   if (feature.type === "corner" && nextFeature.type === "edge" && prevFeature.type === "edge") {
//     const edge1XY = orientationToPoint(feature.deviceOrientation);
//     const edge2XY = orientationToPoint(nextNextFeature.deviceOrientation);

//     const edge1Point = new Flatten.Point(edge1XY[0], edge1XY[1]);
//     const edge2Point = new Flatten.Point(edge2XY[0], edge2XY[1]);

//     const edge1Vector = new Flatten.Vector(edge1Point[0], edge1Point[1]);
//     const edge2Vector = new Flatten.Vector(edge2Point[0], edge2Point[1]);

//     const edge1 = new Flatten.Line(edge1Point, edge1Vector.rotate(Math.PI / 2).normalize());
//     const edge2 = new Flatten.Line(edge2Point, edge2Vector.rotate(Math.PI / 2).normalize());

//     const [revisedPoint] = edge1.intersect(edge2);
//     return [revisedPoint.x, revisedPoint.y];
//   }
// }
