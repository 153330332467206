// blue/properties/edit/component.ts
//
// Edit property info, including name, address, and rooms.
// TODO: Add error handling, such as case where propety cannot be found.

const MODULE = ["properties", "edit"];

import {Component} from "@angular/core";
import {EventEmitter, Output, Input} from "@angular/core";
import {OnInit} from "@angular/core";
import {ActivatedRoute as Route, Router, Routes} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";

import {Observable} from "rxjs";
import {map, pluck} from "rxjs/operators";

import {
  AngularFirestore,
  AngularFirestoreCollection as Collection,
  AngularFirestoreDocument as Document,
} from "angularfire2/firestore";

import {environment} from "../../environments/environment";
import {Logger, LogModule} from "../../utils";
import {GenericDocumentComponent, spinner} from "../../utils";

import {Room} from "../../rooms/type";
import {Property, deleteProperty, getPropertyRooms} from "../type";
import {PropertiesService} from "../service";

////////////////////////////////////////////////////////////////
// EDIT PROPERTY ROUTE

@Component({
  selector: "property-edit-route",
  template: `
<ng-container *ngIf="doc$ | async as doc">
  <property-edit-document [doc]="doc"></property-edit-document>
</ng-container>
`,
})
@LogModule(...MODULE)
export class RouteComponent implements OnInit {
  public doc$: Observable<Document<Property>>;

  public logger: Logger;

  constructor(private route: Route, private service: PropertiesService) {}

  ngOnInit() {
    this.doc$ = this.route.params.pipe(
      pluck("propertyId"),
      map((id: string) => this.service.properties().doc(id))
    );

    this.logger.log$(this.doc$);
  }
}

////////////////////////////////////////////////////////////////
// Work with a property "document" which allows us to update it etc.
//
// Given a document Id, handed to us by `MyDocumentRouteComponent`,
// hand off emissions representing updates to that document to `PropertyComponent`.
// Provide ability to update and/or delete the property, which we can do since we have the document here.
//
// TODO: Add error handling, such as case where propety cannot be found.

@Component({
  selector: "property-edit-document",
  template: `
<ng-container *ngIf="data$ | async as data">
  <property-edit-display
    [data]="data"
    [rooms]="rooms"

    (update)="onUpdate($event)"
    (delete)="onDelete()"
    (done)="onDone()"
    (addRoom)="onAddRoom($event)"
  >
  </property-edit-display>
</ng-container>
`,
})
@LogModule(...MODULE)
export class DocumentComponent extends GenericDocumentComponent<Property> implements OnInit {
  public rooms: Collection<Room>;

  public logger: Logger;

  constructor(
    private afs: AngularFirestore,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private service: PropertiesService
  ) {
    super();
  }

  ngOnInit() {
    if (this.logger.enabled) console.log(...this.logger.log("entered ngOnInit"));

    super.ngOnInit();

    this.rooms = getPropertyRooms(this.doc, this.afs);
  }

  public async onUpdate(property: Property) {
    await super.onUpdate(property);
  }

  // Delete the property. This may or not be exposed depending on feature settings.
  public async onDelete() {
    try {
      await deleteProperty(this.doc, this.afs);
      this.matSnackBar.open("Property successfully deleted", "", {duration: 2000});
      this.router.navigate(["my-property"]);
    } catch (e) {
      this.matSnackBar.open("Sorry, could not delete property.", "", {duration: 2000});
    }
  }

  public async onDone() {
    this.router.navigate(["my-property"]);
  }

  public onAddRoom(id: string) {
    try {
      spinner("Adding room...", () => this.service.addRoom(this.doc, id));
    } catch (e) {
      this.matSnackBar.open("Oops, could not add room", "", {duration: 2000});
    }
  }
}

////////////////////////////////////////////////////////////////
// DISPLAY ACTUAL PROPERTY OBJECTs
@Component({
  selector: "property-edit-display",
  templateUrl: "component.html",
})
@LogModule(...MODULE)
export class DisplayComponent {
  private originalName: string;
  private originalAddress: string;

  @Input("data") property: Property;
  @Input() rooms: Collection<Room>;

  @Output() update = new EventEmitter<Partial<Property>>();
  @Output() delete = new EventEmitter<void>();
  @Output() done = new EventEmitter<void>();
  @Output() addRoom = new EventEmitter<string>();

  public CAN_DELETE_PROPERTY = environment.features && environment.features.deleteProperty;
  public logger: Logger;

  public async onDone() {
    if (this.logger.enabled) console.log(...this.logger.log("onDone"));
    this.done.emit();
  }

  public async onDelete() {
    if (!this.CAN_DELETE_PROPERTY) return;

    if (this.logger.enabled) console.log(...this.logger.log("onDelete"));

    this.delete.emit();
  }

  public onAddRoom(id: string) {
    this.addRoom.emit(id);
  }

  public onFocusName(name: string) {
    // Store the name at the point the user visits this field.
    if (!this.originalName) {
      this.originalName = name;
    }
  }

  public onFocusAddress(address: string) {
    // Store the address at the point the user visits this field.
    if (!this.originalAddress) {
      this.originalAddress = address;
    }
  }

  public onChangeName(name: string) {
    name = name.trim();

    if (name) this.update.emit({name});
    else this.property.name = this.originalName;
  }

  public onChangeAddress(address: string) {
    address = address.trim();

    if (address) this.update.emit({address});
    else this.property.address = this.originalAddress;
  }
}

// There is no route compoment at the moment.
// The my-property/edit route directs to the document route.
export const EDIT_DECLARATIONS = [DocumentComponent, DisplayComponent, RouteComponent];
export const EDIT_ROUTES: Routes = [
  {path: "edit/:propertyId", component: RouteComponent, data: {screenName: "property-edit"}},
];
