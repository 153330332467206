// The dSnag! logo.
//
// Size is controlled from outside.
// Hey, we are not responsible for Fjalla One actually being available.
// In a desktop app, perhaps it is brought in via an `@import` statement in a CSS file from Google.
// In a Cordova app, pehraps it is brought in from `assets/fonts`.
//
// Expose a static variable for the magic color.

import {Component, Input, ViewEncapsulation} from "@angular/core";

@Component({
  selector: "d-snag",
  templateUrl: "component.html",
  styleUrls: ["component.css"],

  // Make these styles global, mostly so we can use the `.dsnag-color` class elsewhere.
  // Note: for this reason, the classes are "namespaced" with `dsnag-`.
  encapsulation: ViewEncapsulation.None,
})
export class DSnagComponent {
  static color = "#00b0e0";

  @Input() premium = false;
}
