// providers/is-ready.guard.ts
//
// Check to make sure that things like the database, and Cordova, are ready.

import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate} from "@angular/router";
import {deviceReady} from "@nims/cordova";

import {Service as ItemsService} from "../items/service";
import {Service as RoomTypesService} from "../room-types/service";

@Injectable()
export class IsReadyGuard implements CanActivate {
  constructor(
    private readonly itemsService: ItemsService,
    private readonly roomTypesService: RoomTypesService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    await Promise.all([this.roomTypesService.ready, this.itemsService.ready, deviceReady]);

    return true;
  }
}
