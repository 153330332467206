// blue/shares/screen/component.ts: component for sharing
//
// Provide a route and a routable component,
// which just passes directly through to the display component.

const MODULE = ["shares", "screen"];

import {Component} from "@angular/core";
import {Routes} from "@angular/router";

import {AngularFirestoreDocument as Document} from "angularfire2/firestore";

import {Service as ProductsService} from "../../products/service";
import {Service as MyPropertyService} from "../../my-property/service";
import {Property} from "../../properties/type";
import {Logger, LogModule} from "../../utils";

import {Service} from "../service";

////////////////////////////////////////////////////////////////
// ROUTED COMPONENT
@Component({
  selector: "share-route",
  template: `<share-display></share-display>`,
})
@LogModule(...MODULE)
export class RouteComponent {}

////////////////////////////////////////////////////////////////
// DISPLAY SHARING SCREEN
@Component({
  selector: "share-display",
  templateUrl: "component.html",
  styleUrls: ["component.css"],
})
@LogModule(...MODULE)
export class DisplayComponent {
  public myProperty$ = this.myPropertyService.document$;

  public logger: Logger;

  constructor(
    private myPropertyService: MyPropertyService,
    public products: ProductsService,
    private service: Service
  ) {}

  public async shareReport(property: Document<Property>) {
    await this.service.shareReport(property);
  }

  public async shareApp() {
    await this.service.shareApp();
  }
}

export const SCREEN_DECLARATIONS = [RouteComponent, DisplayComponent];
export const SCREEN_ROUTES: Routes = [
  {path: "share", component: RouteComponent, data: {screenName: "share"}},
];
