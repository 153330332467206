// blue/rooms/report-status/component.ts
//
// Components for a room status display on a report.

import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

import {AngularFirestore} from "angularfire2/firestore";

import {GenericCollectionComponent, GenericDocumentComponent, LogModule} from "../../utils";
import {Status} from "../../status/";

import {Room} from "../type";

const MODULE = ["rooms", "report-status"];

////////////////////////////////////////////////////////////////
// ROOM STATUS FOR REPORT
//
// Based on the Firestore document for a room, display it by using the "display" component.
// Pass through document-based information such as status as transcluded content.
// Retrieve the status information from the cached info on the rooms service.
@Component({
  selector: "room-report-status-document",
  template: `
<ng-container *ngIf="data$ | async as data">
  <room-report-status-display [data]="data" (jump)="onJump()">
    <room-status-bar [room]="doc" [showNumerals]="true" [showDescription]="false" [dynamicDescription]="false"></room-status-bar>
  </room-report-status-display>
</ng-container>
`,
})
@LogModule(...MODULE)
export class DocumentComponent extends GenericDocumentComponent<Room> {
  @Output() jump = new EventEmitter<string>();

  // If the display component wants to jump, report this upwards, using the room ID.
  public onJump() {
    this.jump.emit(this.doc.ref.id);
  }
}

////////////////////////////////////////////////////////////////
// ROOM STATUS DISPLAY
@Component({
  selector: "room-report-status-display",
  templateUrl: "component.html",
})
@LogModule(...MODULE)
export class DisplayComponent {
  @Input("data") room: Room;

  // Is this needed any more?
  @Input() status: Status;

  // Advise the caller (`room-report-status-document`) of the jump request.
  @Output() jump = new EventEmitter<void>();

  public onJump() {
    this.jump.emit();
  }
}

////////////////////////////////////////////////////////////////
// ROOM COLLECTION OF SUMMARIES
//
// Generate an entry for each element in the collection.
@Component({
  selector: "room-report-status-collection",
  templateUrl: "collection.html",
})
@LogModule(...MODULE)
export class CollectionComponent extends GenericCollectionComponent<Room> implements OnInit {
  @Output() jump = new EventEmitter<string>();

  constructor(afs: AngularFirestore) {
    super(afs);
  }

  // Pass along a jump request to whoever called us.
  public onJump(roomId: string) {
    this.jump.emit(roomId);
  }
}

export const DECLARATIONS = [CollectionComponent, DisplayComponent, DocumentComponent];
