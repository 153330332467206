// blue/components/ui.service.ts

// The UI service, for UI related things shared across the app, such as the sidenav.
// This captures the hardware back button,
// and may pass it along via a `backbutton` observable it exposes.

import {Injectable} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {Subject} from "rxjs";
import {filter} from "rxjs/operators";
import {backbutton} from "@nims/cordova";

@Injectable()
export class Service {
  // Expose an observable to drive opening of the sidenav.
  // This is listened to by the app component, and set by the hamburger component.
  public openSidenav$ = new Subject<boolean>();

  // Create an observable for hardware backbutton, filtering out the case where the sidenav was open.
  public backbutton = backbutton.pipe(
    filter(() => {
      if (!this.sidenavOpened) return true;
      this.openSidenav(false);
    })
  );

  private sidenavOpened = false;

  constructor(router: Router) {
    router.events.subscribe(val => {
      if (val instanceof NavigationEnd) this.openSidenav(false);
    });
  }

  // This is called by the hamburger component,
  // and listened to by the app component.
  public openSidenav(bool = true) {
    this.openSidenav$.next(bool);
  }

  public setSidenavOpened(b: boolean) {
    this.sidenavOpened = b;
  }
}
