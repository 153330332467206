// Constants for use throughout the app.
// Should these be in `environments`, or one level higher in `src`?

// The URL for the e-commerce site where people can buy things off-line.
// THis is displayed in an in-app browser.
export const STORE_URL = "https://www.dsnag.in";

// In the premium version, all packages are assumed to be available with no further ado.
// The user is not prompted, and in fact there are not even any purchasing screesn available.
// This value is set to `true` in ANOTHER BRANCH (the "premium-dsnag" branch,
// along with other premium version-related changes, such as the app package name.
export const IS_PREMIUM_VERSION = false;

export const CAN_DELETE_PROPERTY = false;

export const GOOGLE_API_CLIENT_ID =
  "591836527152-r48ir0koq37o4vebi9idt9ds5oalbsf4.apps.googleusercontent.com";

export const VIDEO_ID = "gtp2_nZakns";

// NOT USED
export const HOCKEYAPP_APP_ID = "a583c8bb88a348bc8bda987a968bb8b6";

// This is the same as the Material Design primary color (I think).
// How to sync it?
export const APP_COLOR = "#03a9f4";

// PRIVACY POLICY and TOS.
// TODO: Fix TOS to point to actual TOS page.
export const PRIVACY_POLICY_URL = "https://dsnag.in/pages/privacy-policy";
export const TOS_URL = "https://dsnag.in/pages/terms";
