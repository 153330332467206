// providers/has-property.guard.ts
//
// Check to make sure that user has a property.
// If not, we bounce him to the property creation screen.
// This guard applies to the entire `inspecting` group of routes.

import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {map, tap} from "rxjs/operators";
import {CanActivate} from "@angular/router";

import {Service} from "./service";

const MODULE_NAME = "guards/has-property.guard";
const LOG = true;

@Injectable()
export class HasPropertyGuard implements CanActivate {
  constructor(private readonly service: Service, private readonly router: Router) {}

  canActivate() {
    // Should this be something derived from UserService instead?
    return this.service.document$.pipe(
      tap(document => {
        if (LOG) console.log(`${MODULE_NAME}`, {document});
      }),
      map(document => !!document),
      tap(hasProperty => {
        if (!hasProperty) this.router.navigate(["/property", "new"]);
      })
    );
  }
}
