// blue/app/utils/document.component.ts
//
// Superclass for component which displays a single item based on its Firestore document,
// and also knows how to create, delete, and update itself.

// Normally, the component written based on this superclass would have a template that looked like this:
//
// ```
// <display-data [data]="data$ | async" (create)="onCreate($event)" (update)="onUpdate($event)" (delete)="onDelete()">
// ```

import {Input, OnInit, OnDestroy} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {AngularFirestoreDocument as Document} from "angularfire2/firestore";
import {Logger} from "@nims/jsutils";

export class GenericDocumentComponent<Data> implements OnInit, OnDestroy {
  // An AngularFirestore document for this item.
  @Input() public doc: Document<Data>;

  // Force subclasses to implement this.
  public logger: Logger;

  // Observable of the item itself.
  public data$: Observable<Data | null>;
  public dataP: Promise<Data>;

  // A flattened version, for your convenience. Could be null!
  public data: Data;

  private _subscription: Subscription;

  // DO NOT FORGET TO CALL `super.ngOnInit` FROM DERIVED CLASSES!!
  ngOnInit() {
    // A stream of data objects.
    this.data$ = this.doc.valueChanges();

    // A promise for whether there is such a document at all.
    this.dataP = this.getDataP();

    this.logger.log$(this.data$);

    this._subscription = this.data$.subscribe(data => (this.data = data));
  }

  ngOnDestroy() {
    if (this._subscription) this._subscription.unsubscribe();
  }

  // EVENT HANDLERS
  // Manipulate the AngularFirestore document in question.
  public onUpdate(data: Partial<Data>) {
    return this.doc.update(data);
  }
  public onDelete() {
    return this.doc.delete();
  }
  public onSet(data: Data) {
    try {
      return this.doc.set(data);
    } catch (e) {
      console.error(...this.logger.log(e));
    }
  }

  // PRIVATE
  private async getDataP() {
    const snapshot = await this.doc.ref.get();
    return snapshot.data() as Data;
  }
}
