// blue/snags/inspect
//
// Component Logic for handling snags.

import {Component} from "@angular/core";
import {Input, Output, EventEmitter} from "@angular/core";
import {OnInit} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";

import {AngularFirestoreDocument as Document} from "angularfire2/firestore";

import {NOTSNAG_COLOR, SNAG_COLOR, Snag} from "../type";
import {Photo, PhotoHighlight, clonePhoto} from "../../photos";

import {getPhoto} from "../../photos";
import {Logger, LogModule} from "../../utils";

const MODULE = ["snags"];

////////////////////////////////////////////////////////////////
// HANDLE SNAG DOCUMENT DESTINED FOR DISPLAY IN ITEM ENTRY
@Component({
  selector: "snag-inspect-document",
  template: `
<snag-inspect-display
  [snag]="snag"
  [noPhoto]="noPhoto"

  (delete)="onDelete()"
  (update)="onUpdate($event)"
  (set)="onSet($event)"

  (setActive)="onSetActive($event)"
  (startInspect)="onStartInspect($event)"
  >
</snag-inspect-display>
`,
})
@LogModule(...MODULE)
export class DocumentComponent {
  // In this particular case, we already have the snag, because we retrieve it long ago at the room level.
  // Hence, we don't follow the normal protocol of using the `GenericDocumentComponent` to stream it for us.
  @Input() snag: Snag;

  @Input() doc: Document<Snag>;

  // Flag on item; pass along to display component.
  @Input() noPhoto: boolean;

  @Output() setActive = new EventEmitter<boolean>();
  @Output() startInspect = new EventEmitter<number>();

  public logger: Logger;

  public onSetActive(b: boolean) {
    this.setActive.emit(b);
  }

  public onStartInspect(inspectedOn: number) {
    this.startInspect.emit(inspectedOn);
  }

  public onUpdate(data: Partial<Snag>) {
    return this.doc.update(data);
  }
  public onDelete() {
    return this.doc.delete();
  }
  public onSet(data: Snag) {
    try {
      console.log(data);
      return this.doc.set(data);
    } catch (e) {
      console.error(...this.logger.log(e));
    }
  }
}

////////////////////////////////////////////////////////////////
// DISPLAY SNAG
//
// When the snag goes into "editing" mode, issue an event so the parent (and siblings) know about it.
@Component({
  selector: "snag-inspect-display",
  templateUrl: "component.html",
  styles: [
    `mat-card-actions { margin-bottom: 0; }`,
    `.mat-menu-panel { background-color: wheat; }`,
    `mat-card-content {margin-bottom: 0; }`,
  ],
})
@LogModule(...MODULE)
export class DisplayComponent implements OnInit {
  // Snag to display.
  @Input() snag: Snag;

  // This comes by way of the item, on which it is a flag.
  @Input() noPhoto = false;

  @Output() delete = new EventEmitter<void>();
  @Output() update = new EventEmitter<Partial<Snag>>();
  @Output() set = new EventEmitter<Snag>();
  @Output() setActive = new EventEmitter<boolean>();
  @Output() startInspect = new EventEmitter<number>();

  // Remember whether or not we are editing.
  // Let the parent know, so it can disable other items while editing this one.
  public get editing() {
    return this._editing;
  }
  public set editing(v) {
    this._editing = v;
    this.setActive.emit(v);
  }

  // Local version of snag variables.
  public photo: Photo;
  public isSnag: boolean;
  public description: string;
  public createdOn: number;

  public SNAG_COLOR = SNAG_COLOR;
  public NOTSNAG_COLOR = NOTSNAG_COLOR;

  public logger: Logger;

  private _editing: boolean;

  constructor(private matSnackBar: MatSnackBar) {}

  ngOnInit() {
    if (this.logger.enabled) console.log(...this.logger.log({snag: this.snag}));
  }

  public onCancelEditing() {
    this.editing = false;

    this.description = this.snag ? this.snag.description : null;
    this.isSnag = this.snag ? this.snag.snag : null;
  }

  public onEdit() {
    this.editing = true;

    this.photo = clonePhoto(this.snag.photo);
    this.description = this.snag.description;
    this.isSnag = this.snag.snag;
  }

  public onSubmit() {
    this.checkAndCreateCreatedOnTime();
    const {isSnag: snag, photo, description, createdOn} = this;
    if (!description) return;

    this.editing = false;
    if (this.createdOn) this.set.emit({snag, photo, description, createdOn})
    else this.set.emit({snag, photo, description});
  }

  // To "uninspect" is to delete the snag.
  public onUninspect() {
    this.delete.emit();
  }

  public onOk() {
    this.checkAndCreateCreatedOnTime();
    if (this.createdOn) this.set.emit({snag: false, createdOn: this.createdOn});
    else this.set.emit({snag: false});
  }

  public async onNotOk() {
    await this.take();
    this.isSnag = true;
    this.editing = true;
  }

  public onChangeHighlight(highlight: PhotoHighlight) {
    if (this.photo) this.photo.highlight = highlight;
  }

  public async onReshoot() {
    await this.take();
  }

  private checkAndCreateCreatedOnTime() {
    // If snag is being created for the first time, update 'createdOn' field
    if (!this.snag) {
      this.createdOn = new Date().getTime();
      this.startInspect.emit(this.createdOn);
    } else { // If snag already has 'createdOn' field, retain the same value.
      if (this.snag.createdOn) this.createdOn = this.snag.createdOn;
    }
  }

  // Internal routine to take a picture and set on the snag.
  // Throws if the capture failed or was cancelled.
  private async take() {
    try {
      this.photo = this.noPhoto ? null : await getPhoto();
    } catch (e) {
      // This is the "error" if the user simply cancels the taking of the picture.
      if (!/no image selected/i.test(e)) {
        return this.matSnackBar.open(`Could not take picture, sorry. (${JSON.stringify(e)})`, "", {
          duration: 2000,
        });
      }
      throw e;
    }
  }
}

export const INSPECT_DECLARATIONS = [DocumentComponent, DisplayComponent];
