// Logger for blue app.
//
// Usage:
// ```
//
// ```

import {Logger} from "@nims/jsutils";
import {environment} from "../environments/environment";

const moduleOptions: {
  [module: string]: {
    enabled: boolean;
    templateEnabled: boolean;
    style: Partial<CSSStyleDeclaration>;
  };
} = {
  default: {
    enabled: true,
    style: {background: "green", color: "white"},
    templateEnabled: false,
  },
  properties: {
    enabled: true,
    style: {background: "green", color: "white"},
    templateEnabled: false,
  },
  items: {
    enabled: true,
    style: {background: "blue", color: "white"},
    templateEnabled: false,
  },
  snags: {
    enabled: true,
    style: {background: "orange", color: "white"},
    templateEnabled: false,
  },
  rooms: {
    enabled: true,
    style: {background: "red", color: "white"},
    templateEnabled: true,
  },
  products: {
    enabled: true,
    style: {background: "purple", color: "white"},
    templateEnabled: false,
  },
  providers: {
    enabled: true,
    style: {background: "yellow", color: "black"},
    templateEnabled: false,
  },
  help: {
    enabled: true,
    style: {background: "cyan", color: "black"},
    templateEnabled: false,
  },
  shares: {
    enabled: true,
    style: {background: "cyan", color: "black"},
    templateEnabled: false,
  },
};

// Returns a `Logger` function, which can be called, but also has an `enabled` property.
export function makeLogger(name: string[]) {
  const options = moduleOptions[name[0]] || moduleOptions.default;
  const log = environment.debug && environment.debug.log;

  if (!options) throw new Error("Missing logger options for " + name[0]);

  return new Logger(name, log && options.enabled, log && options.templateEnabled, options.style);
}
