// blue/console/component.ts
//
// TS logic for blue console main component.

import {Component} from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "component.html",
  styleUrls: ["component.css"],
})
export class AppComponent {}
