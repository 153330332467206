// blue/providers/index.ts
//
// Providers barrel.
// Note: do not import services into other services using this barrel.
// That will result in circular dedendencies.
// Instead, import services one-by-one from their own module.

import {CordovaGuard} from "./cordova.guard";
import {CustomUrlSchemeService} from "./custom-url-scheme.service";
import {FirebaseAnalyticsService} from "./firebase-analytics.service";
import {IsReadyGuard} from "./is-ready.guard";
import {NavigationService} from "./navigation.service";

export const PROVIDERS = [
  CordovaGuard,
  CustomUrlSchemeService,
  FirebaseAnalyticsService,
  IsReadyGuard,
  NavigationService,
];

export {
  CordovaGuard,
  CustomUrlSchemeService,
  FirebaseAnalyticsService,
  IsReadyGuard,
  NavigationService,
};
