// blue/room-types/type.ts
//
// Definition of "room type", the mechanism by which items are associated with rooms.

// import {Metadata} from "./metadata";

export interface RoomType {
  name: string;

  // This is not a regular "room", of which there can be many instances in a property.
  // Instead, it is a kind of bucket for items relating the entire proprerty.
  // Such rooms are omitted from the property definition screen.
  // Nor are they present in the dropdown for room type when adding rooms from the room list screen.
  singleton: boolean;

  // This room has upgradable items, the Google Play package name for which is given by this ID.
  upgrade: string;

  // This room will be automatically populated into any new property.
  // It is very likely also a singleton.
  auto: boolean;

  //   metadata: Metadata;
}

export function isSingleton(roomType: RoomType) {
  return roomType.singleton;
}
