// blue/app/help
//
// Module for help-related stuff.

import {Component} from "@angular/core";
import {Location} from "@angular/common";
import {Routes} from "@angular/router";

import {Logger, LogModule} from "../utils";
import {VIDEO_ID} from "../environments/constants";
import {environment} from "../environments/environment";

const MODULE = ["help"];

@Component({
  selector: "help-route",
  template: `<help-display></help-display>`,
})
@LogModule(...MODULE)
export class RouteComponent {
  public logger: Logger;
}

@Component({
  selector: "help-display",
  templateUrl: "component.html",
  styleUrls: ["component.css"],
})
@LogModule(...MODULE)
export class DisplayComponent {
  public linkToVideo = environment.features.linkToVideo;

  public logger: Logger;

  constructor(private location: Location) {}

  public onGoBack() {
    this.location.back();
  }

  // Show the tutorial video inside the app, using Android's native video player,
  // invokved through the cordova-plugin-youtube-video-player plugin.
  public showVideo() {
    const player = (window as any).YoutubeVideoPlayer;

    if (!player) throw new Error("Could not find YoutubeVideoPlayer");

    player.openVideo(VIDEO_ID, result =>
      console.log(...this.logger.log("YoutubeVideoPlayer result", result))
    );

    // Someone else suggested donig this:
    // cordova.InAppBrowser.open('vnd.youtube:yI2oS2hoL0k', '_system');
  }
}

export const HELP_ROUTES: Routes = [
  {path: "help", component: RouteComponent, data: {screenName: "help"}},
];

export const HELP_DECLARATIONS = [RouteComponent, DisplayComponent];
