// blue/providers/firebase-analytics.service.ts: service for handling Firebase analytics
//
// Watch the current user, and current route, and report to Firebase analytics.
// Provide an interface for reporting custom events to Firebase analytics as well.
// This entire feature can be controlled by the environment property `enableFireabseAnalystics`.

import {Injectable} from "@angular/core";
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {filter, map, mergeMap} from "rxjs/operators";
import {AngularFireAuth} from "angularfire2/auth";
import {LogModule, Logger} from "../utils";
import {environment} from "../environments/environment";

// Shape of the Firebase analytics API.
// Replace this with actual typings whenever they become available.
interface FirebaseAnalytics {
  logEvent: (name: string, params: object) => void;
  setUserId: (user: string) => void;
  setUserProperty: (property: string, value: string) => void;
  setCurrentScreen: (name: string) => void;
  setEnabled: (enabled: boolean) => void;
  resetAnalyticsData: () => void;
}

const enable = environment.enableFirebaseAnalytics;

// Retrieve the Firebase analytics object in a safe way.
function get() {
  const cordova = (window as any).cordova;
  const plugins: any = cordova && cordova.plugins;
  const firebase = plugins && plugins.firebase;
  const analytics: FirebaseAnalytics = firebase && firebase.analytics;

  return analytics;
}

@LogModule("firebase-analytics")
@Injectable()
export class FirebaseAnalyticsService {
  private logger: Logger;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly auth: AngularFireAuth
  ) {
    if (enable) {
      this.watchAuth();
      this.watchRouter();
    }
  }

  // Report an event to Firebase Analytics.
  // This will be invoked from the `firebaseAnalyticsLogEvent` directive, for example.
  public logEvent(name: string, params: any = {}) {
    if (!enable) return;

    const fba = get();

    if (fba) fba.logEvent(name, params);
    if (this.logger.enabled) console.log(...this.logger.log("Logging FBA event", name));
  }

  // Watch the current user, and advise Firebase Analytics of their ID, name, etc.
  private watchAuth() {
    this.auth.authState.subscribe(user => {
      if (user) {
        const fba = get();

        if (fba) {
          fba.setUserId(user.uid);
          fba.setUserProperty("email", user.email);
          fba.setUserProperty("displayName", user.displayName);
        }

        if (this.logger.enabled)
          console.log(...this.logger.log("Setting FBA user", user.displayName));
      }
    });
  }

  // Watch the current route, and advise Firebase Analytics of its screen name,
  // which is provided as the `screenName` property of the `data` property of the route.
  private watchRouter() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === "primary"),
        mergeMap(route => route.data)
      )
      .subscribe(data => {
        const fba = get();

        if (data) {
          const screenName = data["screenName"];

          if (screenName) {
            if (fba) fba.setCurrentScreen(screenName);

            if (this.logger.enabled)
              console.log(...this.logger.log("Logging FBA screen name", screenName));
          }
        }
      });
  }
}
