// blue/components/premium-app/component.ts
//
// TS logic for component to display pitch for buying premium app.
// with link to Google Play.

import {Component} from "@angular/core";
import {openMarket} from "@nims/cordova";

@Component({
  selector: "premium-app",
  templateUrl: "component.html",
  styleUrls: ["component.css"],
})
export class PremiumApp {
  // TODO: consider if we want to persist the hidden status of the offline upsell box.
  public hide = false;

  // Jump to market listing for premium version.
  public onPremium() {
    openMarket("in.nemmadi.d2snag");
  }
}
