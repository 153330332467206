// blue/app/services/custom-url-scheme.service.ts
//
// Service to handle links from `dsnag://` URLs.
// Expose an observable.
// Currently, that observable is consumed in `app.component`.

import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

// const LOG = false;
// const MODULE_NAME = "services/custom-url-scheme.service";

@Injectable()
export class CustomUrlSchemeService {
  public url: Subject<string>;

  constructor() {
    // Maybe the `handleOpenURL` in `index.html` has already been called.
    // In that case, populate the observable with it.
    if ((window as any).handleOpenUrl_url) this.url.next((window as any).handleOpenUrl_url);

    // Replace the previous value of `handleOpenURL` set in `index.html`.
    // We are encoouraged to wrap this in a timeout.
    // Does it matter if all we are doing is sticking a value onto an observable?
    (window as any).handleOpenURL = (url: string) => setTimeout(() => this.url.next(url), 100);
  }
}
