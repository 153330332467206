// blue/rooms/status/component.ts: components for displaying romo status in various forms.

import {Component} from "@angular/core";
import {Input} from "@angular/core";
import {OnInit} from "@angular/core";

import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import {AngularFirestoreDocument as Document} from "angularfire2/firestore";

import {Logger, LogModule} from "../../utils";
import {Status, calcStatus} from "../../status";

import {Room} from "../type";
import {Service} from "../service";

const MODULE = ["rooms", "status"];

////////////////////////////////////////////////////////////////
// SUPERCLASS
@LogModule(...MODULE)
// Angular seems to feel strongly that we need the "export" here...
export class StatusDisplay implements OnInit {
  @Input() room: Document<Room>;

  public status$: Observable<Status>;
  public logger: Logger;

  constructor(private service: Service) {}

  ngOnInit() {
    this.status$ = this.service
      .getItemsWithSnags$(this.room)
      .pipe(map(itemsWithSnags => calcStatus(itemsWithSnags)));
    this.logger.log$(this.status$);
  }
}

////////////////////////////////////////////////////////////////
// ROOM STATUS AS BAR
//
// Does this really belong here?
@Component({
  selector: "room-status-bar",
  template: `
<ng-container *ngIf="status$ | async as status">
  <status-bar
    [status]="status"
    [showNumerals]="showNumerals"
    [showDescription]="showDescription"
    [dynamicDescription]="dynamicDescription">
  </status-bar>
</ng-container>
`,
})
export class Bar extends StatusDisplay {

  @Input() showNumerals = true;
  @Input() showDescription = true;
  @Input() dynamicDescription = true;
  constructor(service: Service) {
    super(service);
  }
}

////////////////////////////////////////////////////////////////
// ROOM STATUS AS PIE
@Component({
  selector: "room-status-pie",
  template: `
<ng-container *ngIf="status$ | async as status">
  <status-pie [status]="status"></status-pie>
</ng-container>
`,
})
export class Pie extends StatusDisplay {
  constructor(service: Service) {
    super(service);
  }
}

export const STATUS_DECLARATIONS = [Bar, Pie];
