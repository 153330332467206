// blue/snags/report
//
// Component Logic for reporting snags.

const MODULE = ["snags", "report"];

import {Component, Input} from "@angular/core";
import {GenericDocumentComponent, Logger, LogModule} from "../../utils";
import {Snag} from "../type";

////////////////////////////////////////////////////////////////
// HANDLE SNAG DOCUMENT DESTINED FOR DISPLAY IN ITEM ENTRY
@Component({
  selector: "snag-report-document",
  template: `
<ng-container *ngIf="data$ | async as data">
  <snag-report-display
    [data]="data">
  </snag-report-display>
</ng-container>
`,
})
@LogModule(...MODULE)
export class DocumentComponent extends GenericDocumentComponent<Snag> {}

////////////////////////////////////////////////////////////////
// DISPLAY SNAG

@Component({
  selector: "snag-report-display",
  templateUrl: "component.html",
})
@LogModule(...MODULE)
export class DisplayComponent {
  @Input("data") snag: Snag;

  public logger: Logger;
}

export const REPORT_DECLARATIONS = [DocumentComponent, DisplayComponent];
