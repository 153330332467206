// blue/my-property/service.ts
//
// Provide an observable of the current user's property.

import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {map, switchMap} from "rxjs/operators";

import {
  AngularFirestore,
  AngularFirestoreCollection as Collection,
  AngularFirestoreDocument as Document,
} from "angularfire2/firestore";

import {Property, deleteProperty} from "../properties/type";

import {PropertiesService, ownerQuery} from "../properties/service";
import {Service as ProductsService} from "../products/service";
import {Service as UserService} from "../users/service";

import {Logger, LogModule} from "../utils";

type Maybe<T> = T | null;

////////////////////////////////////////////////////////////////
// LOCAL ROUTINES

// Given a collection, return an observable for the first document in the collection.
function firstDocument$(
  collection: Collection<Property>,
  afs: AngularFirestore
): Observable<Document<Property> | null> {
  return collection.snapshotChanges().pipe(
    map(actions => actions[0]),
    map(action => (action ? new Document<Property>(action.payload.doc.ref, afs) : null))
  );
}

////////////////////////////////////////////////////////////////
// SERVICE
@Injectable()
@LogModule("my-property")
export class Service {
  // Expose an observable of an `AngularFirestoreDocument` for my property.
  public document$: Observable<Document<Maybe<Property>>>;

  // A static (flattened) version of the proeprty document.
  public document: Maybe<Document<Property>>;

  public property: Property;

  public logger: Logger;

  private _property$: Observable<Property>;
  private initialized = false;

  constructor(
    private afs: AngularFirestore,
    private productsService: ProductsService,
    private propertiesService: PropertiesService,
    private userService: UserService
  ) {}

  public get property$() {
    this.init();
    return this._property$;
  }

  public init() {
    if (this.initialized) return;
    this.initialized = true;

    this.userService.init();

    const uid$ = this.userService.uid$;

    // Create An observable of a eollection of properties with me as owner.
    const collection$: Observable<Maybe<Collection<Property>>> = uid$.pipe(
      map(uid => (uid ? this.propertiesService.properties(ownerQuery(uid)) : null))
    );

    // An observable of documents for my (first) property.
    this.document$ = collection$.pipe(
      switchMap(
        propertyCollection =>
          !propertyCollection ? of(null) : firstDocument$(propertyCollection, this.afs)
      )
    );

    this.document$.subscribe(document => (this.document = document));

    // Remember this document in flat form.
    // But can we dependent on it being there in time?
    this._property$ = this.document$.pipe(switchMap(doc => (doc ? doc.valueChanges() : of(null))));

    this.property$.subscribe(property => (this.property = property));

    this.watchProducts();

    this.logger.log$(this.document$);
  }

  // Reset the property.
  // TODO: implement this.
  public reset() {
    return deleteProperty(this.document, this.afs);
  }

  ////////////////////////////////////////////////////////////////
  // PRIVATE METHODS

  // Watch the store. If a product is "approved", then update the property to reflect that,
  // and mark the purchase as "finished".
  private watchProducts() {
    const {purchases$, unpurchases$} = this.productsService;

    purchases$.subscribe(async productId => {
      console.log(...this.logger.log("Purchases product", productId));

      if (productId === "full_checks") await this.propertiesService.upgradeAll(this.document);
      else await this.propertiesService.upgrade(this.document, productId);

      if (this.logger.enabled)
        console.log(...this.logger.log("completed updating property with product", productId));
    });

    unpurchases$.subscribe(async productId => {
      console.log(...this.logger.log("Unpurchases product", productId));

      if (productId === "full_checks") await this.propertiesService.downgradeAll(this.document);
      else await this.propertiesService.downgrade(this.document, productId);

      if (this.logger.enabled)
        console.log(...this.logger.log("completed downgrading property with product", productId));
    });
  }
}
