// blue/items/report/component.ts
//
// Component for reporting items.

const MODULE = ["items", "report"];

import {Component} from "@angular/core";
import {Input} from "@angular/core";
import {OnInit} from "@angular/core";
import {OnChanges, SimpleChanges} from "@angular/core";

import {Snag} from "../../snags/type";
import {Logger, LogModule} from "../../utils";

import {Item, ItemWithSnag, parseItemName, sortItems} from "../type";

////////////////////////////////////////////////////////////////
// HANDLE LIST OF ITEMS WITH SNAGS, KEYED BY ID, FOR REPORTING
//
// Responsible for sorting (and filtering) item list.
// Then call component to handle each item document.

@Component({
  selector: "item-report-collection",
  template: `
<div spaced tight before>
  <ng-container *ngFor="let itemWithSnag of sortedItemsWithSnags">
    <item-report-display [id]="itemWithSnag.id" [item]="itemWithSnag.item" [snag]="itemWithSnag.snag">
    </item-report-display>
  </ng-container>
</div>
`,
})
@LogModule(...MODULE)
export class CollectionComponent {
  @Input() itemsWithSnags: ItemWithSnag[];

  public sortedItemsWithSnags: ItemWithSnag[];

  public logger: Logger;

  ngOnChanges() {
    const snaggedItems = this.itemsWithSnags.filter(({snag}) => snag && snag.snag);

    this.sortedItemsWithSnags = snaggedItems.sort(
      ({item: item1}: ItemWithSnag, {item: item2}: ItemWithSnag) => sortItems(item1, item2)
    );
  }
}

////////////////////////////////////////////////////////////////
// DISPLAY AN ITEM ON A CARD
//
// Also take in the snag for this item from the snags collection on the room.
@Component({
  selector: "item-report-display",
  templateUrl: "./component.html",
  styleUrls: ["./component.css"],
})
@LogModule(...MODULE)
export class DisplayComponent implements OnInit, OnChanges {
  @Input() public item: Item;
  @Input() snag: Snag;

  public mainName: string;
  public subNames: string[];

  public logger: Logger;

  ngOnChanges(simpleChanges: SimpleChanges) {
    [this.mainName, ...this.subNames] = parseItemName(this.item);
  }

  ngOnInit() {
    if (this.logger.enabled) {
      const {item} = this;
      console.log(...this.logger.log({item}));
    }
  }
}

export const REPORT_DECLARATIONS = [CollectionComponent, DisplayComponent];
